import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Module as ModuleModel } from 'src/types/shared/Modules';
import { Dimension } from '../../../types/shared/Dimensions';
import { patchSortDimensions } from '@actions/dimensions/patchSortDimensions';
import { selectDimensions, selectDimensionsIsFetching } from '@selectors/dimensions';
import dayjs from 'dayjs';
import Title from 'lib/src/components/typography/Title';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import Accordion from '@components/accordion/Accordion';
import ModulesCompanyTable from './ModuleCompanyTable';
import ImportSectionsModal from '../../dimensions/modals/ImportDimensionsModal';
import CreateDimensionModal from '../../dimensions/single/CreateDimensionModal';
import UpdateDimensionModal from '../../dimensions/modals/UpdateDimensionModal';
import DimensionSubcategoriesTable from '@pages/dimensionSubcategories/DimensionSubcategoriesTable';
import ModalActionButtons from '@components/actions/ModalActionButtons';
import UpdateModuleModal from './UpdateModuleModal';
import DeleteDimensionModal from '@pages/dimensions/single/DeleteDimensionModal';
import CreateDimensionSubcategoryModal from '@pages/dimensionSubcategories/modals/CreateDimensionSubcategoryModal';
import CompanyModuleAssociationButtons from '@components/actions/CompanyModuleAssociationButtons';
import CreateModuleAssociationModal from '@pages/companies/single/association/CreateModuleAssociationModal';
import DeleteModuleAssociationModal from '@pages/companies/single/association/DeleteModuleAssociationModal';

const Module = ({ modules, id }: ModuleProps) => {
    const module = modules[id];
    const { name, startDate, endDate, isActive, isScoring, companyCount } = module;

    const history = useHistory();
    const dispatch = useDispatch();

    const dimensions: Record<number, Dimension> = useSelector(selectDimensions);
    const isFetching = useSelector(selectDimensionsIsFetching);

    const [dimensionToCreateSubcategory, setDimensionToCreateSubcategory] = useState<number | null>(
        null,
    );
    const [dimensionToUpdate, setDimensionToUpdate] = useState<number | null>(null);
    const [dimensionToDelete, setDimensionToDelete] = useState<number | null>(null);

    const filteredDimensions = Object.values(dimensions).filter(
        (dimension: Dimension) => dimension.moduleID === id,
    );

    const handleViewSingle = (dimensionID: number) => {
        history.push(`/dimensions/${dimensionID}`);
    };

    const postSort = (newSections: Dimension[]) => {
        dispatch(patchSortDimensions(id, newSections));
    };

    return (
        <>
            <Title>Module - {name}</Title>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Module ID">
                        <p>{id ?? 'No ID'}</p>
                    </ContentItem>{' '}
                    <ContentItem label="Company associations">
                        <p>{companyCount ?? 0}</p>
                    </ContentItem>
                    <ContentItem label="Is module active?">
                        <p>{isActive ? 'Yes' : 'No'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Start date">
                        <p>{startDate ? dayjs(startDate).format('DD-MM-YYYY') : 'No start date'}</p>
                    </ContentItem>
                    <ContentItem label="End date">
                        <p>{endDate ? dayjs(endDate).format('DD-MM-YYYY') : 'No end date'}</p>
                    </ContentItem>
                    <ContentItem label="Is External Scoring?">
                        <p>{isScoring ? 'Yes' : 'No'}</p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>

            <ButtonRow alignment="left">
                <ModalActionButtons
                    name="Dimension"
                    item={module}
                    CreateModal={CreateDimensionModal}
                    UpdateModal={UpdateModuleModal}
                    ImportModal={ImportSectionsModal}
                    disableCreate={filteredDimensions.length === 10}
                    disableImport={filteredDimensions.length === 10}
                />
            </ButtonRow>

            <div style={{ minHeight: '2em' }} />

            <Accordion
                setSectionToCreateQuestion={setDimensionToCreateSubcategory}
                setSectionToUpdate={setDimensionToUpdate}
                setSectionToDelete={setDimensionToDelete}
                sections={filteredDimensions}
                isFetching={isFetching}
                handleViewSingle={handleViewSingle}
                postSort={postSort}
                tableComponent={item => <DimensionSubcategoriesTable dimensionID={+item.id} />}
                noDataMessage="No dimensions yet"
                addText="Add Subcategory"
            />

            {dimensions[dimensionToDelete ?? -1] && dimensionToDelete && (
                <DeleteDimensionModal
                    item={dimensions[dimensionToDelete]}
                    closeModal={() => setDimensionToDelete(null)}
                />
            )}
            {!!module && dimensionToUpdate && (
                <UpdateDimensionModal
                    item={dimensions[dimensionToUpdate]}
                    closeModal={() => setDimensionToUpdate(null)}
                />
            )}
            {dimensionToCreateSubcategory && (
                <CreateDimensionSubcategoryModal
                    id={dimensionToCreateSubcategory}
                    closeModal={() => setDimensionToCreateSubcategory(null)}
                />
            )}

            <Title>Company Associations</Title>
            <CompanyModuleAssociationButtons
                name={'Company Associations'}
                CreateModal={CreateModuleAssociationModal}
                DeleteModal={DeleteModuleAssociationModal}
                item={module}
                // disableCreate={Object.values(processedModules).length > 0}
            />

            <div style={{ minHeight: '2em' }} />

            <ModulesCompanyTable companies={module.companies} />
        </>
    );
};

interface ModuleProps {
    modules: Record<number, ModuleModel>;
    id: number;
}

export default Module;
