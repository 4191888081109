import React from 'react';

import useCreateDimensionQuestion from '@pages/dimensionQuestions/hooks/useCreateDimensionQuestion';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import Select from 'lib/src/components/form/Select';
import TextInput from 'lib/src/components/form/TextInput';
import DatePicker from 'lib/src/components/form/DatePicker';
import Title from 'lib/src/components/typography/Title';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import NumberInput from 'lib/src/components/form/NumberInput';

import {
    dimensionQuestionTypeOptions,
    DimensionQuestionTypes,
} from '../../../types/shared/Companies';

const CreateDimensionQuestionModal = ({ closeModal, id }: Props) => {
    const {
        form: { text, answerType, startDate, endDate, textInputScore },
        handleChange,
        handleSubmit,
        answersObject,
        addAnswerObj,
        updateAnswer,
        removeAnswer,
    } = useCreateDimensionQuestion(id, closeModal);

    return (
        <Modal closeModal={closeModal} title="Create question" extraClasses="show-overflow">
            <Form
                onCancel={closeModal}
                onSubmit={handleSubmit}
                submitDisabled={
                    answerType === DimensionQuestionTypes.TextInput
                        ? textInputScore === null
                        : Object.values(answersObject).length < 1
                }
            >
                <FormRow>
                    <TextInput
                        name="text"
                        value={text}
                        onChange={handleChange}
                        label="Question text"
                        placeholder="Enter question text"
                        customValidate={() => {
                            if (text.length < 1) return 'This is a required field.';
                        }}
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="answerType"
                        value={answerType}
                        onChange={handleChange}
                        label="Question type"
                        options={dimensionQuestionTypeOptions}
                        required
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        name="startDate"
                        value={!!startDate ? new Date(startDate) : null}
                        onChange={handleChange}
                        label="Start date"
                        placeholder="Select start date"
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        name="endDate"
                        value={!!endDate ? new Date(endDate) : null}
                        onChange={handleChange}
                        label="End date"
                        customValidate={() => {
                            if (!!endDate && !!startDate && endDate < startDate) {
                                return 'End date must greater than start date';
                            }
                        }}
                        placeholder="Select end date"
                    />
                </FormRow>

                <>
                    {answerType === DimensionQuestionTypes.TextInput ? (
                        <>
                            <Title>Score</Title>
                            <FormRow>
                                <NumberInput
                                    name="textInputScore"
                                    value={textInputScore ?? 0}
                                    onChange={handleChange}
                                    label="Text Input Score"
                                    required
                                />
                            </FormRow>
                        </>
                    ) : (
                        <>
                            <Title>Answers</Title>
                            {Object.values(answersObject).map(
                                (
                                    answer: { id: string; text: string | null; score: number },
                                    key,
                                ) => (
                                    <FormRow key={key}>
                                        <TextInput
                                            name={`answerText${key}`}
                                            value={answer.text ?? ''}
                                            onChange={(_, value) =>
                                                updateAnswer(answer.id, 'text', value)
                                            }
                                            label="Answer text"
                                            customValidate={() => {
                                                if (answer.text !== null && answer.text.length < 1)
                                                    return 'This is a required field.';
                                            }}
                                        />
                                        <NumberInput
                                            name="score"
                                            value={answer.score}
                                            onChange={(name, value) =>
                                                updateAnswer(answer.id, name, +value)
                                            }
                                            label="Score"
                                            required
                                        />
                                        <button
                                            className="icon-button delete"
                                            onClick={() => removeAnswer(answer.id)}
                                            type="button"
                                        >
                                            <i className="fal fa-trash-alt"></i>
                                        </button>
                                    </FormRow>
                                ),
                            )}
                            <ButtonRow alignment="left">
                                <ActionButton
                                    type="button"
                                    onClick={() => addAnswerObj()}
                                    color="green"
                                    icon="plus"
                                >
                                    Add answer
                                </ActionButton>
                            </ButtonRow>
                        </>
                    )}
                </>
            </Form>
        </Modal>
    );
};

interface Props {
    closeModal: () => void;
    id: number;
}

export default CreateDimensionQuestionModal;
