import { useDispatch, useSelector } from 'react-redux';
import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useCallback, useEffect } from 'react';

import { updateCompanyQuestion } from '@actions/companyQuestions/updateCompanyQuestion';

import {
    getCompanyQuestionPostSuccess,
    getCompanyQuestionsError,
    getCompanyQuestionsIsPosting,
} from '@selectors/companyQuestions';

import {
    CompanyQuestion,
    defaultSliderOptions,
    questionTypeOptions,
    QuestionTypes,
    SliderOptions,
} from '../../../types/shared/Companies';

const useUpdateCompanyQuestion = (
    { id, questionText, isRequired, companyID, type, sectionID, sliderOptions }: CompanyQuestion,
    closeModal: () => void,
) => {
    const dispatch = useDispatch();

    const initialFormData: FormData = {
        questionText,
        isRequired,
        type,
        sliderOptions: sliderOptions !== null ? sliderOptions : defaultSliderOptions,
    };

    const [form, handleChange] = useForm<FormData>(initialFormData);

    const handleSubmit = useCallback(() => {
        dispatch(updateCompanyQuestion(id, form));
    }, [dispatch, id, form]);

    const isPosting = useSelector(getCompanyQuestionsIsPosting);
    const postSuccess = useSelector(getCompanyQuestionPostSuccess);
    const prevProps = usePrevious({ postSuccess, type });
    const error = useSelector(getCompanyQuestionsError);

    useEffect(() => {
        if (!prevProps.postSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevProps.postSuccess, closeModal]);

    return {
        form,
        handleChange,
        handleSubmit,
        isPosting,
        error,
        questionTypeOptions,
    };
};

interface FormData {
    questionText: string;
    isRequired: boolean;
    type: QuestionTypes;
    sliderOptions: SliderOptions;
}

export default useUpdateCompanyQuestion;
