import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from 'src/redux/store';
import { DimensionQuestion } from '../../../types/shared/Dimensions';

import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { DimensionQuestionTypes } from '../../../types/shared/Companies';

export const updateDimensionQuestionRequest = createAction('updateDimensionQuestionRequest');
export const updateDimensionQuestionSuccess = createAction<DimensionQuestion>(
    'updateDimensionQuestionSuccess',
);
export const updateDimensionQuestionFailure = createAction('updateDimensionQuestionFailure');

export const updateDimensionQuestion =
    (id: number, postBody: QuestionRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(updateDimensionQuestionRequest());

        try {
            const { data }: Response = await api.patch(`dimensions/questions/${id}`, postBody);

            dispatch(updateDimensionQuestionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateDimensionQuestionFailure, e as APIError);
        }
    };

interface Response {
    data: DimensionQuestion;
}

interface QuestionRequest {
    text: string;
    answerType: DimensionQuestionTypes;
    startDate: string | null;
    endDate: string | null;
}
