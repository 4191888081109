import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';

import {
    getCompanyQuestionPostSuccess,
    getCompanyQuestionsError,
    getCompanyQuestionsIsPosting,
} from '@selectors/companyQuestions';
import { CustomSection } from 'src/types/shared/Companies';
import { deleteCompanySection } from '@actions/companyQuestions/deleteCompanySection';

const useDeleteCompanyQuestion = ({ id, companyID }: CustomSection, closeModal: () => void) => {
    const dispatch = useDispatch();

    const handleDeleteCompanySection = useCallback(() => {
        dispatch(deleteCompanySection(id));
    }, [dispatch, id]);

    const isPosting = useSelector(getCompanyQuestionsIsPosting);
    const error = useSelector(getCompanyQuestionsError);
    const postSuccess = useSelector(getCompanyQuestionPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal, companyID]);

    return { handleDeleteCompanySection, isPosting, error };
};

export default useDeleteCompanyQuestion;
