import useDeleteDimensionAnswer from '@pages/dimensionQuestions/answers/hooks/useDeleteDimensionAnswer';

import ConfirmModal from 'lib/src/components/modal/ConfirmModal';

const DeleteDimensionAnswerModal = ({ answerID, questionID, closeModal }: DeleteModalProps) => {
    const { handleDelete, isPosting, error } = useDeleteDimensionAnswer(
        answerID,
        questionID,
        closeModal,
    );

    return (
        <ConfirmModal
            title="Delete Answer"
            description={`Are you sure you want to delete this answer?`}
            closeModal={closeModal}
            handleSubmit={handleDelete}
            isPosting={isPosting}
            error={error}
        />
    );
};

interface DeleteModalProps {
    answerID: number;
    questionID: number;
    closeModal: () => void;
}
export default DeleteDimensionAnswerModal;
