import useUpdateCompanySection from '../hooks/useUpdateCompanySection';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';

import TextArea from 'lib/src/components/form/TextArea';
import FormRow from 'lib/src/components/form/FormRow';
import { CustomSection } from '../../../types/shared/Companies';
import FilePicker from 'lib/src/components/filePicker/FilePicker';
import config from '../../../config';
const { API_URL, S3_URL } = config;

const UpdateCompanySectionModal = ({ section, closeModal }: UpdateCompanyQuestionModalProps) => {
    const {
        form: { name, description, imageS3Key },
        handleChange,
        handleSubmit,
        isPosting,
        error,
    } = useUpdateCompanySection(section, closeModal);

    return (
        <Modal title="Update company Section" closeModal={closeModal}>
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting} error={error}>
                <FormRow>
                    <TextArea
                        name="name"
                        onChange={handleChange}
                        value={name}
                        label="Section Title"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextArea
                        name="description"
                        onChange={handleChange}
                        value={description}
                        label="Description"
                    />
                </FormRow>
                <FormRow>
                    <FilePicker
                        name="imageS3Key"
                        onChange={handleChange}
                        value={imageS3Key}
                        label="Image"
                        apiURL={API_URL}
                        storageURL={S3_URL}
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface UpdateCompanyQuestionModalProps {
    section: CustomSection;
    closeModal: () => void;
}

export default UpdateCompanySectionModal;
