import usePrevious from 'lib/src/hooks/usePrevious';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';

import {
    getCompanyQuestionPostSuccess,
    getCompanyQuestionsError,
    getCompanyQuestionsIsPosting,
} from '@selectors/companyQuestions';
import { CompanyQuestion, QuestionAnswer } from 'src/types/shared/Companies';
import { deleteAnswer } from '@actions/companyQuestions/answers/deleteAnswer';

const useDeleteAnswer = (
    answer: QuestionAnswer,
    question: CompanyQuestion,
    closeModal: () => void,
) => {
    const dispatch = useDispatch();
    // const history = useHistory();

    const handleDeleteAnswer = useCallback(() => {
        dispatch(deleteAnswer(answer.id));
    }, [dispatch, answer]);

    const isPosting = useSelector(getCompanyQuestionsIsPosting);
    const error = useSelector(getCompanyQuestionsError);
    const postSuccess = useSelector(getCompanyQuestionPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            // history.replace(
            //     `/companies/${question?.companyID}/sections/${question?.sectionID}/dimensionQuestions/${question?.id}`,
            // );
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return { handleDeleteAnswer, isPosting, error };
};

export default useDeleteAnswer;
