import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCompaniesError, getCompaniesIsPosting } from '@selectors/companies';
import { deleteAssociation } from '@actions/companies';
import { setFieldErrors } from 'lib/src/redux/actions/fieldErrors';
import { Module } from 'src/types/shared/Modules';

const useDeleteAssociation = (module: Module, closeModal: () => void) => {
    const dispatch = useDispatch();
    const { companies } = module;
    const [selectedCompanyID, setSelectedCompanyID] = useState<number | null>(null);

    const handleChange = (_: string, id: number | null) => {
        setSelectedCompanyID(id);
    };

    const companyOptions = useMemo(() => {
        return companies.map(x => ({
            value: x.id,
            label: x.companyReference,
        }));
    }, [companies]);

    const handleSubmit = () => {
        if (selectedCompanyID) {
            dispatch(deleteAssociation({ companyID: selectedCompanyID, moduleID: module.id }));
            closeModal();
        } else {
            dispatch(setFieldErrors({ selectedCompanyID: 'Please select a company' }));
        }
    };

    const isPosting = useSelector(getCompaniesIsPosting);
    const error = useSelector(getCompaniesError);

    return {
        handleSubmit,
        isPosting,
        selectedCompanyID,
        setSelectedCompanyID,
        error,
        companyOptions,
        handleChange,
    };
};

export default useDeleteAssociation;
