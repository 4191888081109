import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { DimensionAnswer, DimensionQuestion } from '../../types/shared/Dimensions';

import {
    createDimensionQuestionFailure,
    createDimensionQuestionRequest,
    createDimensionQuestionSuccess,
    fetchDimensionQuestionsFailure,
    fetchDimensionQuestionsRequest,
    fetchDimensionQuestionsSuccess,
    fetchSingleDimensionQuestionFailure,
    fetchSingleDimensionQuestionRequest,
    fetchSingleDimensionQuestionSuccess,
    updateDimensionQuestionFailure,
    updateDimensionQuestionRequest,
    updateDimensionQuestionSuccess,
    deleteDimensionQuestionFailure,
    deleteDimensionQuestionRequest,
    deleteDimensionQuestionSuccess,
    patchSortDimensionQuestionsFailure,
    patchSortDimensionQuestionsRequest,
    patchSortDimensionQuestionsSuccess,
    patchSortDimensionAnswersRequest,
    patchSortDimensionAnswersSuccess,
    patchSortDimensionAnswersFailure,
} from '@actions/dimensionQuestions';

import {
    createDimensionQuestionAnswerFailure,
    createDimensionQuestionAnswerRequest,
    createDimensionQuestionAnswerSuccess,
    deleteDimensionAnswerFailure,
    deleteDimensionAnswerRequest,
    deleteDimensionAnswerSuccess,
    updateDimensionAnswerFailure,
    updateDimensionAnswerRequest,
    updateDimensionAnswerSuccess,
} from '@actions/dimensionQuestions/answers';

import {
    copyQuestionToDimensionSubcategoryFailure,
    copyQuestionToDimensionSubcategoryRequest,
    copyQuestionToDimensionSubcategorySuccess,
} from '@actions/dimensionSubcategories';
import {
    fetchQuestionAnswersFailure,
    fetchQuestionAnswersRequest,
    fetchQuestionAnswersSuccess,
} from '@actions/dimensionQuestions/answers/fetchQuestionAnswers';
import { convertArrToObj } from 'lib/src/utils/generic';

interface DimensionQuestionsState {
    postSuccess: boolean;
    isPosting: boolean;
    isFetching: boolean;
    dimensionQuestions: Record<number, DimensionQuestion>;
    answers: Record<number, DimensionAnswer>;
    error: string | null;
    isFetchingAnswers: boolean;
    isPostingAnswers: boolean;
    answerPostSuccess: boolean;
    answerError: string | null;
}

const initialState: DimensionQuestionsState = {
    isFetching: false,
    dimensionQuestions: {},
    error: null,
    postSuccess: false,
    isPosting: false,
    isFetchingAnswers: false,
    answers: {},
    isPostingAnswers: false,
    answerPostSuccess: false,
    answerError: null,
};

export default createReducer(initialState, {
    [fetchDimensionQuestionsRequest.type]: handleFetchRequest,
    [fetchDimensionQuestionsSuccess.type]: handleFetchSuccess,
    [fetchDimensionQuestionsFailure.type]: handleFailure,
    [createDimensionQuestionRequest.type]: handlePostRequest,
    [createDimensionQuestionSuccess.type]: handleCreateDimensionQuestionSuccess,
    [createDimensionQuestionFailure.type]: handleFailure,
    [copyQuestionToDimensionSubcategoryRequest.type]: handlePostRequest,
    [copyQuestionToDimensionSubcategorySuccess.type]: handleCopySuccess,
    [copyQuestionToDimensionSubcategoryFailure.type]: handleFailure,
    [fetchSingleDimensionQuestionRequest.type]: handleFetchRequest,
    [fetchSingleDimensionQuestionSuccess.type]: handleFetchSingleSuccess,
    [fetchSingleDimensionQuestionFailure.type]: handleFailure,
    [updateDimensionQuestionRequest.type]: handlePostRequest,
    [updateDimensionQuestionSuccess.type]: handleCreateDimensionQuestionSuccess,
    [updateDimensionQuestionFailure.type]: handleFailure,
    [deleteDimensionQuestionRequest.type]: handlePostRequest,
    [deleteDimensionQuestionSuccess.type]: handleDeleteQuestion,
    [deleteDimensionQuestionFailure.type]: handleFailure,
    [createDimensionQuestionAnswerRequest.type]: handlePostAnswerRequest,
    [createDimensionQuestionAnswerSuccess.type]: handlePostAnswerSuccess,
    [createDimensionQuestionAnswerFailure.type]: handleFailure,
    [updateDimensionAnswerRequest.type]: handlePostAnswerRequest,
    [updateDimensionAnswerSuccess.type]: handlePostAnswerSuccess,
    [updateDimensionAnswerFailure.type]: handleFailure,
    [deleteDimensionAnswerRequest.type]: handlePostAnswerRequest,
    [deleteDimensionAnswerSuccess.type]: handleDeleteAnswer,
    [deleteDimensionAnswerFailure.type]: handleFailure,
    [patchSortDimensionQuestionsRequest.type]: handlePostAnswerRequest,
    [patchSortDimensionQuestionsSuccess.type]: handleSortDimensionQuestionsSuccess,
    [patchSortDimensionQuestionsFailure.type]: handleFailure,
    [fetchQuestionAnswersRequest.type]: handleFetchAnswersRequest,
    [fetchQuestionAnswersSuccess.type]: handleFetchAnswersSuccess,
    [fetchQuestionAnswersFailure.type]: handleAnswersFailure,
    [patchSortDimensionAnswersRequest.type]: handlePostAnswerRequest,
    [patchSortDimensionAnswersSuccess.type]: handleSortDimensionAnswersSuccess,
    [patchSortDimensionAnswersFailure.type]: handleFailure,
});

function handleFetchRequest(state: DimensionQuestionsState) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchSuccess(
    state: DimensionQuestionsState,
    action: PayloadAction<DimensionQuestion[]>,
) {
    state.isFetching = false;
    state.dimensionQuestions = { ...state.dimensionQuestions, ...convertArrToObj(action.payload) };
}

function handlePostRequest(state: DimensionQuestionsState) {
    state.error = null;
    state.isPosting = true;
    state.postSuccess = false;
}

function handlePostSuccess(state: DimensionQuestionsState) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handlePostAnswerRequest(state: DimensionQuestionsState) {
    state.isPostingAnswers = false;
    state.answerPostSuccess = false;
}

function handleCreateDimensionQuestionSuccess(
    state: DimensionQuestionsState,
    action: PayloadAction<DimensionQuestion>,
) {
    handlePostSuccess(state);
    state.dimensionQuestions[action.payload.id] = action.payload;
}

function handleCopySuccess(
    state: DimensionQuestionsState,
    action: PayloadAction<DimensionQuestion>,
) {
    handlePostSuccess(state);
    state.dimensionQuestions[action.payload.id] = action.payload;
}

function handleFetchSingleSuccess(
    state: DimensionQuestionsState,
    action: PayloadAction<DimensionQuestion>,
) {
    state.isFetching = false;
    state.dimensionQuestions[action.payload.id] = action.payload;
}

function handleDeleteQuestion(state: DimensionQuestionsState, action: PayloadAction<number>) {
    handlePostSuccess(state);
    delete state.dimensionQuestions[action.payload];
}

function handlePostAnswerSuccess(
    state: DimensionQuestionsState,
    action: PayloadAction<DimensionAnswer>,
) {
    state.isPostingAnswers = false;
    state.answerPostSuccess = true;
    state.answers[action.payload.id] = action.payload;
}

function handleDeleteAnswer(
    state: DimensionQuestionsState,
    action: PayloadAction<{ answerID: number; questionID: number }>,
) {
    state.isPostingAnswers = false;
    state.answerPostSuccess = true;
    delete state.answers[action.payload.answerID];
}

function handleFailure(state: DimensionQuestionsState, error: string) {
    state.isFetching = false;
    state.isPosting = false;
    state.error = error;
}

function handleSortDimensionQuestionsSuccess(
    state: DimensionQuestionsState,
    dimension: PayloadAction<Record<number, DimensionQuestion>>,
) {
    state.dimensionQuestions = dimension.payload;
}

function handleSortDimensionAnswersSuccess(
    state: DimensionQuestionsState,
    dimension: PayloadAction<Record<number, DimensionAnswer>>,
) {
    state.answers = dimension.payload;
}

function handleFetchAnswersRequest(state: DimensionQuestionsState) {
    state.isFetchingAnswers = true;
    state.answerError = null;
}

function handleFetchAnswersSuccess(
    state: DimensionQuestionsState,
    action: PayloadAction<DimensionAnswer[]>,
) {
    state.isFetchingAnswers = false;
    state.answers = {};
    action.payload.forEach((answer: DimensionAnswer) => {
        state.answers[answer.id] = answer;
    });
}

function handleAnswersFailure(state: DimensionQuestionsState, error: string) {
    state.isFetchingAnswers = false;
    state.answerError = error;
}
