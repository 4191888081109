import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';

import { APIError } from 'lib/src/types/APIError';
import {
    DimensionSubcategory,
    DimensionSubcategoryRequest,
} from '../../../types/shared/Dimensions';

export const createDimensionSubcategoryRequest = createAction('createDimensionSubcategoryRequest');
export const createDimensionSubcategorySuccess = createAction<DimensionSubcategory>(
    'createDimensionSubcategorySuccess',
);
export const createDimensionSubcategoryFailure = createAction('createDimensionSubcategoryFailure');

export const createDimensionSubcategory =
    (id: number, postBody: DimensionSubcategoryRequest) => async (dispatch: AppDispatch) => {
        dispatch(createDimensionSubcategoryRequest());
        try {
            const { data }: CreateResponse = await api.post(
                `dimensions/${id}/subcategories`,
                postBody,
            );
            dispatch(createDimensionSubcategorySuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createDimensionSubcategoryFailure, e as APIError);
        }
    };

interface CreateResponse {
    data: DimensionSubcategory;
}
