import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFetchCompanies from '@pages/companies/hooks/useFetchCompanies';
import usePrevious from 'lib/src/hooks/usePrevious';

import { getAllCompanies, getCompaniesError, getCompaniesIsFetching } from '@selectors/companies';
import { setFieldErrors } from 'lib/src/redux/actions/fieldErrors';

import { postCopyCompanyQuestions } from '@actions/companyQuestions/postCopyCompanyQuestions';

import { Company } from '../../../types/shared/Companies';
import { getCompanyQuestionPostSuccess } from '@selectors/companyQuestions';

const useImportSectionsFromCompany = (closeModal: () => void, companyID: number) => {
    const dispatch = useDispatch();

    const [selectedCompanyID, setSelectedCompanyID] = useState<number | null>(null);

    useFetchCompanies();

    const isFetchingCompanies = useSelector(getCompaniesIsFetching);
    const companies = Object.values(useSelector(getAllCompanies));
    const companiesFetchError = useSelector(getCompaniesError);

    const postSuccess = useSelector(getCompanyQuestionPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    const companyOptions = useMemo(() => {
        return companies.map((company: Company) => ({
            value: company.id,
            label: company.companyReference,
        }));
    }, [companies]);

    const handleChange = (companyID: number | null) => {
        setSelectedCompanyID(companyID);
    };

    const handleSubmit = () => {
        if (selectedCompanyID) {
            dispatch(postCopyCompanyQuestions(companyID, selectedCompanyID));
        } else {
            dispatch(setFieldErrors({ selectedCompanyID: 'Please select a company' }));
        }
    };

    return {
        selectedCompanyID,
        handleChange,
        handleSubmit,
        isFetchingCompanies,
        companiesFetchError,
        companyOptions,
    };
};

export default useImportSectionsFromCompany;
