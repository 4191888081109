import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAllDimensionSubcategories } from '@actions/dimensionSubcategories/fetchAllDimensionSubcategories';

const useFetchAllDimensionSubcategories = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllDimensionSubcategories());
    }, [dispatch]);
};

export default useFetchAllDimensionSubcategories;
