import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from 'lib/src/components/typography/Title';
import React, { Dispatch, SetStateAction, useState } from 'react';

import Select from 'lib/src/components/form/Select';
import useAccordionActions from '@components/accordion/hooks/useAccordionActions';

interface Props {
    section: any;
    handleSort: (itemID: number, direction: 'up' | 'down') => void;
    setSectionToUpdate: Dispatch<SetStateAction<number | null>>;
    setSectionToDelete: Dispatch<SetStateAction<number | null>>;
    setSectionToCreateQuestion: Dispatch<SetStateAction<number | null>>;
    handleViewSingle: (id: number) => void;
    isFirst: boolean;
    isLast: boolean;
    tableComponent: (section: any) => JSX.Element;
    addText?: string;
}

const AccordionItem: React.FC<Props> = ({
    section,
    handleSort,
    setSectionToUpdate,
    setSectionToDelete,
    setSectionToCreateQuestion,
    handleViewSingle,
    isLast,
    isFirst,
    tableComponent,
    addText = 'Add Question',
}) => {
    const { id, name } = section;
    const isActive = section?.isActive;

    const [isExpanded, setExpanded] = useState<boolean>(false);

    const { formData, handleChange, actionOptions } = useAccordionActions(
        id,
        setSectionToCreateQuestion,
        setSectionToDelete,
        setSectionToUpdate,
        isFirst,
        isLast,
        handleSort,
        handleViewSingle,
        addText,
    );

    return (
        <>
            <CreateHeader marginTop>
                <Title>
                    <div className="accordion-button" onClick={() => setExpanded(!isExpanded)}>
                        <i
                            className={`icon far fa-fw fa-${
                                isExpanded ? 'chevron-up' : 'chevron-down'
                            }`}
                        ></i>
                    </div>
                    {name}
                </Title>

                <div>
                    {isActive === undefined ? (
                        ''
                    ) : (
                        <div className={'activity'} title={isActive ? 'Active' : 'Inactive'}>
                            {isActive ? (
                                <i className="fas fa-check-circle active" />
                            ) : (
                                <i className="fas fa-times-circle inactive"></i>
                            )}
                        </div>
                    )}
                    <Select
                        name="action"
                        onChange={handleChange}
                        value={formData.action}
                        options={actionOptions}
                        placeholder="Select action..."
                    />
                </div>
            </CreateHeader>

            {isExpanded && tableComponent(section)}

            <div className="divider" />
        </>
    );
};

export default AccordionItem;
