import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { Module } from 'src/types/shared/Modules';
import {
    createModuleFailure,
    createModuleRequest,
    createModuleSuccess,
} from '@actions/modules/createModule';
import { convertArrToObj } from 'lib/src/utils/generic';

import {
    deleteModuleFailure,
    deleteModuleRequest,
    deleteModuleSuccess,
    fetchModuleFailure,
    fetchModuleRequest,
    fetchModuleSuccess,
    fetchAllModulesFailure,
    fetchAllModulesRequest,
    fetchAllModulesSuccess,
    updateModuleFailure,
    updateModuleRequest,
    updateModuleSuccess,
    copyModuleRequest,
    copyModuleSuccess,
    copyModuleFailure,
} from '@actions/modules';
import {
    createAssociationFailure,
    createAssociationRequest,
    createAssociationSuccess,
    ExtendedResponse as CompanyExtended,
} from '@actions/companies/createAssociation';
import {
    deleteAssociationFailure,
    deleteAssociationRequest,
    deleteAssociationSuccess,
    DeleteProps,
} from '@actions/companies';
import {
    addModuleToAccessCodeFailure,
    addModuleToAccessCodeRequest,
    addModuleToAccessCodeSuccess,
    fetchSingleAccessCodeFailure,
    fetchSingleAccessCodeRequest,
    fetchSingleAccessCodeSuccess,
} from '@actions/accessCodes';
import {
    deleteModuleFromAccessCodeFailure,
    deleteModuleFromAccessCodeRequest,
    deleteModuleFromAccessCodeSuccess,
} from '@actions/accessCodes/deleteModuleFromAccessCode';
import { AccessCode } from '../../types/shared/AccessCode';
import { Company } from '../../types/shared/Companies';
import {
    patchSortAccessCodeModulesFailure,
    patchSortAccessCodeModulesRequest,
    patchSortAccessCodeModulesSuccess,
} from '@actions/accessCodes/patchSortAccessCodeModules';

interface ModulesState {
    moduleCreated: number | null;
    isFetching: boolean;
    modules: Record<number, Module>;
    accessCodeModules: Record<number, Module>;
    isPosting: boolean;
    postSuccess: boolean;
    error: string | null;
}

const initialState: ModulesState = {
    modules: {},
    accessCodeModules: {},
    moduleCreated: null,
    isPosting: false,
    postSuccess: false,
    error: null,
    isFetching: false,
};

export default createReducer(initialState, {
    [createModuleRequest.type]: handlePostRequest,
    [createModuleSuccess.type]: handlePostSuccess,
    [createModuleFailure.type]: handleError,
    [fetchAllModulesRequest.type]: handleFetchRequest,
    [fetchAllModulesSuccess.type]: handleFetchAllSuccess,
    [fetchAllModulesFailure.type]: handleFetchAllFailure,
    [fetchModuleRequest.type]: handleFetchModuleRequest,
    [fetchModuleSuccess.type]: handleFetchModuleSuccess,
    [fetchModuleFailure.type]: handleFetchModuleFailure,
    [updateModuleRequest.type]: handlePostModuleRequest,
    [updateModuleSuccess.type]: handlePostModuleSuccess,
    [updateModuleFailure.type]: handleError,
    [deleteModuleRequest.type]: handlePostRequest,
    [deleteModuleSuccess.type]: handleDeleteModuleSuccess,
    [deleteModuleFailure.type]: handleError,
    [createAssociationRequest.type]: handlePostRequest,
    [createAssociationSuccess.type]: handleCreateAssociationSuccess,
    [createAssociationFailure.type]: handleError,
    [deleteAssociationRequest.type]: handlePostRequest,
    [deleteAssociationSuccess.type]: handleDeleteAssociationSuccess,
    [deleteAssociationFailure.type]: handleError,
    [copyModuleRequest.type]: handlePostRequest,
    [copyModuleSuccess.type]: handlePostModuleSuccess,
    [copyModuleFailure.type]: handleError,
    [deleteModuleFromAccessCodeRequest.type]: handlePostRequest,
    [deleteModuleFromAccessCodeSuccess.type]: handleDeleteModuleFromAccessCodeSuccess,
    [deleteModuleFromAccessCodeFailure.type]: handleError,
    [addModuleToAccessCodeRequest.type]: handlePostRequest,
    [addModuleToAccessCodeSuccess.type]: handleAddModuleToAccessCodeSuccess,
    [addModuleToAccessCodeFailure.type]: handleError,
    [fetchSingleAccessCodeRequest.type]: handleFetchRequest,
    [fetchSingleAccessCodeSuccess.type]: handleFetchSingleAccessCodeSuccess,
    [fetchSingleAccessCodeFailure.type]: handleError,
    [patchSortAccessCodeModulesRequest.type]: handlePostRequest,
    [patchSortAccessCodeModulesSuccess.type]: handleFetchSingleAccessCodeSuccess,
    [patchSortAccessCodeModulesFailure.type]: handleError,
});

function handlePostRequest(state: ModulesState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;
}

function handlePostSuccess(state: ModulesState, action: PayloadAction<Module>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.modules[action.payload.id] = action.payload;
}

function handleError(state: ModulesState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.error = action.payload;
}

function handleFetchRequest(state: ModulesState) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchAllSuccess(state: ModulesState, action: PayloadAction<Module[]>) {
    state.isFetching = false;
    state.modules = convertArrToObj(action.payload);
}

function handleFetchAllFailure(state: ModulesState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
}

function handleFetchModuleRequest(state: ModulesState) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchModuleSuccess(state: ModulesState, action: PayloadAction<Module>) {
    state.isFetching = false;
    state.modules[action.payload.id] = action.payload;
}

function handleFetchModuleFailure(state: ModulesState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
}

function handlePostModuleRequest(state: ModulesState) {
    state.error = null;
    state.isPosting = true;
    state.postSuccess = false;
    state.moduleCreated = null;
}

function handlePostModuleSuccess(state: ModulesState, action: PayloadAction<Module>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.modules[action.payload.id] = action.payload;
    state.moduleCreated = action.payload.id;
}

function handleDeleteModuleSuccess(state: ModulesState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.modules[action.payload];
}

function handleCreateAssociationSuccess(
    state: ModulesState,
    action: PayloadAction<CompanyExtended>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    state?.modules[action.payload?.moduleID]?.companies?.push(action.payload);
}

function handleDeleteAssociationSuccess(state: ModulesState, action: PayloadAction<DeleteProps>) {
    state.isPosting = false;
    state.postSuccess = true;
    if (state?.modules === undefined) return;
    const index = state?.modules[action.payload?.moduleID ?? -1]?.companies?.findIndex(
        company => company.id === action.payload?.companyID,
    );
    state?.modules[action.payload?.moduleID ?? -1]?.companies?.splice(index, 1);
}

function handleFetchSingleAccessCodeSuccess(
    state: ModulesState,
    action: PayloadAction<AccessCode>,
) {
    state.isFetching = false;
    state.accessCodeModules = !!action.payload.modules
        ? convertArrToObj(action.payload.modules)
        : {};
}

function handleDeleteModuleFromAccessCodeSuccess(
    state: ModulesState,
    action: PayloadAction<number>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.accessCodeModules[action.payload];
}

function handleAddModuleToAccessCodeSuccess(state: ModulesState, action: PayloadAction<Company>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.accessCodeModules = convertArrToObj(action.payload.modules);
}
