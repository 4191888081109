import Modal from 'lib/src/components/modal/Modal';

import useImportDimensionSubcategories from '../hooks/useImportDimensionSubcategories';

import Form from 'lib/src/components/form/Form';
import Select from 'lib/src/components/form/Select';
import Loading from '@components/layout/loading/Loading';
import FormField from 'lib/src/components/form/FormField';

const ImportSubcategoriesModal = ({ closeModal }: Props) => {
    const {
        isFetching,
        error,
        handleSubmit,
        handleChange,
        dimensionOptions,
        form: { selectedDimensionID, selectedSubcategoryID },
        subcategoryOptions,
    } = useImportDimensionSubcategories(closeModal);

    return (
        <Modal closeModal={closeModal} title="Import Subcategory" extraClasses="show-overflow">
            {isFetching ? (
                <Loading />
            ) : (
                <Form onSubmit={handleSubmit} onCancel={closeModal} error={error}>
                    <FormField name="selectedDimensionID" label="Dimension">
                        <Select
                            search
                            options={dimensionOptions}
                            name="selectedDimensionID"
                            value={selectedDimensionID}
                            onChange={handleChange}
                        />
                    </FormField>
                    <FormField name="selectedDimensionID" label="Subcategory">
                        <Select
                            search
                            options={subcategoryOptions}
                            name="selectedSubcategoryID"
                            disabled={!selectedDimensionID}
                            value={selectedSubcategoryID}
                            onChange={handleChange}
                        />
                    </FormField>
                </Form>
            )}
        </Modal>
    );
};

interface Props {
    closeModal: () => void;
    id: number;
}

export default ImportSubcategoriesModal;
