import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import usePrevious from 'lib/src/hooks/usePrevious';

import { deleteCompanyQuestion } from '@actions/companyQuestions/deleteCompanyQuestion';

import {
    getCompanyQuestionPostSuccess,
    getCompanyQuestionsError,
    getCompanyQuestionsIsPosting,
} from '@selectors/companyQuestions';
import { CompanyQuestion } from 'src/types/shared/Companies';

const useDeleteCompanyQuestion = (
    { id, companyID, sectionID }: CompanyQuestion,
    closeModal: () => void,
) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleDeleteCompanyQuestion = useCallback(() => {
        dispatch(deleteCompanyQuestion(id, sectionID));
    }, [dispatch, id, sectionID]);

    const isPosting = useSelector(getCompanyQuestionsIsPosting);
    const error = useSelector(getCompanyQuestionsError);
    const postSuccess = useSelector(getCompanyQuestionPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
            history.replace(`/companies/${companyID}`);
        }
    }, [postSuccess, prevPostSuccess, history, companyID, closeModal]);

    return { handleDeleteCompanyQuestion, isPosting, error };
};

export default useDeleteCompanyQuestion;
