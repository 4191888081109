export const isEmptyOrWhitespace = (str: string): boolean =>
    (str.length < 1 && str === null) || str.match(/^ *$/) !== null;

export const validateEmpty = (str: string | number | null): string =>
    isEmptyOrWhitespace(str?.toString() ?? '') ? 'This is a required field' : '';
export const validateScoresMedium = (mediumScoreBoundary: number, highScoreBoundary: number) =>
    +mediumScoreBoundary < +highScoreBoundary
        ? ''
        : 'Low/Medium score boundary must be lesser than Medium/High score boundary';
export const validateScoresHigh = (mediumScoreBoundary: number, highScoreBoundary: number) =>
    +mediumScoreBoundary < +highScoreBoundary
        ? ''
        : 'Medium/High score boundary must be greater than Low/Medium score boundary';
export const formatBoundaries = (str: string): string => str.replace(/[^0-9.]/g, '');
