import React from 'react';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import Loading from '@components/layout/loading/Loading';
import Select from 'lib/src/components/form/Select';
import FormField from 'lib/src/components/form/FormField';
import useImportSectionsFromCompany from '../hooks/useImportSectionsFromCompany';
import { Company } from 'src/types/shared/Companies';

function ImportSectionsModal({ closeModal, item }: Props) {
    const companyID = item?.id ?? -1;
    const {
        selectedCompanyID,
        handleChange,
        handleSubmit,
        isFetchingCompanies,
        companiesFetchError,
        companyOptions,
    } = useImportSectionsFromCompany(closeModal, companyID);

    return (
        <Modal closeModal={closeModal} title="Import Sections" extraClasses="show-overflow">
            {isFetchingCompanies ? (
                <Loading />
            ) : (
                <Form onSubmit={handleSubmit} onCancel={closeModal} error={companiesFetchError}>
                    <FormField name="selectedCompanyID" label="Company">
                        <Select
                            search
                            options={companyOptions}
                            name="selectedCompanyID"
                            value={selectedCompanyID}
                            onChange={(_, val) => handleChange(val)}
                        />
                    </FormField>
                </Form>
            )}
        </Modal>
    );
}

interface Props {
    closeModal: () => void;
    item: Company;
}
export default ImportSectionsModal;
