import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../../../redux/store';
import { QuestionAnswer } from '../../../../types/shared/Companies';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const fetchAllQuestionAnswersRequest = createAction('fetchAllQuestionAnswersRequest');
export const fetchAllQuestionAnswersSuccess = createAction<QuestionAnswer[]>(
    'fetchAllQuestionAnswersSuccess',
);
export const fetchAllQuestionAnswersFailure = createAction('fetchAllQuestionAnswersFailure');

export const fetchAllQuestionAnswers = (questionID: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchAllQuestionAnswersRequest());

    try {
        const { data }: QuestionAnswerResponse = await api.get(
            `onboarding/questions/${questionID}/answers`,
        );

        dispatch(fetchAllQuestionAnswersSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchAllQuestionAnswersFailure, e as APIError);
    }
};

interface QuestionAnswerResponse {
    data: QuestionAnswer[];
}
