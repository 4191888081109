import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import {
    DimensionSubcategory,
    DimensionSubcategoryRequest,
} from '../../../types/shared/Dimensions';

export const updateDimensionSubcategoryRequest = createAction('updateDimensionSubcategoryRequest');
export const updateDimensionSubcategorySuccess = createAction<DimensionSubcategory>(
    'updateDimensionSubcategorySuccess',
);
export const updateDimensionSubcategoryFailure = createAction('updateDimensionSubcategoryFailure');

export const updateDimensionSubcategory =
    (subcategoryID: number, patchBody: DimensionSubcategoryRequest) =>
    async (dispatch: AppDispatch) => {
        dispatch(updateDimensionSubcategoryRequest());

        try {
            const { data } = await api.patch<DimensionSubcategoryRequest, DimensionSubcategory>(
                `dimensions/subcategories/${subcategoryID}`,

                patchBody,
            );
            dispatch(updateDimensionSubcategorySuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateDimensionSubcategoryFailure, e as APIError);
        }
    };
