import { Config } from '.';

const config: Config = {
    API_URL: 'http://87percentadminapistaging-env.eba-svcugqpn.eu-west-1.elasticbeanstalk.com',
    S3_URL: 'https://s3-eu-west-1.amazonaws.com/87-percent-staging-media',
    URL: 'http://admin.87percent.madeatsilverchip.com.s3-website-eu-west-1.amazonaws.com/',
    WEB_URL: 'http://87percent.madeatsilverchip.com.s3-website-eu-west-1.amazonaws.com/',
};

export default config;
