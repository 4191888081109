import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const deleteCompanySectionRequest = createAction('deleteCompanySectionRequest');
export const deleteCompanySectionSuccess = createAction<number>('deleteCompanySectionSuccess');
export const deleteCompanySectionFailure = createAction('deleteCompanySectionFailure');

export const deleteCompanySection = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(deleteCompanySectionRequest());

    try {
        await api.delete(`onboarding/sections/${id}`);

        dispatch(deleteCompanySectionSuccess(id));
    } catch (e) {
        handleApiErrors(dispatch, deleteCompanySectionFailure, e as APIError);
    }
};
