import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { QuestionAnswer } from 'src/types/shared/Companies';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const updateAnswerRequest = createAction('updateAnswerRequest');
export const updateAnswerSuccess = createAction<QuestionAnswer>('updateAnswerSuccess');
export const updateAnswerFailure = createAction('updateAnswerFailure');

export const updateAnswer =
    (answerID: number, postBody: { answerText: string }) => async (dispatch: AppDispatch) => {
        dispatch(updateAnswerRequest());

        try {
            const { data }: UpdateResponse = await api.patch(
                `onboarding/answers/${answerID}`,
                postBody,
            );

            dispatch(updateAnswerSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateAnswerFailure, e as APIError);
        }
    };

interface UpdateResponse {
    data: QuestionAnswer;
}
