import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
    fetchAllCompanyQuestionsFailure,
    fetchAllCompanyQuestionsRequest,
    fetchAllCompanyQuestionsSuccess,
} from '@actions/companyQuestions/fetchAllCompanyQuestions';

import { convertArrToObj } from 'lib/src/utils/generic';

import { CompanyQuestion, CustomSection, QuestionAnswer } from 'src/types/shared/Companies';
import {
    updateCompanyQuestionFailure,
    updateCompanyQuestionRequest,
    updateCompanyQuestionSuccess,
} from '@actions/companyQuestions/updateCompanyQuestion';
import {
    createCompanyQuestionFailure,
    createCompanyQuestionRequest,
    createCompanyQuestionSuccess,
} from '@actions/companyQuestions/createCompanyQuestion';
import {
    deleteCompanyQuestionFailure,
    deleteCompanyQuestionRequest,
    deleteCompanyQuestionSuccess,
} from '@actions/companyQuestions/deleteCompanyQuestion';
import {
    fetchCompanyQuestionFailure,
    fetchCompanyQuestionRequest,
    fetchCompanyQuestionSuccess,
} from '@actions/companyQuestions/fetchCompanyQuestion';
import {
    fetchAllQuestionAnswersFailure,
    fetchAllQuestionAnswersRequest,
    fetchAllQuestionAnswersSuccess,
} from '@actions/companyQuestions/answers/fetchAllQuestionAnswers';
import {
    updateAnswerFailure,
    updateAnswerRequest,
    updateAnswerSuccess,
} from '@actions/companyQuestions/answers/updateAnswer';
import {
    deleteAnswerFailure,
    deleteAnswerRequest,
    deleteAnswerSuccess,
} from '@actions/companyQuestions/answers/deleteAnswer';
import {
    createAnswerFailure,
    createAnswerRequest,
    createAnswerSuccess,
} from '@actions/companyQuestions/answers/createAnswer';
import {
    postSortCompanyQuestionsFailure,
    postSortCompanyQuestionsRequest,
    postSortCompanyQuestionsSuccess,
} from '@actions/companyQuestions/postSortCompanyQuestions';
import {
    postSortQuestionAnswersFailure,
    postSortQuestionAnswersRequest,
    postSortQuestionAnswersSuccess,
} from '@actions/companyQuestions/answers/postSortQuestionAnswers';
import {
    fetchCompanyOnboardingSectionsFailure,
    fetchCompanyOnboardingSectionsRequest,
    fetchCompanyOnboardingSectionsSuccess,
    fetchImportSectionsSuccess,
} from '@actions/companyQuestions/fetchCompanyOnboardingSections';
import {
    postCopyCompanyQuestionsFailure,
    postCopyCompanyQuestionsRequest,
    postCopyCompanyQuestionsSuccess,
} from '@actions/companyQuestions/postCopyCompanyQuestions';
import {
    createCompanySectionRequest,
    createCompanySectionSuccess,
    createCompanySectionFailure,
} from '@actions/companyQuestions/createCompanySection';
import {
    deleteCompanySectionFailure,
    deleteCompanySectionRequest,
    deleteCompanySectionSuccess,
} from '@actions/companyQuestions/deleteCompanySection';
import {
    updateCompanySectionRequest,
    updateCompanySectionSuccess,
    updateCompanySectionFailure,
} from '@actions/companyQuestions/updateCompanySection';
import {
    postSortCompanySectionsFailure,
    postSortCompanySectionsRequest,
    postSortCompanySectionsSuccess,
} from '@actions/companyQuestions/postSortCompanySections';

interface CompanyQuestionsState {
    sections: Record<number, CustomSection>;
    answers: Record<number, QuestionAnswer>;
    isFetching: boolean;
    isFetchingAnswers: boolean;
    isFetchingOnboarding: boolean;
    isPosting: boolean;
    postSuccess: boolean;
    error: string | null;
    onboardingQuestions: Record<number, CompanyQuestion>;
    importingSections: Record<number, CustomSection>;
}

const initialState: CompanyQuestionsState = {
    sections: {},
    answers: {},
    isFetching: false,
    isFetchingAnswers: false,
    isFetchingOnboarding: false,
    isPosting: false,
    postSuccess: false,
    error: null,
    onboardingQuestions: {},
    importingSections: {},
};

export default createReducer(initialState, {
    [fetchAllCompanyQuestionsRequest.type]: handleFetchRequest,
    [fetchAllCompanyQuestionsSuccess.type]: handleFetchAllQuestionsSuccess,
    [fetchAllCompanyQuestionsFailure.type]: handleFailure,
    [fetchCompanyQuestionRequest.type]: handleFetchRequest,
    [fetchCompanyQuestionSuccess.type]: handleFetchSingleQuestionSuccess,
    [fetchCompanyQuestionFailure.type]: handleFailure,
    [updateCompanyQuestionRequest.type]: handlePostRequest,
    [updateCompanyQuestionSuccess.type]: handlePatchQuestionSuccess,
    [updateCompanyQuestionFailure.type]: handleFailure,
    [createCompanyQuestionRequest.type]: handlePostRequest,
    [createCompanyQuestionSuccess.type]: handlePostQuestionSuccess,
    [createCompanyQuestionFailure.type]: handleFailure,
    [deleteCompanyQuestionRequest.type]: handlePostRequest,
    [deleteCompanyQuestionSuccess.type]: handleDeleteQuestionSuccess,
    [deleteCompanyQuestionFailure.type]: handleFailure,
    [fetchAllQuestionAnswersRequest.type]: handleFetchAllAnswersRequest,
    [fetchAllQuestionAnswersSuccess.type]: handleFetchAllQuestionAnswersSuccess,
    [fetchAllQuestionAnswersFailure.type]: handleFailure,
    [updateAnswerRequest.type]: handlePostRequest,
    [updateAnswerSuccess.type]: handleUpdateAnswerSuccess,
    [updateAnswerFailure.type]: handleFailure,
    [deleteAnswerRequest.type]: handlePostRequest,
    [deleteAnswerSuccess.type]: handleDeleteAnswerSuccess,
    [deleteAnswerFailure.type]: handleFailure,
    [createAnswerRequest.type]: handlePostRequest,
    [createAnswerSuccess.type]: handlePostAnswerSuccess,
    [createAnswerFailure.type]: handleFailure,
    [postSortCompanyQuestionsRequest.type]: handleFetchRequest,
    [postSortCompanyQuestionsSuccess.type]: handleSortQuestionsSuccess,
    [postSortCompanyQuestionsFailure.type]: handleFailure,
    [postSortQuestionAnswersRequest.type]: handleFetchAllAnswersRequest,
    [postSortQuestionAnswersFailure.type]: handleFailure,
    [postSortQuestionAnswersSuccess.type]: handleFetchAllQuestionAnswersSuccess,
    [fetchCompanyOnboardingSectionsRequest.type]: fetchOnboardingSectionsRequest,
    [fetchCompanyOnboardingSectionsSuccess.type]: handleFetchOnboardingSuccess,
    [fetchCompanyOnboardingSectionsFailure.type]: handleFailure,
    [postCopyCompanyQuestionsRequest.type]: handlePostRequest,
    [postCopyCompanyQuestionsSuccess.type]: handleCopySuccess,
    [postCopyCompanyQuestionsFailure.type]: handleFailure,
    [createCompanySectionRequest.type]: handlePostRequest,
    [createCompanySectionSuccess.type]: handlePostSectionSuccess,
    [createCompanySectionFailure.type]: handleFailure,
    [deleteCompanySectionRequest.type]: handlePostRequest,
    [deleteCompanySectionSuccess.type]: handleDeleteSectionSuccess,
    [deleteCompanySectionFailure.type]: handleFailure,
    [updateCompanySectionRequest.type]: handlePostRequest,
    [updateCompanySectionSuccess.type]: handleUpdateSectionSuccess,
    [updateCompanySectionFailure.type]: handleFailure,
    [postSortCompanySectionsRequest.type]: handlePostRequest,
    [postSortCompanySectionsSuccess.type]: handlePostSortSectionsSuccess,
    [postSortCompanySectionsFailure.type]: handleFailure,
    [fetchImportSectionsSuccess.type]: handleFetchImportSectionsSuccess,
});

function handleFetchRequest(state: CompanyQuestionsState) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchAllQuestionsSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<CustomSection[]>,
) {
    state.isFetching = false;
    state.sections = convertArrToObj(action.payload);
}

function handleFetchImportSectionsSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<CustomSection[]>,
) {
    state.isFetching = false;
    state.importingSections = convertArrToObj(action.payload);
}

function handleSortQuestionsSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<CompanyQuestion[]>,
) {
    const sectionID = action.payload[0].sectionID;
    state.isFetching = false;
    state.sections[sectionID].questions = action.payload;
}

function handleFetchSingleQuestionSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<CompanyQuestion>,
) {
    const targetIndex = state.sections[action.payload.sectionID].questions.findIndex(
        q => q.id === action.payload.id,
    );
    state.isFetching = false;
    state.sections[action.payload.sectionID].questions[targetIndex] = action.payload;
}

function handlePostRequest(state: CompanyQuestionsState) {
    state.isPosting = true;
    state.error = null;
    state.postSuccess = false;
}

function handlePostQuestionSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<CompanyQuestion>,
) {
    state.sections[action.payload.sectionID].questions = [
        ...state.sections[action.payload.sectionID].questions,
        action.payload,
    ];
    state.postSuccess = true;
    state.isPosting = false;
}

function handlePatchQuestionSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<CompanyQuestion>,
) {
    const targetIndex = state.sections[action.payload.sectionID].questions.findIndex(
        q => q.id === action.payload.id,
    );
    state.sections[action.payload.sectionID].questions[targetIndex] = action.payload;
    state.postSuccess = true;
    state.isPosting = false;
}

function handlePostSectionSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<CustomSection>,
) {
    state.sections[action.payload.id] = action.payload;
    state.postSuccess = true;
    state.isPosting = false;
}

function handleUpdateSectionSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<CustomSection>,
) {
    state.sections[action.payload.id] = action.payload;
    state.postSuccess = true;
    state.isPosting = false;
}

function handleFailure(state: CompanyQuestionsState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isFetching = false;
    state.isFetchingAnswers = false;
    state.isPosting = false;
}

function handleDeleteQuestionSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<CompanyQuestion>,
) {
    const targetIndex = state.sections[action.payload.sectionID].questions.findIndex(
        q => q.id === action.payload.id,
    );
    state.isPosting = false;
    state.postSuccess = true;
    state.sections[action.payload.sectionID].questions.splice(targetIndex, 1);
}

function handleDeleteSectionSuccess(state: CompanyQuestionsState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.sections[action.payload];
}

function handleFetchAllAnswersRequest(state: CompanyQuestionsState) {
    state.isFetchingAnswers = true;
}

function handleFetchAllQuestionAnswersSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<QuestionAnswer[]>,
) {
    state.isFetchingAnswers = false;
    state.answers = convertArrToObj(action.payload);
}

function handleUpdateAnswerSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<QuestionAnswer>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    state.answers[action.payload.id] = action.payload;
}

function handleDeleteAnswerSuccess(state: CompanyQuestionsState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.answers[action.payload];
}

function handlePostAnswerSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<QuestionAnswer>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    state.answers[action.payload.id] = action.payload;
}

function fetchOnboardingSectionsRequest(state: CompanyQuestionsState) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchOnboardingSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<CustomSection[]>,
) {
    state.isFetching = false;
    state.sections = convertArrToObj(action.payload);
}

function handleCopySuccess(state: CompanyQuestionsState, action: PayloadAction<CustomSection[]>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.sections = { ...state.sections, ...convertArrToObj(action.payload) };
}

function handlePostSortSectionsSuccess(
    state: CompanyQuestionsState,
    action: PayloadAction<CustomSection[]>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    state.sections = convertArrToObj(action.payload);
}
