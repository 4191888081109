import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const deleteAssociationRequest = createAction('deleteAssociationRequest');
export const deleteAssociationSuccess = createAction<DeletePropsRequired>(
    'deleteAssociationSuccess',
);
export const deleteAssociationFailure = createAction('deleteAssociationFailure');

export const deleteAssociation =
    (props: DeleteProps) =>
    async (dispatch: AppDispatch): Promise<void> => {
        if (props.moduleID == null) return;
        const requiredDeletetionProps = props as DeletePropsRequired;
        const { companyID, moduleID } = requiredDeletetionProps;

        dispatch(deleteAssociationRequest());

        try {
            await api.delete(`company/${companyID}/modules/${moduleID}`);
            dispatch(deleteAssociationSuccess(requiredDeletetionProps));
        } catch (e) {
            handleApiErrors(dispatch, deleteAssociationFailure, e as APIError);
        }
    };

export interface DeleteProps {
    companyID: number;
    moduleID: number | null;
}

type DeletePropsRequired = NoNull<DeleteProps>;
type NoNull<Type> = {
    [Key in keyof Type]-?: NoNull<NonNullable<Type[Key]>>;
};
