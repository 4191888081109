import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'lib/src/hooks/useForm';
import { useParams } from 'react-router-dom';
import useFetchAllDimensions from '@pages/dimensions/hooks/useFetchAllDimensions';
import useFetchAllDimensionSubcategories from './useFetchAllDimensionSubcategories';
import usePrevious from 'lib/src/hooks/usePrevious';

import {
    selectDimensionSubcategories,
    selectDimensionSubcategoriesError,
    selectDimensionSubcategoriesIsFetching,
    selectDimensionSubcategoriesPostSuccess,
} from '@selectors/dimensionSubcategories';
import { selectDimensions } from '@selectors/dimensions';
import { setFieldErrors } from 'lib/src/redux/actions/fieldErrors';
import { copySubcategoryToDimension } from '@actions/dimensions';

const useImportDimensionSubcategories = (closeModal: () => void) => {
    const { dimensionID } = useParams<{ dimensionID: string }>();
    const dispatch = useDispatch();
    useFetchAllDimensionSubcategories();
    useFetchAllDimensions();

    const [form, handleChange] = useForm<{
        selectedDimensionID: number;
        selectedSubcategoryID: number;
    }>({
        selectedDimensionID: 0,
        selectedSubcategoryID: 0,
    });

    const subcategories = Object.values(useSelector(selectDimensionSubcategories));
    const isFetching = useSelector(selectDimensionSubcategoriesIsFetching);
    const error = useSelector(selectDimensionSubcategoriesError);

    const dimensions = Object.values(useSelector(selectDimensions));

    const postSuccess = useSelector(selectDimensionSubcategoriesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    const dimensionOptions = useMemo(() => {
        return dimensions.reduce((acc: { value: number; label: string }[], dimension) => {
            if (dimension.id !== +dimensionID) {
                acc.push({ value: dimension.id, label: dimension.name });
            }
            return acc;
        }, []);
    }, [dimensionID, dimensions]);

    const subcategoryOptions = useMemo(() => {
        return subcategories.reduce((acc: { value: number; label: string }[], subcategory) => {
            if (subcategory.dimensionID === form.selectedDimensionID) {
                acc.push({ value: subcategory.id, label: subcategory.name });
            }
            return acc;
        }, []);
    }, [form.selectedDimensionID, subcategories]);

    const handleSubmit = () => {
        const addError = (errors: any, newError: any): any => ({ ...errors, ...newError });
        const { selectedSubcategoryID } = form;
        if (!!selectedSubcategoryID) {
            dispatch(copySubcategoryToDimension(+dimensionID, selectedSubcategoryID));
        } else {
            let obj = {};
            if (!form.selectedDimensionID) {
                obj = addError(obj, { selectedModuleID: 'Please select a dimension' });
            }
            if (!form.selectedSubcategoryID) {
                obj = addError(obj, { selectedDimensionID: 'Please select a subcategory' });
            }
            return dispatch(setFieldErrors(obj));
        }
    };

    return {
        form,
        handleChange,
        isFetching,
        error,
        handleSubmit,
        dimensionOptions,
        subcategoryOptions,
    };
};

export default useImportDimensionSubcategories;
