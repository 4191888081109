import { useSelector } from 'react-redux';
import useFetchAnswers from './hooks/useFetchAnswers';
import { useAppSelector } from '../../../redux/store';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from 'lib/src/components/typography/Title';
import { getAllQuestionAnswers, getCompanyQuestion } from '@selectors/companyQuestions';
import QuestionAnswersTable from './QuestionAnswersTable';
import { RootState } from '@reducers/index';
import ActionButton from 'lib/src/components/button/ActionButton';
import { useState } from 'react';
import CreateAnswerModal from './CreateAnswerModal';
import { CompanyQuestion } from 'src/types/shared/Companies';
import DeleteAnswerModal from './DeleteAnswerModal';
import UpdateAnswerModal from './UpdateAnswerModal';

const QuestionAnswers = ({ questionID, sectionID }: QuestionAnswersProps) => {
    useFetchAnswers(questionID);
    const question = useAppSelector((state: RootState) =>
        getCompanyQuestion(state, questionID, sectionID),
    );
    const [showCreateAnswerModal, setShowCreateAnswerModal] = useState<boolean>(false);
    const [answerToUpdate, setAnswerToUpdate] = useState<number | null>(null);
    const [answerToDelete, setAnswerToDelete] = useState<number | null>(null);

    const answers = useSelector(getAllQuestionAnswers);

    return (
        <>
            <CreateHeader marginTop>
                <Title>Answers</Title>
                <ActionButton
                    color="green"
                    icon="plus"
                    onClick={() => setShowCreateAnswerModal(true)}
                >
                    Create answer
                </ActionButton>
            </CreateHeader>

            <QuestionAnswersTable
                answers={answers}
                questionID={questionID}
                setAnswerToDelete={setAnswerToDelete}
                setAnswerToUpdate={setAnswerToUpdate}
            />
            {showCreateAnswerModal && !!question && (
                <CreateAnswerModal
                    question={question as CompanyQuestion}
                    closeModal={() => setShowCreateAnswerModal(false)}
                />
            )}
            {!!answerToDelete && !!question && (
                <DeleteAnswerModal
                    answerID={answerToDelete}
                    question={question}
                    closeModal={() => setAnswerToDelete(null)}
                />
            )}
            {!!answerToUpdate && (
                <UpdateAnswerModal
                    answerID={answerToUpdate}
                    closeModal={() => setAnswerToUpdate(null)}
                />
            )}
        </>
    );
};

interface QuestionAnswersProps {
    questionID: number;
    sectionID: number;
}

export default QuestionAnswers;
