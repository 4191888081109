import useDeleteCompanySection from './hooks/useDeleteCompanySection';

import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import { CustomSection } from 'src/types/shared/Companies';

const DeleteCompanySectionModal = ({ section, closeModal }: DeleteModalProps) => {
    const { handleDeleteCompanySection, isPosting, error } = useDeleteCompanySection(
        section,
        closeModal,
    );

    return (
        <ConfirmModal
            title="Delete Section"
            description={`Are you sure you want to delete this section?`}
            closeModal={closeModal}
            handleSubmit={handleDeleteCompanySection}
            isPosting={isPosting}
            error={error}
        />
    );
};

interface DeleteModalProps {
    section: CustomSection;
    closeModal: () => void;
}
export default DeleteCompanySectionModal;
