import useDeleteDimensionQuestion from '@pages/dimensionQuestions/hooks/useDeleteDimensionQuestion';

import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import { DimensionQuestion } from 'src/types/shared/Dimensions';

const DeleteDimensionQuestionModal = ({ question, closeModal }: DeleteModalProps) => {
    const { handleDelete, isPosting, error } = useDeleteDimensionQuestion(question);

    return (
        <ConfirmModal
            title={`Delete Question - ${question.text}`}
            description={`Are you sure you want to delete this question?`}
            closeModal={closeModal}
            handleSubmit={handleDelete}
            isPosting={isPosting}
            error={error}
        />
    );
};

interface DeleteModalProps {
    question: DimensionQuestion;
    closeModal: () => void;
}
export default DeleteDimensionQuestionModal;
