import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';

import {
    getCompanyQuestionPostSuccess,
    getCompanyQuestionsError,
    getCompanyQuestionsIsPosting,
} from '@selectors/companyQuestions';

import { createCompanySection } from '@actions/companyQuestions/createCompanySection';
import { CustomSectionPost } from 'src/types/shared/Companies';

const initialFormData: FormState = {
    companyID: 0,
    sort: 0,
    name: '',
    description: '',
    imageS3Key: [] as string[],
};

const useCreateCompanySection = (companyID: number, sortValue: number, closeModal: () => void) => {
    const dispatch = useDispatch();

    const isPosting = useSelector(getCompanyQuestionsIsPosting);
    const postSuccess = useSelector(getCompanyQuestionPostSuccess);
    const error = useSelector(getCompanyQuestionsError);
    const prevPostSuccess = usePrevious(postSuccess);

    const [form, handleChange] = useForm({ ...initialFormData, companyID });

    const handleSubmit = useCallback(() => {
        const formattedForm: CustomSectionPost = {
            ...form,
            imageS3Key:
                Array.isArray(form.imageS3Key) && form.imageS3Key.length > 0
                    ? form.imageS3Key[0]
                    : null,
        };

        dispatch(createCompanySection(companyID, formattedForm));
    }, [dispatch, form, companyID]);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        form,
        handleChange,
        handleSubmit,
        isPosting,
        error,
    };
};

interface FormState {
    companyID: number;
    sort: number;
    name: string;
    description: string;
    imageS3Key: string[];
}

export default useCreateCompanySection;
