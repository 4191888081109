import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Dimension from '@pages/dimensions/single/Dimension';
import DimensionQuestion from '@pages/dimensionQuestions/single/DimensionQuestion';
import DimensionSubcategory from '@pages/dimensionSubcategories/single/DimensionSubcategory';

const ModulesRoutes: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}/:dimensionID`}>
                <Dimension />
            </Route>
            <Route exact path={`${path}/:dimensionID/subcategories/:subcategoryID`}>
                <DimensionSubcategory />
            </Route>
            <Route
                exact
                path={`${path}/:dimensionID/subcategories/:subcategoryID/questions/:questionID`}
            >
                <DimensionQuestion />
            </Route>
        </Switch>
    );
};

export default ModulesRoutes;
