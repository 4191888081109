import useForm from 'lib/src/hooks/useForm';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';

import {
    getAccessCode,
    getAccessCodesError,
    getAccessCodesIsFetching,
    getAccessCodesPostSuccess,
} from '@selectors/accessCodes';

import { updateAccessCode } from '@actions/accessCodes';
import { RootState } from '@reducers/index';

const useCreateAccessCode = (codeID: number, closeModal: () => void) => {
    const dispatch = useDispatch();
    const error = useSelector(getAccessCodesError);
    const isPosting = useSelector(getAccessCodesIsFetching);
    const postSuccess = useSelector(getAccessCodesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    const { accessCode, maxUses, startDate, endDate } = useSelector((state: RootState) =>
        getAccessCode(state, codeID),
    );

    const [form, handleChange] = useForm<FormState>({ accessCode, maxUses, startDate, endDate });

    const handleSubmit = () => {
        const postBody = {
            ...form,
            maxUses: form.maxUses || null,
            startDate: form.startDate || null,
            endDate: form.endDate || null,
        };
        dispatch(updateAccessCode(codeID, postBody));
    };

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        form,
        handleChange,
        closeModal,
        handleSubmit,
        isPosting,
        error,
    };
};

interface FormState {
    accessCode: string;
    maxUses: number | null;
    startDate: Date | null;
    endDate: Date | null;
}

export default useCreateAccessCode;
