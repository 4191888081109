import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useForm from 'lib/src/hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';

import { createCompany } from '@actions/companies';
import {
    getCompaniesError,
    getCompaniesIsPosting,
    getCompaniesPostSuccess,
} from '@selectors/companies';

import { v1 } from 'uuid';
import { removeObjItem, sanitizeWysiwyg, updateObj } from 'lib/src/utils/generic';

const initialFormData: FormState = {
    companyReference: '',
    startDate: null,
    endDate: null,
    couldImproveMessage: '',
    isEnabled: false,
    imageS3Key: [],
    supportServiceLogoS3Key: [],
    supportLink: '',
    hideDimensionWheel: false,
    languageCode: 'en',
};

const initialAccessCodeForm: IAccessCodeState = {};

const useCreateCompany = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const isPosting = useSelector(getCompaniesIsPosting);
    const postSuccess = useSelector(getCompaniesPostSuccess);
    const error = useSelector(getCompaniesError);
    const prevPostSuccess = usePrevious(postSuccess);

    const [form, handleChange] = useForm(initialFormData);
    const [accessCodesObject, setAccessCodesObject] = useState(initialAccessCodeForm);

    useEffect(() => {
        window.addEventListener('paste', (e: Event) => {
            if (!(e instanceof ClipboardEvent)) return;
            e.preventDefault();
            const text = e.clipboardData?.getData('text/plain') ?? '';
            document.execCommand('insertHTML', false, text);
        });
    }, []);

    const addAccessCode = () => {
        const guid = v1();
        const updated = updateObj(accessCodesObject, guid, {
            guid,
            accessCode: '',
            maxUses: 0,
            startDate: null,
            endDate: null,
        });

        setAccessCodesObject(updated);
    };

    const updateAccessCode = (guid: string, key: string, value: string | number | null | Date) => {
        const accessCode = accessCodesObject[guid];
        const updated = updateObj(accessCodesObject, guid, { ...accessCode, [key]: value });

        setAccessCodesObject(updated);
    };

    const removeAccessCode = (guid: string) => {
        const updated = removeObjItem(accessCodesObject, guid);

        setAccessCodesObject(updated);
    };

    const handleSubmit = useCallback(() => {
        let imageS3Key = null;
        let supportServiceLogoS3Key = null;
        const accessCodesArray = Object.values(accessCodesObject).reduce(
            (
                acc: any[],
                answer: {
                    guid: string;
                    accessCode: string;
                    maxUses: number | null;
                    startDate: Date | null;
                    endDate: Date | null;
                },
            ) => {
                const { accessCode, maxUses, startDate, endDate } = answer;

                const request = {
                    accessCode,
                    maxUses: maxUses || null,
                    startDate: startDate || null,
                    endDate: endDate || null,
                };

                return [...acc, request];
            },
            [],
        );

        if (Array.isArray(form.imageS3Key) && form.imageS3Key.length > 0) {
            imageS3Key = form.imageS3Key[0];
        }
        if (
            Array.isArray(form.supportServiceLogoS3Key) &&
            form.supportServiceLogoS3Key.length > 0
        ) {
            supportServiceLogoS3Key = form.supportServiceLogoS3Key[0];
        }

        const postBody = {
            ...form,
            imageS3Key,
            supportServiceLogoS3Key,
            accessCodes: accessCodesArray,
            couldImproveMessage: sanitizeWysiwyg(form.couldImproveMessage),
        };

        dispatch(createCompany(postBody));
    }, [accessCodesObject, dispatch, form]);

    const closeModal = useCallback(() => {
        history.push('/companies');
    }, [history]);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        form,
        handleChange,
        closeModal,
        handleSubmit,
        isPosting,
        error,
        accessCodesObject,
        addAccessCode,
        updateAccessCode,
        removeAccessCode,
    };
};

interface FormState {
    companyReference: string;
    startDate: Date | null;
    endDate: Date | null;
    couldImproveMessage: string;
    isEnabled: boolean;
    imageS3Key: string[];
    supportServiceLogoS3Key: string[];
    supportLink: string;
    hideDimensionWheel: boolean;
    languageCode: string;
}

interface IAccessCodeState {
    [key: string]: {
        guid: string;
        accessCode: string;
        maxUses: number;
        startDate: Date;
        endDate: Date;
    };
}

export default useCreateCompany;
