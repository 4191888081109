import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { AccessCode } from 'src/types/shared/AccessCode';

export const fetchSingleAccessCodeRequest = createAction('fetchSingleAccessCodeRequest');
export const fetchSingleAccessCodeSuccess = createAction<AccessCode>(
    'fetchSingleAccessCodeSuccess',
);
export const fetchSingleAccessCodeFailure = createAction('fetchSingleAccessCodeFailure');

export const fetchSingleAccessCode =
    (accessCodeID: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSingleAccessCodeRequest());

        try {
            const { data }: Response = await api.get(`access-codes/${accessCodeID}`);

            dispatch(fetchSingleAccessCodeSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSingleAccessCodeFailure, e as APIError);
        }
    };

interface Response {
    data: AccessCode;
}
