import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { Dimension, DimensionPost } from 'src/types/shared/Dimensions';
import { APIError } from 'lib/src/types/APIError';

export const createDimensionRequest = createAction('createDimensionRequest');
export const createDimensionSuccess = createAction<Dimension>('createDimensionSuccess');
export const createDimensionFailure = createAction('createDimensionFailure');

export const createDimension =
    (moduleID: number, postBody: DimensionPost) => async (dispatch: AppDispatch) => {
        dispatch(createDimensionRequest());
        try {
            const { data }: CreateDimensionReponse = await api.post(
                `modules/${moduleID}/dimensions`,
                postBody,
            );
            dispatch(createDimensionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createDimensionFailure, e as APIError);
        }
    };

interface CreateDimensionReponse {
    data: Dimension;
}
