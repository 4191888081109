import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createModule } from '@actions/modules/createModule';
import { getAllModules, getModulesPostSuccess } from '@selectors/modules';
import { ModuleRequestModel } from 'src/types/shared/Modules';

const useCreateModule = (closeModal: () => void) => {
    const dispatch = useDispatch();
    const postSuccess = useSelector(getModulesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);
    const modules = Object.values(useSelector(getAllModules) ?? []);

    const [form, handleChange] = useForm<ModuleRequestModel>({
        name: '',
        startDate: null,
        endDate: null,
        isActive: false,
        isScoring: false,
    });

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    const handleSubmit = () => {
        dispatch(createModule(form));
    };

    return { form, handleChange, handleSubmit, modules };
};

export default useCreateModule;
