import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';

import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

// Login
export const postLoginRequest = createAction('postLoginRequest');
export const postLoginSuccess = createAction<string>('postLoginSuccess');
export const postLoginFailure = createAction<string>('postLoginFailure');

export const postLogin =
    (postBody: LoginRequestModel) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postLoginRequest());

        try {
            const { data }: LoginResponseModel = await api.post('auth/login', postBody);
            localStorage.setItem('token', data.token);

            dispatch(postLoginSuccess(data.token));
        } catch (e) {
            handleApiErrors(dispatch, postLoginFailure, e as APIError);
        }
    };

interface LoginRequestModel {
    email: string;
    password: string;
}

interface LoginResponseModel {
    data: { token: string };
}
