import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectDimensionSubcategories,
    selectDimensionSubcategoriesIsFetching,
} from '@selectors/dimensionSubcategories';
import { fetchDimensionSubcategories } from '@actions/dimensionSubcategories/fetchDimensionSubcategories';
import { DimensionSubcategory } from 'src/types/shared/Dimensions';
import { moveItemInArray } from 'lib/src/utils/generic';
import { Column } from 'lib/src/types/table';

import Table from 'lib/src/components/table/Table';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import LinkButton from 'lib/src/components/button/LinkButton';
import DeleteDimensionSubcategoryModal from './modals/DeleteDimensionSubcategoryModal';
import { patchSortDimensionSubcategories } from '@actions/dimensionSubcategories/patchSortDimensionSubcategories';

const DimensionSubcategoriesTable = ({ dimensionID }: Props) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(selectDimensionSubcategoriesIsFetching);
    const dimensionSubcategories: Record<number, DimensionSubcategory> = useSelector(
        selectDimensionSubcategories,
    );

    const subcategories = Object.values(dimensionSubcategories).filter(
        question => question.dimensionID === +dimensionID,
    );

    useEffect(() => {
        dispatch(fetchDimensionSubcategories(dimensionID));
    }, [dispatch, dimensionID]);

    const handleSort = (questionID: number, direction: 'up' | 'down') => {
        const currentSorted = subcategories.sort((a, b) => a.sort - b.sort);
        const sortAdjustment = direction === 'up' ? -1 : 1;
        const indexToMove = currentSorted.reduce(
            (acc: number | null, curr: DimensionSubcategory) => {
                if (acc !== null) return acc;
                if (curr.id === questionID) return currentSorted.indexOf(curr);
                return acc;
            },
            null,
        );
        if (indexToMove === null) return;
        const newSubcategories: DimensionSubcategory[] = moveItemInArray(
            currentSorted,
            indexToMove,
            indexToMove + sortAdjustment,
        ).map((s, i) => ({ ...s, sort: i }));

        dispatch(patchSortDimensionSubcategories(dimensionID, newSubcategories));
    };

    const columns = (buttonProps: ButtonProps): Column<DimensionSubcategory>[] => [
        {
            key: 1,
            heading: '',
            getValue: row => (
                <ButtonRow alignment="left">
                    {row.sort > 1 && (
                        <ActionButton
                            className="mini-button"
                            type="button"
                            onClick={() => handleSort(row.id, 'up')}
                        >
                            ↑
                        </ActionButton>
                    )}
                    {row.sort < subcategories.length && (
                        <ActionButton
                            className="mini-button"
                            type="button"
                            onClick={() => handleSort(row.id, 'down')}
                        >
                            ↓
                        </ActionButton>
                    )}
                </ButtonRow>
            ),
        },
        {
            key: 2,
            heading: 'Subcategory name',
            getValue: row => row.name,
        },
        {
            key: 3,
            heading: '',
            getValue: row => (
                <ButtonRow alignment="right">
                    <LinkButton href={`/dimensions/${row.dimensionID}/subcategories/${row.id}`}>
                        View
                    </LinkButton>
                    <ActionButton
                        color="red"
                        onClick={() => {
                            buttonProps.setShowDeleteModal(true);
                            buttonProps.setSubcategorySelection(row.id);
                        }}
                    >
                        Delete
                    </ActionButton>
                </ButtonRow>
            ),
        },
    ];

    const sortedSubcategories = useMemo(() => {
        if (!subcategories) return [];
        return Object.values(subcategories).sort((a, b) => a.sort - b.sort);
    }, [subcategories]);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [subcategorySelection, setSubcategorySelection] = useState<number>(-1);
    return (
        <>
            {showDeleteModal && (
                <DeleteDimensionSubcategoryModal
                    id={subcategorySelection}
                    closeModal={() => setShowDeleteModal(false)}
                />
            )}
            <Table
                isLoading={isFetching}
                columns={columns({ setSubcategorySelection, setShowDeleteModal })}
                rows={sortedSubcategories}
            />
        </>
    );
};

interface ButtonProps {
    setShowDeleteModal: (props: boolean) => void;
    setSubcategorySelection: (props: number) => void;
}

interface Props {
    dimensionID: number;
}
export default DimensionSubcategoriesTable;
