import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { Module } from 'src/types/shared/Modules';
import { APIError } from 'lib/src/types/APIError';

export const patchSortCompanyModulesRequest = createAction('patchSortCompanyModulesRequest');
export const patchSortCompanyModulesSuccess = createAction<Module>(
    'patchSortCompanyModulesSuccess',
);
export const patchSortCompanyModulesFailure = createAction('patchSortCompanyModulesFailure');

export const patchSortCompanyModules =
    (id: number, modules: Module[]) => async (dispatch: AppDispatch) => {
        dispatch(patchSortCompanyModulesRequest());
        const sorts = modules
            .sort((a, b) => a.sort - b.sort)
            .map((a, i) => ({ id: a.id, sort: i + 1 }));
        const postBody: PostBody = { sorts };

        try {
            const { data }: UpdateModuleResponse = await api.patch(
                `company/${id}/modules/sort`,
                postBody,
            );

            dispatch(patchSortCompanyModulesSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, patchSortCompanyModulesFailure, e as APIError);
        }
    };

interface PostBody {
    sorts: { id: number; sort: number }[];
}

interface UpdateModuleResponse {
    data: Module;
}
