import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';

import {
    getAllCompanyImportingSections,
    getCompanyQuestionPostSuccess,
    getCompanyQuestionsError,
    getCompanyQuestionsIsPosting,
} from '@selectors/companyQuestions';

import { createCompanyQuestion } from '@actions/companyQuestions/createCompanyQuestion';
import {
    SliderOptions,
    importingQuestionDropdownOptions,
    CustomSection,
    CompanyQuestion,
    QuestionAnswer,
} from '../../../types/shared/Companies';
import { getAllCompanies, getCompaniesIsFetching } from '@selectors/companies';
import { DropdownOption } from 'lib/src/types/shared/DropdownOption';
import { fetchAllCompanies } from '@actions/companies';
import { fetchCompanyOnboardingSections } from '@actions/companyQuestions/fetchCompanyOnboardingSections';
import { removeObjItem, updateObj } from 'lib/src/utils/generic';
import { v1 } from 'uuid';

const initialFormData: FormState = {
    importingQuestion: null,
    companyToImportFrom: null,
    questionToImport: null,
    companyID: 0,
    questionText: '',
    isRequired: false,
    type: 1,
    sliderOptions: {
        minValue: 0,
        maxValue: 10,
        increment: 1,
    },
};

const initialAnswerForm: AnswerState = {};

const useCreateCompanyQuestion = (
    companyID: number,
    sectionID: number,
    setSectionToCreateQuestion: React.Dispatch<React.SetStateAction<number | null>>,
) => {
    const dispatch = useDispatch();

    const isPosting = useSelector(getCompanyQuestionsIsPosting);
    const isFetchingCompanies = useSelector(getCompaniesIsFetching);
    const postSuccess = useSelector(getCompanyQuestionPostSuccess);
    const error = useSelector(getCompanyQuestionsError);
    const companies = useSelector(getAllCompanies);
    const importingSections = useSelector(getAllCompanyImportingSections);

    const [form, handleChange] = useForm({ ...initialFormData, companyID });
    const [answersObject, setAnswersObject] = useState(initialAnswerForm);

    const prevProps = usePrevious({
        postSuccess,
        questionToImport: form.questionToImport,
        companyToImportForm: form.companyToImportFrom,
    });

    const companyToImportDropdownOptions: DropdownOption<number>[] = Object.values(companies).map(
        c => ({ value: c.id, label: c.companyReference }),
    );

    const questionsDropdownOptions: DropdownOption<number>[] = Object.values(
        importingSections,
    ).reduce((acc: DropdownOption<number>[], curr: CustomSection) => {
        if (curr.questions.length)
            return [...acc, ...curr.questions.map(q => ({ value: q.id, label: q.questionText }))];
        else return acc;
    }, []);

    const handleSubmit = useCallback(() => {
        Object.values(answersObject).forEach(object => {
            delete object.id;
        });

        const newForm = {
            companyID: form.companyID,
            questionText: form.questionText,
            isRequired: form.isRequired,
            type: form.type,
            sliderOptions: form.sliderOptions,
        };

        dispatch(
            createCompanyQuestion(sectionID, { ...newForm, answers: Object.values(answersObject) }),
        );
    }, [dispatch, form, sectionID, answersObject]);

    const handleImportQuestion = useCallback(
        (question: CompanyQuestion) => {
            handleChange('type', question.type);
            handleChange('questionText', question.questionText);
            handleChange('isRequired', question.isRequired);
            handleChange('sliderOptions', question.sliderOptions);
            setAnswersObject({});

            const newAnswers = {} as AnswerState;
            question.answers.forEach((a: QuestionAnswer) => {
                newAnswers[a.id] = { id: a.id, answerText: a.answerText, sort: a.sort };
            });
            setAnswersObject(newAnswers);
        },
        [handleChange],
    );

    const closeModal = useCallback(() => {
        dispatch(fetchCompanyOnboardingSections(companyID));
        setSectionToCreateQuestion(null);
    }, [dispatch, companyID, setSectionToCreateQuestion]);

    useEffect(() => {
        dispatch(fetchAllCompanies());
    }, [dispatch]);

    useEffect(() => {
        if (
            !!form.companyToImportFrom &&
            !Number.isNaN(form.companyToImportFrom) &&
            form.companyToImportFrom !== prevProps.companyToImportForm
        ) {
            dispatch(fetchCompanyOnboardingSections(form.companyToImportFrom, true));
        }
    }, [dispatch, form.companyToImportFrom, prevProps.companyToImportForm]);

    useEffect(() => {
        if (postSuccess && !prevProps.postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevProps.postSuccess, closeModal]);

    useEffect(() => {
        if (
            form.questionToImport &&
            !Number.isNaN(form.questionToImport) &&
            form.questionToImport !== prevProps.questionToImport
        ) {
            try {
                const thisQuestion: CompanyQuestion | null = Object.values(
                    importingSections,
                ).reduce((acc: CompanyQuestion | null, curr: CustomSection) => {
                    if (curr.questions.length) {
                        const question = curr.questions.find(q => q.id === form.questionToImport);

                        if (question) return question;
                        else return acc;
                    } else return acc;
                }, null);
                if (!!thisQuestion) handleImportQuestion(thisQuestion);
            } catch (e) {
                console.warn(e);
            }
        }
    }, [
        form.questionToImport,
        prevProps.questionToImport,
        handleImportQuestion,
        importingSections,
    ]);

    const addAnswerObj = () => {
        const id = v1();
        const updated = updateObj(answersObject, id, { id: id, answerText: '' });

        setAnswersObject(updated);
    };

    const updateAnswer = (key: string, value: string) => {
        const updated = updateObj(answersObject, key, { id: key, answerText: value });

        setAnswersObject(updated);
    };

    function removeAnswer(key: string) {
        const updated = removeObjItem(answersObject, key);

        setAnswersObject(updated);
    }

    return {
        form,
        handleChange,
        closeModal,
        handleSubmit,
        isPosting,
        error,
        answersObject,
        importingQuestionDropdownOptions,
        companyToImportDropdownOptions,
        isFetchingCompanies,
        questionsDropdownOptions,
        addAnswerObj,
        updateAnswer,
        removeAnswer,
    };
};

interface FormState {
    importingQuestion: number | null;
    companyToImportFrom: number | null;
    questionToImport: number | null;
    companyID: number;
    questionText: string;
    isRequired: boolean;
    type: number;
    sliderOptions: SliderOptions;
}

interface AnswerState {
    [key: number]: { id: number; answerText: string; sort?: number };
}

export default useCreateCompanyQuestion;
