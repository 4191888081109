import { RootState } from '@reducers/index';

export const selectDimensionQuestions = (state: RootState) =>
    state.dimensionQuestionsReducer.dimensionQuestions;
export const selectDimensionQuestion = (state: RootState, id: number) =>
    state.dimensionQuestionsReducer.dimensionQuestions[id];

export const selectDimensionQuestionsIsFetching = (state: RootState) =>
    state.dimensionQuestionsReducer.isFetching;

export const selectDimensionQuestionsIsPosting = (state: RootState) =>
    state.dimensionQuestionsReducer.isPosting;
export const selectDimensionQuestionPostSuccess = (state: RootState) =>
    state.dimensionQuestionsReducer.postSuccess;

export const selectDimensionQuestionsError = (state: RootState) =>
    state.dimensionQuestionsReducer.error;

export const selectDimensionAnswersIsFetching = (state: RootState) =>
    state.dimensionQuestionsReducer.isFetchingAnswers;
export const selectDimensionAnswers = (state: RootState) => state.dimensionQuestionsReducer.answers;
export const selectDimensionAnswersError = (state: RootState) =>
    state.dimensionQuestionsReducer.answerError;
export const selectDimensionAnswersIsPosting = (state: RootState) =>
    state.dimensionQuestionsReducer.isPostingAnswers;
export const selectDimensionAnswersPostSuccess = (state: RootState) =>
    state.dimensionQuestionsReducer.answerPostSuccess;
