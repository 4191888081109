import useUpdateCompany from './hooks/useUpdateCompany';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import TextInput from 'lib/src/components/form/TextInput';
import FormRow from 'lib/src/components/form/FormRow';
import DatePicker from 'lib/src/components/form/DatePicker';
import { Company } from 'src/types/shared/Companies';
import FilePicker from 'lib/src/components/filePicker/FilePicker';
import { API_URL } from 'lib/src/utils/api';
import config from '../../../config/index';
import ToggleSwitch from 'lib/src/components/form/ToggleSwitch';
import Title from 'lib/src/components/typography/Title';
import WYSIWYG from 'lib/src/components/form/WYSIWYG';

const { S3_URL } = config;

const UpdateCompanyModal = ({ item, closeModal }: UpdateCompanyModalProps) => {
    const {
        form: {
            companyReference,
            startDate,
            endDate,
            couldImproveMessage,
            imageS3Key,
            isEnabled,
            supportServiceLogoS3Key,
            supportLink,
            hideDimensionWheel,
            languageCode,
        },
        handleSubmit,
        handleChange,
        isPosting,
    } = useUpdateCompany(item, closeModal);

    return (
        <Modal title={`Update company - ${companyReference}`} closeModal={closeModal} size="medium">
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting}>
                <FormRow>
                    <TextInput
                        name="companyReference"
                        value={companyReference}
                        onChange={handleChange}
                        label="Company reference"
                        required
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        name="startDate"
                        value={startDate && new Date(startDate)}
                        onChange={handleChange}
                        label="Start date"
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        name="endDate"
                        value={endDate && new Date(endDate)}
                        onChange={handleChange}
                        label="End date"
                    />
                </FormRow>
                <br />
                <FormRow>
                    <ToggleSwitch
                        name="isEnabled"
                        value={isEnabled}
                        onChange={handleChange}
                        label="Is Company Active?"
                    />
                </FormRow>
                <br />
                <FormRow>
                    <ToggleSwitch
                        name="hideDimensionWheel"
                        value={hideDimensionWheel}
                        onChange={handleChange}
                        label="Hide dimension wheel"
                    />
                </FormRow>
                <br />
                <FormRow>
                    <FilePicker
                        name="imageS3Key"
                        value={imageS3Key}
                        onChange={handleChange}
                        label="Company logo"
                        apiURL={API_URL}
                        storageURL={S3_URL}
                        maxFiles={1}
                        className="small-image"
                    />
                </FormRow>
                <Title>Support message</Title>
                <FormRow>
                    <FilePicker
                        name="supportServiceLogoS3Key"
                        value={supportServiceLogoS3Key}
                        onChange={handleChange}
                        label="Support service logo"
                        apiURL={API_URL}
                        storageURL={S3_URL}
                        maxFiles={1}
                        className="small-image"
                    />
                </FormRow>
                <FormRow>
                    <WYSIWYG
                        name="couldImproveMessage"
                        value={couldImproveMessage}
                        onChange={handleChange}
                        label="Could improve message"
                        limitInputToMax
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="supportLink"
                        value={supportLink}
                        onChange={handleChange}
                        label="Support link"
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="languageCode"
                        value={languageCode}
                        onChange={handleChange}
                        label="Language code"
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface UpdateCompanyModalProps {
    item: Company;
    closeModal: () => void;
}

export default UpdateCompanyModal;
