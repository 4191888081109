import React from 'react';

import { CustomSection } from 'src/types/shared/Companies';
import Loading from '@components/layout/loading/Loading';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import { moveItemInArray } from 'lib/src/utils/generic';
import AccordionItem from './AccordionItem';

const Accordion = ({
    setSectionToCreateQuestion,
    setSectionToUpdate,
    setSectionToDelete,
    sections,
    isFetching,
    handleViewSingle,
    postSort,
    tableComponent,
    noDataMessage = 'No sections yet',
    addText,
}: SectionProps) => {
    const handleSort = (sectionID: number, direction: 'up' | 'down') => {
        const currentSorted = sections.sort((a, b) => a.sort - b.sort);
        const sortAdjustment = direction === 'up' ? -1 : 1;

        const indexToMove = currentSorted.reduce((acc: number | null, curr: CustomSection) => {
            if (acc !== null) return acc;
            if (curr.id === sectionID) return currentSorted.indexOf(curr);
            return acc;
        }, null);

        if (indexToMove === null) return;

        const newSections: CustomSection[] = moveItemInArray(
            currentSorted,
            indexToMove,
            indexToMove + sortAdjustment,
        ).map((s, i) => ({ ...s, sort: i }));

        postSort(newSections);
    };

    return (
        <>
            {isFetching && <Loading />}
            {sections.sort((a, b) => a.sort - a.sort).length ? (
                sections
                    .sort((a, b) => a.sort - b.sort)
                    .map((section: CustomSection, i: number) => {
                        return (
                            <AccordionItem
                                key={i}
                                section={section}
                                handleSort={handleSort}
                                setSectionToUpdate={setSectionToUpdate}
                                setSectionToDelete={setSectionToDelete}
                                setSectionToCreateQuestion={setSectionToCreateQuestion}
                                handleViewSingle={handleViewSingle}
                                isFirst={i === 0}
                                isLast={i === Object.values(sections).length - 1}
                                tableComponent={tableComponent}
                                addText={addText}
                            />
                        );
                    })
            ) : (
                <ContentBlock>
                    <ContentRow>
                        <ContentItem label="">
                            <p>{noDataMessage}</p>
                        </ContentItem>
                    </ContentRow>
                </ContentBlock>
            )}
        </>
    );
};

interface SectionProps {
    setSectionToUpdate: React.Dispatch<React.SetStateAction<number | null>>;
    setSectionToCreateQuestion: React.Dispatch<React.SetStateAction<number | null>>;
    setSectionToDelete: React.Dispatch<React.SetStateAction<number | null>>;
    sections: any[];
    isFetching: boolean;
    handleViewSingle: (id: number) => void;
    postSort: (newSections: any[]) => void;
    tableComponent: (section: any) => JSX.Element;
    noDataMessage?: string;
    addText?: string;
}

export default Accordion;
