import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCompaniesError, getCompaniesIsPosting } from '@selectors/companies';
import { deleteAssociation } from '@actions/companies';
import { Company } from 'src/types/shared/Companies';
import { setFieldErrors } from 'lib/src/redux/actions/fieldErrors';

const useDeleteAssociation = (company: Company, closeModal: () => void) => {
    const dispatch = useDispatch();
    const { modules } = company;
    const [selectedModuleID, setSelectedModuleID] = useState<number | null>(null);

    const handleChange = (name: string, id: number | null) => {
        setSelectedModuleID(id);
    };

    const moduleOptions = useMemo(() => {
        return modules.map(x => ({
            value: x.id,
            label: x.name,
        }));
    }, [modules]);

    const handleSubmit = () => {
        if (selectedModuleID) {
            dispatch(deleteAssociation({ companyID: company.id, moduleID: selectedModuleID }));
            closeModal();
        } else {
            dispatch(setFieldErrors({ selectedCompanyID: 'Please select a module' }));
        }
    };

    const isPosting = useSelector(getCompaniesIsPosting);
    const error = useSelector(getCompaniesError);

    return {
        handleSubmit,
        isPosting,
        selectedModuleID,
        setSelectedModuleID,
        error,
        moduleOptions,
        handleChange,
    };
};

export default useDeleteAssociation;
