import { getModulesIsFetching } from '@selectors/modules';
import Table from 'lib/src/components/table/Table';
import { Column } from 'lib/src/types/table';
import { useSelector } from 'react-redux';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import { Company } from 'src/types/shared/Companies';
import dayjs from 'dayjs';

const ValidateTable = (companies: Company[]) => {
    if (!companies) return [];
    if (companies?.length > 0 && companies[0]?.id === 0) return [];
    return companies;
};

const ModulesCompanyTable = ({ companies }: ModulesCompaniesTableProps) => {
    const isFetching = useSelector(getModulesIsFetching);

    return (
        <Table
            isLoading={isFetching}
            columns={columns}
            rows={Object.values(ValidateTable(companies))}
        />
    );
};

const columns: Column<Company>[] = [
    {
        key: 1,
        heading: 'Company Reference',
        getValue: row => row.companyReference,
        searchable: true,
    },
    {
        key: 2,
        heading: 'Start date',
        getValue: row =>
            row.startDate ? dayjs(row.startDate).format('DD-MM-YYYY') : 'No start date',
        getSort: (a, b) =>
            a.startDate && b.startDate
                ? new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
                : 0,
        searchable: true,
    },
    {
        key: 3,
        heading: 'End date',
        getValue: row => (row.endDate ? dayjs(row.endDate).format('DD-MM-YYYY') : 'No end date'),
        getSort: (a, b) =>
            a.endDate && b.endDate
                ? new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
                : 0,
        searchable: true,
    },
    {
        key: 4,
        heading: 'Is company active?',
        getValue: row => (row.isEnabled ? 'Yes' : 'No'),
    },
    {
        key: 5,
        heading: 'Submission count',
        getValue: row => `${row.submissionCount ? row.submissionCount : '0'}`,
        getSort: (a, b) => a.submissionCount - b.submissionCount,
    },
    {
        key: 6,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton href={`/companies/${row.id}`}>View</LinkButton>
            </ButtonRow>
        ),
    },
];

interface ModulesCompaniesTableProps {
    companies: Company[];
}

export default ModulesCompanyTable;
