import { AccessCode as IAccessCode } from '../../types/shared/AccessCode';
import { Company } from '../../types/shared/Companies';

import Title from 'lib/src/components/typography/Title';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import CompanyModuleAssociationButtons from '@components/actions/CompanyModuleAssociationButtons';
import ModulesTable from '@pages/modules/ModulesTable';
import CreateAccessCodeAssociation from '@pages/accessCodes/modals/CreateAccessCodeAssociation';
import DeleteAccessCodeAssociation from '@pages/accessCodes/modals/DeleteAccessCodeAssociation';
import { Module } from '../../types/shared/Modules';

const AccessCode = ({ accessCode, company, modules, isFetching, error, sortAction }: IProps) => {
    return (
        <>
            <Title>Access code - {accessCode.accessCode}</Title>
            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Access code">
                        <p>{accessCode.accessCode}</p>
                    </ContentItem>
                    <ContentItem label="Number of uses">
                        <p>{accessCode.numberOfUses || '0'}</p>
                    </ContentItem>
                    <ContentItem label="Max uses">
                        <p>{accessCode.maxUses || 'Unlimited'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Company reference">
                        <p>{company.companyReference}</p>
                    </ContentItem>
                    <ContentItem label="Code start date">
                        <p>
                            {accessCode.startDate
                                ? new Date(accessCode.startDate).toLocaleDateString()
                                : 'N/A'}
                        </p>
                    </ContentItem>
                    <ContentItem label="Code end date">
                        <p>
                            {accessCode.endDate
                                ? new Date(accessCode.endDate).toLocaleDateString()
                                : 'N/A'}
                        </p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>

            <Title>Modules</Title>
            <p style={{ color: '#b8b8b8', marginBottom: 16 }}>
                If no associations are chosen, it will default to company modules.
            </p>
            <CompanyModuleAssociationButtons
                item={accessCode}
                CreateModal={CreateAccessCodeAssociation}
                DeleteModal={DeleteAccessCodeAssociation}
                name="Association"
            />
            <div style={{ minHeight: '2em' }} />
            <ModulesTable
                modules={modules}
                isFetching={isFetching}
                error={error}
                sortAction={sortAction}
                showSort
                hideDuplicate
            />
        </>
    );
};

interface IProps {
    accessCode: IAccessCode;
    company: Company;
    modules: Module[];
    isFetching: boolean;
    error: string | null;
    sortAction?: (modules: Module[]) => void;
}

export default AccessCode;
