import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../../../redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const deleteAnswerRequest = createAction('deleteAnswerRequest');
export const deleteAnswerSuccess = createAction<number>('deleteAnswerSuccess');
export const deleteAnswerFailure = createAction('deleteAnswerFailure');

export const deleteAnswer = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(deleteAnswerRequest());

    try {
        await api.delete(`onboarding/answers/${id}`);

        dispatch(deleteAnswerSuccess(id));
    } catch (e) {
        handleApiErrors(dispatch, deleteAnswerFailure, e as APIError);
    }
};
