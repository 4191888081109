import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/store';
import { batch, useDispatch, useSelector } from 'react-redux';
import { fetchModule } from '@actions/modules/fetchModule';
import { getModule, getAllModules, getModulesIsFetching } from '@selectors/modules';
import Module from './Module';
import { fetchAllModuleDimensions } from '@actions/dimensions/fetchAllModuleDimensions';

const ErrorNotFound: FC = () => <p>Could not find module</p>;

const Loading: FC = () => <p>Loading...</p>;

const ModuleContainer = () => {
    const dispatch = useDispatch();

    const { moduleID } = useParams<{
        moduleID: string;
    }>();

    useEffect(() => {
        batch(() => {
            dispatch(fetchModule(+moduleID));
            dispatch(fetchAllModuleDimensions(+moduleID));
        });
    }, [moduleID, dispatch]);

    const module = useAppSelector(state => getModule(state, +moduleID));
    const modules = useAppSelector(state => getAllModules(state));
    const isFetching = useSelector(getModulesIsFetching);
    if (!module && isFetching) return <Loading />;
    if (!module) return <ErrorNotFound />;

    return (
        <>
            <Module id={+moduleID} modules={modules} />
        </>
    );
};

export default ModuleContainer;
