import { RootState } from '@reducers/index';
import { Module } from 'src/types/shared/Modules';

export const getAllModules = (state: RootState): Record<number, Module> =>
    state.modulesReducer.modules;
export const getModule = (state: RootState, id: number): Module | undefined =>
    state.modulesReducer.modules[id];
export const getModulesError = (state: RootState): string | null => state.modulesReducer.error;

export const getModulesIsPosting = (state: RootState): boolean => state.modulesReducer.isPosting;

export const getModulesPostSuccess = (state: RootState): boolean =>
    state.modulesReducer.postSuccess;

export const getModulesIsFetching = (state: RootState): boolean => state.modulesReducer.isFetching;

export const getAccessCodeModules = (state: RootState) => state.modulesReducer.accessCodeModules;
