import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { APIError } from 'lib/src/types/APIError';
import { AccessCode } from 'src/types/shared/AccessCode';

export const createAccessCodeRequest = createAction('createAccessCodeRequest');
export const createAccessCodeSuccess = createAction<AccessCode>('createAccessCodeSuccess');
export const createAccessCodeFailure = createAction('createAccessCodeFailure');

export const createAccessCode =
    (companyID: number, postBody: PostBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(createAccessCodeRequest());

        try {
            const { data }: createAccessCodeResponse = await api.post(
                `company/${companyID}/access-codes`,
                postBody,
            );

            dispatch(createAccessCodeSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createAccessCodeFailure, e as APIError);
        }
    };

interface PostBody {
    accessCode: string;
    maxUses: number | null;
}

interface createAccessCodeResponse {
    data: AccessCode;
}
