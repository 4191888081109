import React, { useState } from 'react';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from 'lib/src/components/typography/Title';
import ActionButton from 'lib/src/components/button/ActionButton';

import { DimensionAnswer, DimensionQuestion } from '../../../types/shared/Dimensions';
import DimensionQuestionAnswersTable from '../answers/DimensionQuestionAnswersTable';
import CreateDimensionAnswerModal from '../answers/modal/CreateDimensionAnswerModal';
import DeleteDimensionAnswerModal from './modal/DeleteDimensionAnswerModal';
import UpdateDimensionAnswerModal from './modal/UpdateDimensionAnswerModal';

const DimensionQuestionAnswers = ({ question }: DimensionQuestionAnswersProps) => {
    const [showCreateAnswerModal, setShowCreateAnswerModal] = useState<boolean>(false);
    const [answerToUpdate, setAnswerToUpdate] = useState<DimensionAnswer | null>(null);
    const [answerToDelete, setAnswerToDelete] = useState<number | null>(null);

    return (
        <>
            <CreateHeader marginTop>
                <Title>Answers</Title>
                <ActionButton
                    color="green"
                    icon="plus"
                    onClick={() => setShowCreateAnswerModal(true)}
                >
                    Create answer
                </ActionButton>
            </CreateHeader>

            <DimensionQuestionAnswersTable
                setAnswerToUpdate={setAnswerToUpdate}
                setAnswerToDelete={setAnswerToDelete}
            />

            {showCreateAnswerModal && (
                <CreateDimensionAnswerModal
                    questionID={question.id}
                    closeModal={() => setShowCreateAnswerModal(false)}
                />
            )}
            {!!answerToDelete && !!question && (
                <DeleteDimensionAnswerModal
                    answerID={answerToDelete}
                    questionID={question.id}
                    closeModal={() => setAnswerToDelete(null)}
                />
            )}
            {!!answerToUpdate && (
                <UpdateDimensionAnswerModal
                    answer={answerToUpdate}
                    closeModal={() => setAnswerToUpdate(null)}
                />
            )}
        </>
    );
};

interface DimensionQuestionAnswersProps {
    question: DimensionQuestion;
}

export default DimensionQuestionAnswers;
