import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { CompanyQuestion } from 'src/types/shared/Companies';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const postSortCompanyQuestionsRequest = createAction('postSortCompanyQuestionsRequest');
export const postSortCompanyQuestionsSuccess = createAction<CompanyQuestion[]>(
    'postSortCompanyQuestionsSuccess',
);
export const postSortCompanyQuestionsFailure = createAction('postSortCompanyQuestionsFailure');

export const postSortCompanyQuestions =
    (sectionID: number, questions: CompanyQuestion[]) => async (dispatch: AppDispatch) => {
        dispatch(postSortCompanyQuestionsRequest());

        const sorts = questions
            .sort((a, b) => a.sort - b.sort)
            .map((a, i) => ({ id: a.id, sort: i }));
        const postBody: PostBody = { sorts };

        try {
            const { data } = await api.patch<PostBody, CompanyQuestion[]>(
                `onboarding/sections/${sectionID}/questions/sort`,
                postBody,
            );

            dispatch(postSortCompanyQuestionsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postSortCompanyQuestionsFailure, e as APIError);
        }
    };

interface Sort {
    id: number;
    sort: number;
}
interface PostBody {
    sorts: Sort[];
}
