import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { DimensionSubcategory } from '../../../types/shared/Dimensions';

export const fetchDimensionSubcategoriesRequest = createAction('fetchDimensionSubcategories');
export const fetchDimensionSubcategoriesSuccess = createAction<DimensionSubcategory[]>(
    'fetchDimensionSubcategoriesSuccess',
);
export const fetchDimensionSubcategoriesFailure = createAction(
    'fetchDimensionSubcategoriesFailure',
);

export const fetchDimensionSubcategories =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchDimensionSubcategoriesRequest());
        try {
            const { data }: Response = await api.get(`dimensions/${id}/subcategories`);

            dispatch(fetchDimensionSubcategoriesSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchDimensionSubcategoriesFailure, e as APIError);
        }
    };

interface Response {
    data: DimensionSubcategory[];
}
