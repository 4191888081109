import { combineReducers } from 'redux';

import fieldErrorsReducer from 'lib/src/redux/reducers/fieldErrors';
import redirectsReducer from 'lib/src/redux/reducers/redirects';
import darkModeReducer from 'lib/src/redux/reducers/darkMode';
import authReducer from './auth';
import adminUsersReducer from './adminUsers';
import companiesReducer from './companies';
import companyQuestionsReducer from './companyQuestions';
import modulesReducer from './modules';
import dimensionsReducer from './dimensions';
import dimensionQuestionsReducer from './dimensionQuestions';
import dimensionSubcategoriesReducer from './dimensionSubategories';
import accessCodesReducer from './accessCodes';

const rootReducer = combineReducers({
    authReducer,
    adminUsersReducer,
    accessCodesReducer,
    companiesReducer,
    companyQuestionsReducer,
    darkModeReducer,
    dimensionsReducer,
    dimensionSubcategoriesReducer,
    dimensionQuestionsReducer,
    fieldErrorsReducer,
    modulesReducer,
    redirectsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
