import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';

import { APIError } from 'lib/src/types/APIError';
import { Module } from '../../../types/shared/Modules';

export const fetchModulesForAccessCodeRequest = createAction('fetchModulesForAccessCodeRequest');
export const fetchModulesForAccessCodeSuccess = createAction<Module[]>(
    'fetchModulesForAccessCodeSuccess',
);
export const fetchModulesForAccessCodeFailure = createAction('fetchModulesForAccessCodeFailure');

export const fetchModulesForAccessCode = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchModulesForAccessCodeRequest());
    try {
        const { data }: Response = await api.get(`access-codes/${id}/modules`);
        dispatch(fetchModulesForAccessCodeSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchModulesForAccessCodeFailure, e as APIError);
    }
};

interface Response {
    data: Module[];
}
