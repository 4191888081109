import React from 'react';

import useSingleDimensionSubcategory from './hooks/useSingleDimensionSubcategory';

import Title from 'lib/src/components/typography/Title';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import Loading from '@components/layout/loading/Loading';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ModalActionButtons from '@components/actions/ModalActionButtons';
import DimensionQuestionsTable from '@pages/dimensions/DimensionQuestionTable';
import CreateDimensionQuestionModal from '@pages/dimensionQuestions/modals/CreateDimensionQuestionModal';
import UpdateDimensionSubcategoryModal from '../modals/UpdateDimensionSubcategoryModal';
import DeleteDimensionSubcategoryModal from '../modals/DeleteDimensionSubcategoryModal';
import ImportDimensionQuestionModal from '@pages/dimensionQuestions/modals/ImportDimensionQuestionModal';

const DimensionSubcategory = () => {
    const { subcategory } = useSingleDimensionSubcategory();

    if (!subcategory) return <Loading />;

    const {
        id,
        name,
        title,
        mediumScoreBoundary,
        highScoreBoundary,
        difficultyDescription,
        strengthDescription,
    } = subcategory;

    return (
        <>
            <Title>Subcategory - {name}</Title>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Name">
                        <p>{name}</p>
                    </ContentItem>
                    <ContentItem label="Title">
                        <p>{title}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Difficulty Description">
                        <p>{difficultyDescription}</p>
                    </ContentItem>
                    <ContentItem label="Strength Description">
                        <p>{strengthDescription}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Medium Score Boundary">
                        <p>{mediumScoreBoundary}</p>
                    </ContentItem>
                    <ContentItem label="High Score Boundary">
                        <p>{highScoreBoundary}</p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>

            <ModalActionButtons
                name="Questions"
                item={subcategory}
                CreateModal={CreateDimensionQuestionModal}
                UpdateModal={UpdateDimensionSubcategoryModal}
                DeleteModal={DeleteDimensionSubcategoryModal}
                ImportModal={ImportDimensionQuestionModal}
            />

            <div style={{ minHeight: '2em' }} />

            <Title>Questions </Title>

            <DimensionQuestionsTable subcategoryID={id} />
        </>
    );
};

export default DimensionSubcategory;
