import { useDispatch } from 'react-redux';
import useForm from 'lib/src/hooks/useForm';
import { Dimension, DimensionFormUpdate, DimensionPost } from '../../../types/shared/Dimensions';
import { updateDimension } from '@actions/dimensions/updateDimension';

const useUpdateDimension = (dimension: Dimension, closeModal: () => void) => {
    const dispatch = useDispatch();
    const {
        id,
        name,
        description,
        isActive,
        imageS3Key,
        lowScoreDetails,
        mediumScoreBoundary,
        mediumScoreDetails,
        highScoreBoundary,
        highScoreDetails,
        isScoring,
    } = dimension;

    const initialForm: DimensionFormUpdate = {
        name: name,
        description: description,
        isActive: isActive,
        imageS3Key: !imageS3Key ? null : [imageS3Key],
        lowScoreDetails: lowScoreDetails,
        mediumScoreBoundary: isScoring ? mediumScoreBoundary.toString() : '0',
        mediumScoreDetails: mediumScoreDetails,
        highScoreBoundary: isScoring ? highScoreBoundary.toString() : '0',
        highScoreDetails: highScoreDetails,
    };

    const [form, handleChange] = useForm(initialForm);

    const handleSubmit = () => {
        const formattedFormData: DimensionPost = {
            ...form,
            imageS3Key: !form.imageS3Key ? null : form.imageS3Key[0],
            highScoreBoundary: isScoring ? parseFloat(form.highScoreBoundary) : null,
            mediumScoreBoundary: isScoring ? parseFloat(form.mediumScoreBoundary) : null,
        };
        dispatch(updateDimension(id, formattedFormData));
        closeModal();
    };

    return { form, handleChange, handleSubmit, isScoring };
};

export default useUpdateDimension;
