import { useDispatch, useSelector } from 'react-redux';

import Table from 'lib/src/components/table/Table';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';

import {
    selectDimensionAnswers,
    selectDimensionAnswersIsFetching,
} from '@selectors/dimensionQuestions';

import { Column } from 'lib/src/types/table';
import { Dispatch, SetStateAction, useMemo } from 'react';

import { DimensionAnswer } from '../../../types/shared/Dimensions';
import { patchSortDimensionAnswers } from '@actions/dimensionQuestions';

const DimensionQuestionAnswersTable = ({
    setAnswerToDelete,
    setAnswerToUpdate,
}: DimensionQuestionAnswersTableProps) => {
    const dispatch = useDispatch();
    const columns: Column<DimensionAnswer>[] = [
        {
            key: 1,
            heading: 'Answer text',
            getValue: row => row.text,
        },
        {
            key: 2,
            heading: 'Score',
            getValue: row => row.score.toString(),
        },
        {
            key: 3,
            heading: '',
            getValue: (row, i, rows) => (
                <ButtonRow alignment="right">
                    {i > 0 && (
                        <ActionButton
                            className="mini-button"
                            type="button"
                            onClick={() => handleSort(row.id, row.questionID, 'up')}
                        >
                            ↑
                        </ActionButton>
                    )}
                    {i < rows.length - 1 && (
                        <ActionButton
                            className="mini-button"
                            type="button"
                            onClick={() => handleSort(row.id, row.questionID, 'down')}
                        >
                            ↓
                        </ActionButton>
                    )}
                    <ActionButton color="grey" icon="pencil" onClick={() => setAnswerToUpdate(row)}>
                        Edit
                    </ActionButton>
                    <ActionButton
                        icon="trash-alt"
                        onClick={() => setAnswerToDelete(row.id)}
                        color="red"
                        disabled={rows.length <= 2}
                    >
                        Delete
                    </ActionButton>
                </ButtonRow>
            ),
        },
    ];

    const isFetching = useSelector(selectDimensionAnswersIsFetching);
    const answers = Object.values(useSelector(selectDimensionAnswers)).sort(
        (a, b) => a.sort - b.sort,
    );

    const sortedAnswers = useMemo(() => {
        return answers;
    }, [answers]);

    const handleSort = (answerID: number, questionID: number, direction: 'up' | 'down') => {
        const currentSorted = Object.values(answers).sort((a, b) => a.sort - b.sort);
        const newAnswers: DimensionAnswer[] = [];
        const answerToMove = currentSorted.find(answer => answer.id === answerID);
        if (!answerToMove) return;

        const answer = { ...answerToMove };

        const sortAdjustment = direction === 'up' ? -1 : 1;

        answer.sort += sortAdjustment;

        for (let i = 0; i < currentSorted.length; i++) {
            const curAnswer = { ...currentSorted[i] };
            if (curAnswer.id === answerID) {
                curAnswer.sort += sortAdjustment;
            }
            if (curAnswer.id !== answerID && curAnswer.sort === answer.sort) {
                curAnswer.sort -= sortAdjustment;
            }
            newAnswers.push(curAnswer);
        }

        dispatch(patchSortDimensionAnswers(questionID, newAnswers));
    };

    return <Table isLoading={isFetching} columns={columns} rows={sortedAnswers} />;
};

interface DimensionQuestionAnswersTableProps {
    setAnswerToDelete: Dispatch<SetStateAction<number | null>>;
    setAnswerToUpdate: Dispatch<SetStateAction<DimensionAnswer | null>>;
}
export default DimensionQuestionAnswersTable;
