import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { CustomSection } from 'src/types/shared/Companies';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const updateCompanySectionRequest = createAction('updateCompanySectionRequest');
export const updateCompanySectionSuccess = createAction<CustomSection>(
    'updateCompanySectionSuccess',
);
export const updateCompanySectionFailure = createAction('updateCompanySectionFailure');

export const updateCompanySection =
    (id: number, postBody: PostBody) => async (dispatch: AppDispatch) => {
        dispatch(updateCompanySectionRequest());

        try {
            const { data }: ResponseModel = await api.patch(`onboarding/sections/${id}`, postBody);

            dispatch(updateCompanySectionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateCompanySectionFailure, e as APIError);
        }
    };

interface PostBody {
    name: string;
    description: string;
    imageS3Key: string | null;
}

interface ResponseModel {
    data: CustomSection;
}
