import dayjs from 'dayjs';
import { RefObject, useState } from 'react';

import { useSelector } from 'react-redux';

import { getCompaniesIsFetching } from '@selectors/companies';

import { Column } from 'lib/src/types/table';
import { AccessCode } from 'src/types/shared/AccessCode';

import Table from 'lib/src/components/table/Table';
import UpdateAccessCodeModal from '@pages/companies/single/accessCodes/modals/UpdateAccessCodeModal';
import DeleteAccessCodeModal from './accessCodes/modals/DeleteAccessCodeModal';
import AccessCodeButtons from '@pages/companies/single/AccessCodeButtons';

const AccessCodeTable = ({ accessCodes }: AccessCodeTableProps) => {
    const isFetching = useSelector(getCompaniesIsFetching);
    const [accessCodeToUpdate, setAccessCodeToUpdate] = useState<number | null>(null);
    const [accessCodeToDelete, setAccessCodeToDelete] = useState<number | null>(null);

    const [copiedAccessCode, setCopiedAccessCode] = useState<string | null>(null);

    const copyCode = (accessCode: string, codeRef: RefObject<HTMLInputElement>) => {
        codeRef.current?.select();
        document.execCommand('copy');
        setCopiedAccessCode(accessCode);
    };

    const columns: Column<AccessCode>[] = [
        {
            key: 1,
            heading: 'Access Codes',
            getValue: row => row.accessCode,
            searchable: true,
        },
        {
            key: 2,
            heading: 'Start date',
            getValue: row =>
                row.startDate ? dayjs(row.startDate).format('DD-MM-YYYY') : 'No start date',
            getSort: (a, b) =>
                a.startDate && b.startDate
                    ? new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
                    : 0,
            searchable: true,
        },
        {
            key: 3,
            heading: 'End date',
            getValue: row =>
                row.endDate ? dayjs(row.endDate).format('DD-MM-YYYY') : 'No end date',
            getSort: (a, b) =>
                a.endDate && b.endDate
                    ? new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
                    : 0,
            searchable: true,
        },
        {
            key: 4,
            heading: 'Used',
            getValue: row => `${row.numberOfUses ? row.numberOfUses : '0'}`,
            searchable: true,
        },
        {
            key: 5,
            heading: 'Max amount to use',
            getValue: row => `${row.maxUses ? row.maxUses : 'Unlimited'}`,
            searchable: true,
        },
        {
            key: 6,
            heading: '',
            getValue: row => (
                <AccessCodeButtons
                    row={row}
                    copiedAccessCode={copiedAccessCode}
                    copyCode={copyCode}
                    setAccessCodeToUpdate={setAccessCodeToUpdate}
                    setAccessCodeToDelete={setAccessCodeToDelete}
                />
            ),
        },
    ];

    return (
        <>
            <Table isLoading={isFetching} columns={columns} rows={Object.values(accessCodes)} />

            {accessCodeToUpdate && (
                <UpdateAccessCodeModal
                    id={accessCodeToUpdate}
                    closeModal={() => setAccessCodeToUpdate(null)}
                />
            )}
            {accessCodeToDelete && (
                <DeleteAccessCodeModal
                    id={accessCodeToDelete}
                    closeModal={() => setAccessCodeToDelete(null)}
                />
            )}
        </>
    );
};

interface AccessCodeTableProps {
    accessCodes: Record<number, AccessCode>;
}

export default AccessCodeTable;
