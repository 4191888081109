import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'lib/src/hooks/useForm';
import { useParams } from 'react-router-dom';
import useFetchAllDimensionSubcategories from '@pages/dimensionSubcategories/hooks/useFetchAllDimensionSubcategories';
import usePrevious from 'lib/src/hooks/usePrevious';
import useFetchModules from '@pages/modules/hooks/useFetchModules';
import useFetchAllModuleDimensions from '@pages/modules/hooks/useFetchDimensions';

import {
    selectDimensionSubcategories,
    selectDimensionSubcategoriesError,
    selectDimensionSubcategoriesIsFetching,
} from '@selectors/dimensionSubcategories';
import {
    selectDimensionQuestionPostSuccess,
    selectDimensionQuestions,
} from '@selectors/dimensionQuestions';
import { getAllModules } from '@selectors/modules';
import { selectDimensions } from '@selectors/dimensions';

import { setFieldErrors } from 'lib/src/redux/actions/fieldErrors';
import { copyQuestionToDimensionSubcategory } from '@actions/dimensionSubcategories';
import { fetchDimensionQuestions } from '@actions/dimensionQuestions/fetchDimensionQuestions';

import { Module } from '../../../types/shared/Modules';

const useImportDimensionQuestion = (closeModal: () => void) => {
    const { subcategoryID } = useParams<{ subcategoryID: string }>();
    const dispatch = useDispatch();

    const [form, handleChange] = useForm<{
        selectedQuestionID: number;
        selectedSubcategoryID: number;
        selectedModuleID: number;
        selectedDimensionID: number;
    }>({
        selectedQuestionID: 0,
        selectedSubcategoryID: 0,
        selectedModuleID: 0,
        selectedDimensionID: 0,
    });

    useFetchModules();
    useFetchAllModuleDimensions(form.selectedModuleID);
    useFetchAllDimensionSubcategories();

    const isFetching = useSelector(selectDimensionSubcategoriesIsFetching);
    const error = useSelector(selectDimensionSubcategoriesError);

    const modules = useSelector(getAllModules);
    const dimensions = useSelector(selectDimensions);
    const subcategories = useSelector(selectDimensionSubcategories);
    const questions = useSelector(selectDimensionQuestions);

    const postSuccess = useSelector(selectDimensionQuestionPostSuccess);

    const prevPostSuccess = usePrevious(postSuccess);
    const prevSelectedSubcategoryID = usePrevious(form.selectedSubcategoryID);

    useEffect(() => {
        if (form.selectedSubcategoryID !== prevSelectedSubcategoryID) {
            dispatch(fetchDimensionQuestions(form.selectedSubcategoryID));
        }
    }, [dispatch, form.selectedSubcategoryID, prevSelectedSubcategoryID]);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    const moduleOptions = useMemo(() => {
        return Object.values(modules).map((module: Module) => ({
            value: module.id,
            label: module.name,
        }));
    }, [modules]);

    const dimensionOptions = useMemo(() => {
        return Object.values(dimensions).reduce(
            (acc: { value: number; label: string }[], dimension) => {
                if (dimension.moduleID === form.selectedModuleID) {
                    acc.push({ value: dimension.id, label: dimension.name });
                }
                return acc;
            },
            [],
        );
    }, [dimensions, form.selectedModuleID]);

    const subcategoryOptions = useMemo(() => {
        return Object.values(subcategories).reduce(
            (acc: { value: number; label: string }[], subcategory) => {
                const selectedDimension = dimensions[form.selectedDimensionID];
                if (
                    selectedDimension &&
                    subcategory.id !== +subcategoryID &&
                    selectedDimension.id === subcategory.dimensionID
                ) {
                    acc.push({ value: subcategory.id, label: subcategory.name });
                }
                return acc;
            },
            [],
        );
    }, [dimensions, form.selectedDimensionID, subcategoryID, subcategories]);

    const questionOptions = useMemo(() => {
        return Object.values(questions).reduce(
            (acc: { value: number; label: string }[], question) => {
                if (question.subcategoryID === form.selectedSubcategoryID) {
                    acc.push({ value: question.id, label: question.text });
                }
                return acc;
            },
            [],
        );
    }, [form.selectedSubcategoryID, questions]);

    const handleSubmit = () => {
        const addError = (errors: any, newError: any): any => ({ ...errors, ...newError });
        const { selectedQuestionID } = form;
        if (!!selectedQuestionID) {
            dispatch(copyQuestionToDimensionSubcategory(+subcategoryID, selectedQuestionID));
        } else {
            let obj = {};
            if (!form.selectedQuestionID) {
                obj = addError(obj, { selectedModuleID: 'Please select a dimension' });
            }
            if (!form.selectedSubcategoryID) {
                obj = addError(obj, { selectedQuestionID: 'Please select a subcategory' });
            }
            return dispatch(setFieldErrors(obj));
        }
    };

    return {
        form,
        handleChange,
        isFetching,
        error,
        handleSubmit,
        questionOptions,
        subcategoryOptions,
        moduleOptions,
        dimensionOptions,
    };
};

export default useImportDimensionQuestion;
