import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { APIError } from 'lib/src/types/APIError';

export const deleteDimensionAnswerRequest = createAction('deleteDimensionAnswerRequest');
export const deleteDimensionAnswerSuccess = createAction<{ answerID: number; questionID: number }>(
    'deleteDimensionAnswerSuccess',
);
export const deleteDimensionAnswerFailure = createAction('deleteDimensionAnswerFailure');

export const deleteDimensionAnswer =
    (answerID: number, questionID: number) => async (dispatch: AppDispatch) => {
        dispatch(deleteDimensionAnswerRequest());
        try {
            await api.delete(`dimensions/answers/${answerID}`);
            dispatch(deleteDimensionAnswerSuccess({ answerID, questionID }));
        } catch (e) {
            handleApiErrors(dispatch, deleteDimensionAnswerFailure, e as APIError);
        }
    };
