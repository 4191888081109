import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/store';

import { getCompanyQuestion, getIsFetchingAnswers } from '@selectors/companyQuestions';
import CompanyQuestionComponent from './CompanyQuestion';
import UpdateCompanyQuestionModal from '../modals/UpdateCompanyQuestionModal';
import DeleteCompanyQuestionModal from './DeleteCompanyQuestionModal';
import { fetchCompanyQuestion } from '@actions/companyQuestions/fetchCompanyQuestion';

import UpdateAnswerModal from '../answers/UpdateAnswerModal';
import { fetchAllQuestionAnswers } from '@actions/companyQuestions/answers/fetchAllQuestionAnswers';
import DeleteAnswerModal from '../answers/DeleteAnswerModal';
import CreateAnswerModal from '../answers/CreateAnswerModal';
import { CompanyQuestion } from 'src/types/shared/Companies';

const CompanyQuestionContainer = () => {
    const dispatch = useDispatch();

    const { sectionID, questionID, answerID } = useParams<{
        questionID: string;
        answerID: string;
        sectionID: string;
        companyID: string;
    }>();

    const [showUpdateQuestionModal, setShowUpdateQuestionModal] = useState<boolean>(false);
    const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState<boolean>(false);
    const [showUpdateAnswerModal, setShowUpdateAnswerModal] = useState<boolean>(false);
    const [showDeleteAnswerModal, setShowDeleteAnswerModal] = useState<boolean>(false);
    const [showCreateAnswerModal, setShowCreateAnswerModal] = useState<boolean>(false);

    const question = useAppSelector(state => getCompanyQuestion(state, +questionID, +sectionID));
    const isFetchingAnswers = useSelector(getIsFetchingAnswers);

    useEffect(() => {
        dispatch(fetchCompanyQuestion(+questionID));
        dispatch(fetchAllQuestionAnswers(+questionID));
    }, [dispatch, questionID]);

    if (!question) return null;

    return (
        <>
            <CompanyQuestionComponent question={question as CompanyQuestion} />
            {!!question && showUpdateQuestionModal && (
                <UpdateCompanyQuestionModal
                    question={question}
                    closeModal={() => setShowUpdateQuestionModal(true)}
                />
            )}
            {!!question && showDeleteQuestionModal && (
                <DeleteCompanyQuestionModal
                    question={question}
                    closeModal={() => setShowDeleteQuestionModal(true)}
                />
            )}
            {showCreateAnswerModal && (
                <CreateAnswerModal
                    question={question as CompanyQuestion}
                    closeModal={() => setShowCreateAnswerModal(true)}
                />
            )}
            ;
            {!!answerID && !isFetchingAnswers && showUpdateAnswerModal && (
                <UpdateAnswerModal
                    answerID={+answerID}
                    closeModal={() => setShowUpdateAnswerModal(true)}
                />
            )}
            {!!answerID && !isFetchingAnswers && showDeleteAnswerModal && (
                <DeleteAnswerModal
                    answerID={+answerID}
                    question={question as CompanyQuestion}
                    closeModal={() => setShowDeleteAnswerModal(true)}
                />
            )}
        </>
    );
};

export default CompanyQuestionContainer;
