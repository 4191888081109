import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { Module } from 'src/types/shared/Modules';
import { APIError } from 'lib/src/types/APIError';

export const updateModuleRequest = createAction('updateModuleRequest');
export const updateModuleSuccess = createAction<Module>('updateModuleSuccess');
export const updateModuleFailure = createAction('updateModuleFailure');

export const updateModule = (id: number, postBody: PostBody) => async (dispatch: AppDispatch) => {
    dispatch(updateModuleRequest());

    try {
        const { data }: UpdateModuleResponse = await api.patch(`modules/${id}`, postBody);

        dispatch(updateModuleSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, updateModuleFailure, e as APIError);
    }
};

interface PostBody {
    name: string;
    startDate: Date | null;
    endDate: Date | null;
    isActive: boolean;
    isScoring: boolean;
}

interface UpdateModuleResponse {
    data: Module;
}
