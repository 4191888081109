import useDimensionQuestion from '@pages/dimensionQuestions/hooks/useDimensionQuestion';

import Title from 'lib/src/components/typography/Title';
import Loading from '@components/layout/loading/Loading';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import ContentItem from '@components/layout/contentBlock/ContentItem';

import { convertPascalCaseToSentanceCase } from 'lib/src/utils/generic';

import { DimensionQuestionTypes } from '../../../types/shared/Companies';

import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import DimensionQuestionAnswers from '../answers/DimensionQuestionAnswers';
import UpdateDimensionQuestionModal from '@pages/dimensionQuestions/modals/UpdateDimensionQuestionModal';
import DeleteDimensionQuestionModal from '@pages/dimensionQuestions/modals/DeleteDimensionQuestionModal';
import dayjs from 'dayjs';

const DimensionQuestion = () => {
    const {
        isFetching,
        question,
        showUpdateModal,
        setShowUpdateModal,
        showDeleteModal,
        setShowDeleteModal,
    } = useDimensionQuestion();

    if (!question || isFetching) return <Loading />;

    const { text, answerType, textInputScore } = question;

    return (
        <>
            <Title>{`Custom question - ${text}`}</Title>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Question text">
                        <p>{text}</p>
                    </ContentItem>
                    <ContentItem label="Answer type">
                        <p>{convertPascalCaseToSentanceCase(DimensionQuestionTypes[answerType])}</p>
                    </ContentItem>
                    {answerType === DimensionQuestionTypes.TextInput && (
                        <ContentItem label="Score">
                            <p>{textInputScore}</p>
                        </ContentItem>
                    )}
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Start Date">
                        <p>
                            {question.startDate
                                ? dayjs(question.startDate).format('hh:mm - DD/MM/YYYY')
                                : 'No start date'}
                        </p>
                    </ContentItem>
                    <ContentItem label="End Date">
                        <p>
                            {question.endDate
                                ? dayjs(question.endDate).format('hh:mm - DD/MM/YYYY')
                                : 'No end date'}
                        </p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>

            <ButtonRow alignment="left">
                <ActionButton color="grey" icon="pencil" onClick={() => setShowUpdateModal(true)}>
                    Edit
                </ActionButton>
                <ActionButton color="red" icon="trash-alt" onClick={() => setShowDeleteModal(true)}>
                    Delete
                </ActionButton>
            </ButtonRow>

            {answerType !== DimensionQuestionTypes.TextInput && (
                <DimensionQuestionAnswers question={question} />
            )}

            {showUpdateModal && (
                <UpdateDimensionQuestionModal
                    question={question}
                    closeModal={() => setShowUpdateModal(false)}
                />
            )}

            {showDeleteModal && (
                <DeleteDimensionQuestionModal
                    question={question}
                    closeModal={() => setShowDeleteModal(false)}
                />
            )}
        </>
    );
};

export default DimensionQuestion;
