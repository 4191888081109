import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { Company } from 'src/types/shared/Companies';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const fetchCompanyRequest = createAction('fetchCompanyRequest');
export const fetchCompanySuccess = createAction<Company>('fetchCompanySuccess');
export const fetchCompanyFailure = createAction('fetchCompanyFailure');

export const fetchCompany = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchCompanyRequest());

    try {
        const { data }: Response = await api.get(`company/${id}`);

        dispatch(fetchCompanySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchCompanyFailure, e as APIError);
    }
};

interface Response {
    data: Company;
}
