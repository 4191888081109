import { fetchAllCompanies } from '@actions/companies';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useFetchCompanies = (noModule?: boolean): void => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllCompanies(noModule));
    }, [noModule, dispatch]);
};

export default useFetchCompanies;
