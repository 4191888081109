import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { Dimension, DimensionSubcategory } from 'src/types/shared/Dimensions';

export const patchSortDimensionSubcategoriesRequest = createAction(
    'patchSortDimensionSubcategoriesRequest',
);
export const patchSortDimensionSubcategoriesSuccess = createAction<Dimension>(
    'patchSortDimensionSubcategoriesSuccess',
);
export const patchSortDimensionSubcategoriesFailure = createAction(
    'patchSortDimensionSubcategoriesFailure',
);

export const patchSortDimensionSubcategories =
    (dimensionID: number, dimensions: DimensionSubcategory[]) => async (dispatch: AppDispatch) => {
        dispatch(patchSortDimensionSubcategoriesRequest());

        const sorts: Sort[] = dimensions
            .sort((a, b) => a.sort - b.sort)
            .map((a, i) => ({ id: a.id, sort: i + 1 }));
        const patchBody: PatchBody = { sorts };

        try {
            const { data } = await api.patch<PatchBody, Dimension>(
                `dimensions/${dimensionID}/subcategories/sort`,
                patchBody,
            );
            dispatch(patchSortDimensionSubcategoriesSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, patchSortDimensionSubcategoriesFailure, e as APIError);
        }
    };

interface Sort {
    id: number;
    sort: number;
}
interface PatchBody {
    sorts: Sort[];
}
