import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logout } from '@actions/auth';

import NavGroup from './NavGroup';
import NavItem from './NavItem';
import { clearJtwtToken } from 'lib/src/utils/jwt';

const Nav: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const path = location.pathname.toLowerCase();
    if (path.startsWith('/auth')) return null;

    return (
        <nav className="navigation custom-scroll">
            <ul className="nav-list">
                <NavGroup text="User management">
                    <NavItem to="/admin-users" text="Admins" icon="users-cog" />
                </NavGroup>

                <NavGroup text="Company management">
                    <NavItem to="/companies" text="Companies" icon="building" />
                </NavGroup>
                <NavGroup text="Module management">
                    <NavItem to="/modules" text="Modules" icon="building" />
                </NavGroup>

                <NavGroup text="Settings">
                    <NavItem
                        to="/auth/login"
                        text="Logout"
                        icon="sign-out"
                        onClick={() => {
                            clearJtwtToken();
                            dispatch(logout());
                        }}
                    />
                </NavGroup>
            </ul>
        </nav>
    );
};

export default Nav;
