import { useState } from 'react';

import Title from 'lib/src/components/typography/Title';
import ActionButton from 'lib/src/components/button/ActionButton';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import CreateModuleModal from '@pages/modules/modals/CreateModuleModal';
import ModulesTable from './ModulesTable';
import useFetchModules from '@pages/modules/hooks/useFetchModules';
import { useSelector } from 'react-redux';
import { getAllModules } from '@selectors/modules';

const Modules = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);

    useFetchModules();
    const modules = useSelector(getAllModules);

    return (
        <>
            <CreateHeader>
                <Title>Modules</Title>
                <ActionButton color="green" icon="plus" onClick={() => setShowCreateModal(true)}>
                    Create
                </ActionButton>
            </CreateHeader>

            <ModulesTable modules={modules} />

            {showCreateModal && <CreateModuleModal closeModal={() => setShowCreateModal(false)} />}
        </>
    );
};

export default Modules;
