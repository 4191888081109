import useUpdateCompanyQuestion from '../hooks/useUpdateCompanyQuestion';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';

import TextArea from 'lib/src/components/form/TextArea';
import FormRow from 'lib/src/components/form/FormRow';
import { CompanyQuestion, QuestionTypes } from '../../../types/shared/Companies';
import Select from 'lib/src/components/form/Select';
import NumberInput from 'lib/src/components/form/NumberInput';
import Title from 'lib/src/components/typography/Title';
import ToggleSwitch from 'lib/src/components/form/ToggleSwitch';

const UpdateCompanyQuestionModal = ({ question, closeModal }: UpdateCompanyQuestionModalProps) => {
    const {
        form: { questionText, isRequired, type, sliderOptions },
        handleChange,
        handleSubmit,
        isPosting,
        error,
        questionTypeOptions,
    } = useUpdateCompanyQuestion(question, closeModal);

    return (
        <Modal title="Update company question" closeModal={closeModal}>
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting} error={error}>
                <FormRow>
                    <TextArea
                        name="questionText"
                        onChange={handleChange}
                        value={questionText}
                        label="Question text"
                        required
                    />
                </FormRow>
                <FormRow>
                    <Select
                        options={questionTypeOptions}
                        name="type"
                        value={type}
                        onChange={handleChange}
                        label="Question type"
                    />
                </FormRow>
                <FormRow>
                    <ToggleSwitch
                        name="isRequired"
                        onChange={handleChange}
                        value={isRequired}
                        label="Is required"
                    />
                </FormRow>
                {type === QuestionTypes.Slider && (
                    <>
                        <Title>Slider Options</Title>
                        <FormRow>
                            <NumberInput
                                name="minValue"
                                value={sliderOptions.minValue}
                                onChange={(_, value) => {
                                    handleChange('sliderOptions', {
                                        ...sliderOptions,
                                        minValue: value,
                                    });
                                }}
                                label="Min value"
                                maxNumber={sliderOptions.maxValue}
                            />
                        </FormRow>
                        <FormRow>
                            <NumberInput
                                name="maxValue"
                                value={sliderOptions.maxValue}
                                onChange={(_, value) => {
                                    handleChange('sliderOptions', {
                                        ...sliderOptions,
                                        maxValue: value,
                                    });
                                }}
                                label="Max value"
                                minNumber={sliderOptions.minValue}
                            />
                        </FormRow>
                        <FormRow>
                            <NumberInput
                                name="increment"
                                value={sliderOptions.increment}
                                onChange={(_, value) => {
                                    handleChange('sliderOptions', {
                                        ...sliderOptions,
                                        increment: value,
                                    });
                                }}
                                label="Increment"
                            />
                        </FormRow>
                    </>
                )}
            </Form>
        </Modal>
    );
};

interface UpdateCompanyQuestionModalProps {
    question: CompanyQuestion;
    closeModal: () => void;
}

export default UpdateCompanyQuestionModal;
