import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import usePrevious from 'lib/src/hooks/usePrevious';

import {
    selectDimensionSubcategoriesError,
    selectDimensionSubcategoriesIsFetching,
    selectDimensionSubcategory,
} from '@selectors/dimensionSubcategories';
import { RootState } from '@reducers/index';
import { fetchDimensionSubcategory } from '@actions/dimensionSubcategories/fetchDimensionSubcategory';

const useSingleDimensionSubcategory = () => {
    const { subcategoryID } = useParams<{ dimensionID: string; subcategoryID: string }>();
    const dispatch = useDispatch();
    const history = useHistory();

    const isFetching = useSelector(selectDimensionSubcategoriesIsFetching);
    const error = useSelector(selectDimensionSubcategoriesError);
    const subcategory = useSelector((state: RootState) =>
        selectDimensionSubcategory(state, +subcategoryID),
    );
    const prevSubcategory = usePrevious(subcategory);

    useEffect(() => {
        dispatch(fetchDimensionSubcategory(+subcategoryID));
    }, [dispatch, subcategoryID]);

    useEffect(() => {
        if (!subcategory && prevSubcategory) {
            history.replace(`/dimensions/${prevSubcategory.dimensionID}`);
        }
    }, [subcategory, prevSubcategory, history]);

    return { subcategory, isFetching, error };
};

export default useSingleDimensionSubcategory;
