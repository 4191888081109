import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from 'src/redux/store';
import { Dimension } from '../../../types/shared/Dimensions';

import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const fetchAllModuleDimensionsRequest = createAction('fetchAllModuleDimensions');
export const fetchAllModuleDimensionsSuccess = createAction<Dimension[]>(
    'fetchAllModuleDimensionsSuccess',
);
export const fetchAllModuleDimensionsFailure = createAction('fetchAllModuleDimensionsFailure');

export const fetchAllModuleDimensions =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchAllModuleDimensionsRequest());

        try {
            const { data }: Response = await api.get(`modules/${id}/dimensions`);

            dispatch(fetchAllModuleDimensionsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchAllModuleDimensionsFailure, e as APIError);
        }
    };

interface Response {
    data: Dimension[];
}
