import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const deleteAccessCodeRequest = createAction('deleteAccessCodeRequest');
export const deleteAccessCodeSuccess = createAction<number>('deleteAccessCodeSuccess');
export const deleteAccessCodeFailure = createAction('deleteAccessCodeFailure');

export const deleteAccessCode =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteAccessCodeRequest());

        try {
            await api.delete(`access-codes/${id}`);

            dispatch(deleteAccessCodeSuccess(id));
        } catch (e) {
            handleApiErrors(dispatch, deleteAccessCodeFailure, e as APIError);
        }
    };
