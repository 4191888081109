import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { QuestionAnswer } from 'src/types/shared/Companies';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const postSortQuestionAnswersRequest = createAction('postSortQuestionAnswersRequest');
export const postSortQuestionAnswersSuccess = createAction<QuestionAnswer[]>(
    'postSortQuestionAnswersSuccess',
);
export const postSortQuestionAnswersFailure = createAction('postSortQuestionAnswersFailure');

export const postSortQuestionAnswers =
    (questionID: number, answers: QuestionAnswer[]) => async (dispatch: AppDispatch) => {
        dispatch(postSortQuestionAnswersRequest());
        const sorts = answers
            .sort((a, b) => a.sort - b.sort)
            .map<Sort>((ans, i) => ({ id: ans.id, sort: i }));
        const postBody = { sorts };
        try {
            const { data }: UpdateResponse = await api.patch(
                `onboarding/questions/${questionID}/answers/sort`,
                postBody,
            );

            dispatch(postSortQuestionAnswersSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postSortQuestionAnswersFailure, e as APIError);
        }
    };

interface Sort {
    id: number;
    sort: number;
}

interface UpdateResponse {
    data: QuestionAnswer[];
}
