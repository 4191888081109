import useImportDimensionQuestion from '@pages/dimensionQuestions/hooks/useImportDimensionQuestion';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import Select from 'lib/src/components/form/Select';
import Loading from '@components/layout/loading/Loading';
import FormField from 'lib/src/components/form/FormField';

const ImportDimensionQuestionModal = ({ closeModal }: Props) => {
    const {
        isFetching,
        error,
        handleSubmit,
        handleChange,
        subcategoryOptions,
        questionOptions,
        form: { selectedSubcategoryID, selectedQuestionID, selectedModuleID, selectedDimensionID },
        moduleOptions,
        dimensionOptions,
    } = useImportDimensionQuestion(closeModal);

    return (
        <Modal closeModal={closeModal} title="Import Question" extraClasses="show-overflow">
            {isFetching ? (
                <Loading />
            ) : (
                <Form onSubmit={handleSubmit} onCancel={closeModal} error={error}>
                    <FormField name="selectedModuleID" label="Module">
                        <Select
                            search
                            options={moduleOptions}
                            name="selectedModuleID"
                            value={selectedModuleID}
                            onChange={(name, val) => handleChange(name, val)}
                        />
                    </FormField>
                    <FormField name="selectedDimensionID" label="Dimension">
                        <Select
                            search
                            options={dimensionOptions}
                            name="selectedDimensionID"
                            disabled={!selectedModuleID}
                            value={selectedDimensionID}
                            onChange={(name, val) => handleChange(name, val)}
                        />
                    </FormField>
                    <FormField name="selectedSubcategoryID" label="Subcategory">
                        <Select
                            search
                            options={subcategoryOptions}
                            name="selectedSubcategoryID"
                            value={selectedSubcategoryID}
                            disabled={!selectedDimensionID}
                            onChange={handleChange}
                        />
                    </FormField>
                    <FormField name="selectedDimensionID" label="Question">
                        <Select
                            search
                            options={questionOptions}
                            name="selectedQuestionID"
                            disabled={!selectedSubcategoryID}
                            value={selectedQuestionID}
                            onChange={handleChange}
                        />
                    </FormField>
                </Form>
            )}
        </Modal>
    );
};

interface Props {
    closeModal: () => void;
    id: number;
}

export default ImportDimensionQuestionModal;
