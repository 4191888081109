import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { Module } from 'src/types/shared/Modules';
import { APIError } from 'lib/src/types/APIError';

export const patchSortAccessCodeModulesRequest = createAction('patchSortAccessCodeModulesRequest');
export const patchSortAccessCodeModulesSuccess = createAction<Module>(
    'patchSortAccessCodeModulesSuccess',
);
export const patchSortAccessCodeModulesFailure = createAction('patchSortAccessCodeModulesFailure');

export const patchSortAccessCodeModules =
    (id: number, modules: Module[]) => async (dispatch: AppDispatch) => {
        dispatch(patchSortAccessCodeModulesRequest());
        const sorts = modules
            .sort((a, b) => a.sort - b.sort)
            .map((a, i) => ({ id: a.id, sort: i + 1 }));
        const postBody: PostBody = { sorts };

        try {
            const { data }: UpdateModuleResponse = await api.patch(
                `access-codes/${id}/modules/sort`,
                postBody,
            );

            dispatch(patchSortAccessCodeModulesSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, patchSortAccessCodeModulesFailure, e as APIError);
        }
    };

interface PostBody {
    sorts: { id: number; sort: number }[];
}

interface UpdateModuleResponse {
    data: Module;
}
