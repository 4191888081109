import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import useCreateCompanySection from '../hooks/useCreateCompanySection';
import TextArea from 'lib/src/components/form/TextArea';
import FilePicker from 'lib/src/components/filePicker/FilePicker';
import config from '../../../config';
const { API_URL, S3_URL } = config;

const CreateCompanySectionModal = ({ id, sortValue, closeModal }: CreateModalProps) => {
    const {
        form: { name, description, imageS3Key },
        handleChange,
        handleSubmit,
        isPosting,
        error,
    } = useCreateCompanySection(id, sortValue, closeModal);

    return (
        <Modal closeModal={closeModal} title="Create section">
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal} error={error}>
                <FormRow>
                    <TextArea
                        name="name"
                        value={name}
                        onChange={handleChange}
                        label="Section name"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextArea
                        name="description"
                        value={description}
                        onChange={handleChange}
                        label="Description"
                    />
                </FormRow>
                <FormRow>
                    <FilePicker
                        label="Image"
                        name="imageS3Key"
                        value={imageS3Key}
                        onChange={handleChange}
                        apiURL={API_URL}
                        storageURL={S3_URL}
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface CreateModalProps {
    id: number;
    sortValue: number;
    closeModal: () => void;
}
export default CreateCompanySectionModal;
