import useCreateCompany from './hooks/useCreateCompany';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import DatePicker from 'lib/src/components/form/DatePicker';
import ToggleSwitch from 'lib/src/components/form/ToggleSwitch';
import FilePicker from 'lib/src/components/filePicker/FilePicker';
import { validateAccessCode } from 'lib/src/utils/validation';
import { API_URL } from 'lib/src/utils/api';
import config from '../../config/index';
import Title from 'lib/src/components/typography/Title';
import WYSIWYG from 'lib/src/components/form/WYSIWYG';
import NumberInput from 'lib/src/components/form/NumberInput';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';

const { S3_URL } = config;

const CreateCompanyModal = () => {
    const {
        form: {
            companyReference,
            startDate,
            endDate,
            couldImproveMessage,
            isEnabled,
            imageS3Key,
            supportServiceLogoS3Key,
            supportLink,
            hideDimensionWheel,
            languageCode,
        },
        handleChange,
        closeModal,
        handleSubmit,
        isPosting,
        error,
        accessCodesObject,
        addAccessCode,
        updateAccessCode,
        removeAccessCode,
    } = useCreateCompany();

    return (
        <Modal closeModal={closeModal} title="Create company" size="medium">
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal} error={error}>
                <FormRow>
                    <TextInput
                        name="companyReference"
                        value={companyReference}
                        onChange={handleChange}
                        label="Company reference"
                        required
                    />
                </FormRow>
                <>
                    <Title>Access Codes</Title>

                    {Object.values(accessCodesObject).map((accessCode, index) => (
                        <>
                            <FormRow key={index}>
                                <TextInput
                                    name="accessCode"
                                    value={accessCode.accessCode}
                                    onChange={(name, value) =>
                                        updateAccessCode(accessCode.guid, name, value)
                                    }
                                    label="Access Code"
                                    customValidate={validateAccessCode}
                                />
                                <NumberInput
                                    name="maxUses"
                                    value={accessCode.maxUses}
                                    onChange={(name, value) =>
                                        updateAccessCode(accessCode.guid, name, +value)
                                    }
                                    label="Max uses - 0 for unlimited"
                                    minNumber={0}
                                />
                                <button
                                    className="icon-button delete"
                                    onClick={() => removeAccessCode(accessCode.guid)}
                                    type="button"
                                >
                                    <i className="fal fa-trash-alt"></i>
                                </button>
                            </FormRow>
                            <FormRow>
                                <DatePicker
                                    name="startDate"
                                    value={accessCode.startDate}
                                    onChange={(name, value) =>
                                        updateAccessCode(accessCode.guid, name, value)
                                    }
                                    label="Start date"
                                />
                            </FormRow>
                            <FormRow>
                                <DatePicker
                                    name="endDate"
                                    value={accessCode.endDate}
                                    onChange={(name, value) =>
                                        updateAccessCode(accessCode.guid, name, value)
                                    }
                                    label="End date"
                                />
                            </FormRow>
                        </>
                    ))}

                    <ButtonRow alignment="left">
                        <ActionButton
                            type="button"
                            onClick={() => addAccessCode()}
                            color="green"
                            icon="plus"
                        >
                            Add access code
                        </ActionButton>
                    </ButtonRow>
                </>
                <br />

                <FormRow>
                    <DatePicker
                        name="startDate"
                        value={startDate}
                        onChange={handleChange}
                        label="Start date"
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        name="endDate"
                        value={endDate}
                        onChange={handleChange}
                        label="End date"
                    />
                </FormRow>
                <FormRow>
                    <ToggleSwitch
                        name="isEnabled"
                        value={isEnabled}
                        onChange={handleChange}
                        label="Is company active?"
                    />
                </FormRow>
                <br />
                <FormRow>
                    <ToggleSwitch
                        name="hideDimensionWheel"
                        value={hideDimensionWheel}
                        onChange={handleChange}
                        label="Is dimension wheel hidden?"
                    />
                </FormRow>
                <br />
                <FormRow>
                    <FilePicker
                        name="imageS3Key"
                        value={imageS3Key}
                        onChange={handleChange}
                        label="Company logo"
                        apiURL={API_URL}
                        storageURL={S3_URL}
                        maxFiles={1}
                    />
                </FormRow>
                <Title>Support message</Title>
                <FormRow>
                    <FilePicker
                        name="supportServiceLogoS3Key"
                        value={supportServiceLogoS3Key}
                        onChange={handleChange}
                        label="Support service logo"
                        apiURL={API_URL}
                        storageURL={S3_URL}
                        maxFiles={1}
                        className="small-image"
                    />
                </FormRow>
                <FormRow>
                    <WYSIWYG
                        name="couldImproveMessage"
                        value={couldImproveMessage}
                        onChange={handleChange}
                        label="Could improve message"
                        limitInputToMax
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="supportLink"
                        value={supportLink}
                        onChange={handleChange}
                        label="Support link"
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="languageCode"
                        value={languageCode}
                        onChange={handleChange}
                        label="Language code"
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

export default CreateCompanyModal;
