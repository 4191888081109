import { Redirect, Switch } from 'react-router-dom';

import AdminUsersRoutes from './AdminUsersRoutes';
import AuthRoutes from './AuthRoutes';
import SubRouter from './SubRouter';
import CompaniesRoutes from './CompaniesRoutes';
import ModulesRoutes from './ModulesRoutes';
import DimensionsRoutes from '@routes/DimensionsRoutes';

const Routes: React.FC = () => (
    <Switch>
        <SubRouter path="/auth">
            <AuthRoutes />
        </SubRouter>
        <SubRouter path="/admin-users" auth>
            <AdminUsersRoutes />
        </SubRouter>
        <SubRouter path="/companies" auth>
            <CompaniesRoutes />
        </SubRouter>
        <SubRouter path="/modules" auth>
            <ModulesRoutes />
        </SubRouter>
        <SubRouter path="/dimensions" auth>
            <DimensionsRoutes />
        </SubRouter>
        <Redirect from="/" to="/companies" />
    </Switch>
);

export default Routes;
