import Modal from 'lib/src/components/modal/Modal';

import useCreateDimensionAnswer from '../hooks/useCreateDimensionAnswer';

import TextInput from 'lib/src/components/form/TextInput';
import FormRow from 'lib/src/components/form/FormRow';
import Form from 'lib/src/components/form/Form';

const CreateDimensionAnswerModal = ({ questionID, closeModal }: Props) => {
    const {
        form: { text, score },
        handleChange,
        handleSubmit,
    } = useCreateDimensionAnswer(questionID, closeModal);

    return (
        <Modal closeModal={closeModal} title="Create answer">
            <Form onSubmit={handleSubmit} onCancel={closeModal}>
                <FormRow>
                    <TextInput
                        name="text"
                        value={text}
                        onChange={handleChange}
                        label="Answer text"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="score"
                        value={score.toString()}
                        onChange={handleChange}
                        label="Score"
                        required
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface Props {
    questionID: number;
    closeModal: () => void;
}

export default CreateDimensionAnswerModal;
