import useCreateAnswer from './hooks/useCreateAnswer';

import { CompanyQuestion } from 'src/types/shared/Companies';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import Modal from 'lib/src/components/modal/Modal';

const CreateAnswerModal = ({ question, closeModal }: CreateAnswerModalProps) => {
    const {
        form: { answerText },
        handleChange,
        handleSubmit,
        isPosting,
        error,
    } = useCreateAnswer(question, closeModal);

    return (
        <Modal title="Create answer" closeModal={closeModal}>
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal} error={error}>
                <FormRow>
                    <TextInput
                        name="answerText"
                        value={answerText}
                        onChange={handleChange}
                        label="Answer text"
                        required
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface CreateAnswerModalProps {
    question: CompanyQuestion;
    closeModal: () => void;
}

export default CreateAnswerModal;
