import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';

import { APIError } from 'lib/src/types/APIError';
import { DimensionSubcategory } from '../../../types/shared/Dimensions';

export const fetchDimensionSubcategoryRequest = createAction('fetchDimensionSubcategoryRequest');
export const fetchDimensionSubcategorySuccess = createAction<DimensionSubcategory>(
    'fetchDimensionSubcategorySuccess',
);
export const fetchDimensionSubcategoryFailure = createAction('fetchDimensionSubcategoryFailure');

export const fetchDimensionSubcategory = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchDimensionSubcategoryRequest());
    try {
        const { data }: Response = await api.get(`dimensions/subcategories/${id}`);
        dispatch(fetchDimensionSubcategorySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchDimensionSubcategoryFailure, e as APIError);
    }
};

interface Response {
    data: DimensionSubcategory;
}
