import React, { RefObject, useRef } from 'react';

import config from '../../../config';

import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import LinkButton from 'lib/src/components/button/LinkButton';

import { AccessCode } from '../../../types/shared/AccessCode';

const { WEB_URL } = config;

const AccessCodeButtons = ({
    row,
    copiedAccessCode,
    copyCode,
    setAccessCodeToUpdate,
    setAccessCodeToDelete,
}: IProps) => {
    const accessURL = new URL(`q/${row.accessCode}`, WEB_URL);

    const isCopied = copiedAccessCode === row.accessCode;
    const codeRef = useRef<HTMLInputElement>(null);

    return (
        <ButtonRow alignment="right">
            <ActionButton onClick={() => copyCode(row.accessCode, codeRef)} color="green">
                <CopyLink text={accessURL.href} codeRef={codeRef} />
                {isCopied ? 'Copied!' : 'Copy access code link'}
            </ActionButton>
            <LinkButton href={`/companies/${row.companyID}/access-codes/${row.id}`}>
                View
            </LinkButton>
            <ActionButton onClick={() => setAccessCodeToUpdate(row.id)} color="grey">
                Edit
            </ActionButton>

            <ActionButton onClick={() => setAccessCodeToDelete(row.id)} color="red">
                Delete
            </ActionButton>
        </ButtonRow>
    );
};

interface ICopyLink {
    text: string;
    codeRef: React.RefObject<HTMLInputElement>;
}
const CopyLink = ({ text, codeRef }: ICopyLink) => (
    <input style={{ position: 'absolute', top: '-100000px' }} ref={codeRef} readOnly value={text} />
);

interface IProps {
    row: AccessCode;
    copiedAccessCode: string | null;
    copyCode: (accessCode: string, codeRef: RefObject<HTMLInputElement>) => void;
    setAccessCodeToUpdate: (id: number) => void;
    setAccessCodeToDelete: (id: number) => void;
}

export default AccessCodeButtons;
