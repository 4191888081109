import useUpdateDimensionAnswer from '../hooks/useUpdateDimensionAnswer';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import { DimensionAnswer } from '../../../../types/shared/Dimensions';

const UpdateDimensionAnswerModal = ({ answer, closeModal }: UpdateModalProps) => {
    const {
        form: { text, score },
        handleChange,
        handleSubmit,
    } = useUpdateDimensionAnswer(answer, closeModal);
    return (
        <Modal closeModal={closeModal} title="Update answer">
            <Form onSubmit={handleSubmit} onCancel={closeModal}>
                <FormRow>
                    <TextInput
                        name="text"
                        value={text}
                        onChange={handleChange}
                        label="Answer text"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="score"
                        value={score.toString()}
                        onChange={handleChange}
                        label="Score"
                        required
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface UpdateModalProps {
    answer: DimensionAnswer;
    closeModal: () => void;
}

export default UpdateDimensionAnswerModal;
