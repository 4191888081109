import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectDimensionQuestions,
    selectDimensionQuestionsIsFetching,
} from '@selectors/dimensionQuestions';
import { fetchDimensionQuestions } from '@actions/dimensionQuestions/fetchDimensionQuestions';
import { DimensionQuestion } from 'src/types/shared/Dimensions';
import { moveItemInArray } from 'lib/src/utils/generic';
import { Column } from 'lib/src/types/table';

import Table from 'lib/src/components/table/Table';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import LinkButton from 'lib/src/components/button/LinkButton';
import { patchSortDimensionQuestions } from '@actions/dimensionQuestions/patchSortDimensionQuestions';
import { DimensionQuestionTypes } from '../../types/shared/Companies';

const DimensionQuestionsTable = ({ subcategoryID }: Props) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(selectDimensionQuestionsIsFetching);
    const dimensionQuestions: Record<number, DimensionQuestion> =
        useSelector(selectDimensionQuestions);

    const questions = Object.values(dimensionQuestions ?? {}).filter(
        question => question.subcategoryID === +subcategoryID,
    );

    useEffect(() => {
        dispatch(fetchDimensionQuestions(subcategoryID));
    }, [dispatch, subcategoryID]);

    const handleSort = (questionID: number, direction: 'up' | 'down') => {
        const currentSorted = questions.sort((a, b) => a.sort - b.sort);
        const sortAdjustment = direction === 'up' ? -1 : 1;

        const indexToMove = currentSorted.reduce((acc: number | null, curr: DimensionQuestion) => {
            if (acc !== null) return acc;
            if (curr.id === questionID) return currentSorted.indexOf(curr);
            return acc;
        }, null);

        if (indexToMove === null) return;

        const newQuestions: DimensionQuestion[] = moveItemInArray(
            currentSorted,
            indexToMove,
            indexToMove + sortAdjustment,
        ).map((s, i) => ({ ...s, sort: i }));
        dispatch(patchSortDimensionQuestions(subcategoryID, newQuestions));
    };

    const columns: Column<DimensionQuestion>[] = [
        {
            key: 1,
            heading: '',
            getValue: row => (
                <ButtonRow alignment="left">
                    {row.sort > 1 && (
                        <ActionButton
                            className="mini-button"
                            type="button"
                            onClick={() => handleSort(row.id, 'up')}
                        >
                            ↑
                        </ActionButton>
                    )}
                    {row.sort < questions.length && (
                        <ActionButton
                            className="mini-button"
                            type="button"
                            onClick={() => handleSort(row.id, 'down')}
                        >
                            ↓
                        </ActionButton>
                    )}
                </ButtonRow>
            ),
        },
        {
            key: 2,
            heading: 'Question text',
            getValue: row => row.text,
        },
        {
            key: 3,
            heading: 'Answer count',
            getValue: row =>
                row.answerType !== DimensionQuestionTypes.TextInput && !!row.answerCount
                    ? `${row.answerCount}`
                    : 'N/A',
        },
        {
            key: 4,
            heading: '',
            getValue: row => (
                <ButtonRow alignment="right">
                    <LinkButton
                        href={`/dimensions/${row.dimensionID}/subcategories/${row.subcategoryID}/questions/${row.id}`}
                    >
                        View
                    </LinkButton>
                </ButtonRow>
            ),
        },
    ];

    const sortedQuestions = useMemo(() => {
        if (!questions) return [];
        return Object.values(questions).sort((a, b) => a.sort - b.sort);
    }, [questions]);
    return <Table isLoading={isFetching} columns={columns} rows={sortedQuestions} />;
};

interface Props {
    subcategoryID: number;
}
export default DimensionQuestionsTable;
