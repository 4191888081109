import { useEffect } from 'react';
import useForm from 'lib/src/hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';

import { createDimensionQuestionAnswer } from '@actions/dimensionQuestions/answers/createDimensionQuestionAnswer';
import { selectDimensionAnswersPostSuccess } from '@selectors/dimensionQuestions';

const useCreateDimensionAnswer = (questionID: number, closeModal: () => void) => {
    const dispatch = useDispatch();
    const [form, handleChange] = useForm<{ text: string; score: number }>({ text: '', score: 0 });

    const postSuccess = useSelector(selectDimensionAnswersPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [closeModal, postSuccess, prevPostSuccess]);

    const handleSubmit = () => {
        const postBody = { ...form, score: +form.score };
        dispatch(createDimensionQuestionAnswer(questionID, postBody));
    };

    return { form, handleChange, handleSubmit };
};

export default useCreateDimensionAnswer;
