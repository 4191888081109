import React from 'react';

import useCreateModule from '@pages/modules/hooks/useCreateModule';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import DatePicker from 'lib/src/components/form/DatePicker';
import ToggleSwitch from 'lib/src/components/form/ToggleSwitch';

const CreateModuleModal = ({ closeModal }: Props) => {
    const {
        form: { name, startDate, endDate, isActive, isScoring },
        handleChange,
        handleSubmit,
        modules,
    } = useCreateModule(closeModal);

    return (
        <Modal closeModal={closeModal} title="Create Module" extraClasses="show-overflow">
            <Form onSubmit={handleSubmit} onCancel={closeModal}>
                <FormRow>
                    <TextInput
                        label="Name"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        placeholder="Name"
                        customValidate={() => {
                            if (modules.some(x => x.name === name)) {
                                return 'Module name already exists';
                            }
                        }}
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        label="Start Date"
                        name="startDate"
                        value={startDate}
                        onChange={handleChange}
                        placeholder="Start Date"
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        label="End Date"
                        name="endDate"
                        value={endDate}
                        onChange={handleChange}
                        placeholder="End Date"
                    />
                </FormRow>
                <FormRow>
                    <ToggleSwitch
                        label="Is Active?"
                        name="isActive"
                        value={isActive}
                        onChange={handleChange}
                    />
                </FormRow>
                <FormRow>
                    <ToggleSwitch
                        label="Is External Scoring?"
                        name="isScoring"
                        value={isScoring}
                        onChange={handleChange}
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface Props {
    closeModal: () => void;
}

export default CreateModuleModal;
