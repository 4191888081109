import React from 'react';
import useCreateDimensionSubcategory from '@pages/dimensionSubcategories/hooks/useCreateDimensionSubcategory';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import TextArea from 'lib/src/components/form/TextArea';
import NumberInput from 'lib/src/components/form/NumberInput';

const CreateDimensionSubcategoryModal = ({ id, closeModal }: Props) => {
    const {
        form: {
            name,
            title,
            strengthDescription,
            difficultyDescription,
            mediumScoreBoundary,
            highScoreBoundary,
        },
        handleChange,
        handleSubmit,
        isScoring,
        isPosting,
        error,
    } = useCreateDimensionSubcategory(id, closeModal);
    return (
        <Modal closeModal={closeModal} title="Create Subcategory" extraClasses="show-overflow">
            <Form onSubmit={handleSubmit} onCancel={closeModal} isPosting={isPosting} error={error}>
                <FormRow>
                    <TextInput
                        label="Name"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        placeholder="Name"
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        label="Title"
                        name="title"
                        value={title}
                        onChange={handleChange}
                        placeholder="Title"
                    />
                </FormRow>
                {isScoring && (
                    <>
                        <FormRow>
                            <TextArea
                                label="Strength Description"
                                name="strengthDescription"
                                value={strengthDescription || ''}
                                onChange={handleChange}
                                placeholder="Strength Description"
                            />
                        </FormRow>
                        <FormRow>
                            <TextArea
                                label="Difficulty Description"
                                name="difficultyDescription"
                                value={difficultyDescription || ''}
                                onChange={handleChange}
                                placeholder="Difficulty Description"
                            />
                        </FormRow>
                        <FormRow>
                            <NumberInput
                                label="Low/Medium Score Boundary"
                                name="mediumScoreBoundary"
                                value={mediumScoreBoundary || 0}
                                onChange={handleChange}
                                placeholder="Medium Boundary"
                            />
                        </FormRow>
                        <FormRow>
                            <NumberInput
                                label="Medium/High Score Boundary"
                                name="highScoreBoundary"
                                value={highScoreBoundary || 0}
                                onChange={handleChange}
                                placeholder="High Boundary"
                            />
                        </FormRow>
                    </>
                )}
            </Form>
        </Modal>
    );
};

interface Props {
    id: number;
    closeModal: () => void;
}

export default CreateDimensionSubcategoryModal;
