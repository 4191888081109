import dayjs from 'dayjs';
import Table from 'lib/src/components/table/Table';

import { useSelector } from 'react-redux';

import { getCompaniesIsFetching } from '@selectors/companies';

import { Column } from 'lib/src/types/table';
import { Company } from 'src/types/shared/Companies';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';

const CompaniesTable = ({ companies }: CompaniesTableProps) => {
    const isFetching = useSelector(getCompaniesIsFetching);

    return <Table isLoading={isFetching} columns={columns} rows={Object.values(companies)} />;
};

const columns: Column<Company>[] = [
    {
        key: 1,
        heading: 'Company reference',
        getValue: row => row.companyReference,
        searchable: true,
    },
    {
        key: 2,
        heading: 'Start date',
        getValue: row =>
            row.startDate ? dayjs(row.startDate).format('DD-MM-YYYY') : 'No start date',
        getSort: (a, b) =>
            a.startDate && b.startDate
                ? new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
                : 0,
        searchable: true,
    },
    {
        key: 3,
        heading: 'End date',
        getValue: row => (row.endDate ? dayjs(row.endDate).format('DD-MM-YYYY') : 'No end date'),
        getSort: (a, b) =>
            a.endDate && b.endDate
                ? new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
                : 0,
        searchable: true,
    },
    {
        key: 4,
        heading: 'Is company active?',
        getValue: row => (row.isEnabled ? 'Yes' : 'No'),
        getSort: (a, b) => {
            if (a.isEnabled > b.isEnabled) return +1;
            if (a.isEnabled < b.isEnabled) return -1;
            return 0;
        },
    },
    {
        key: 5,
        heading: 'Submission count',
        getValue: row => `${row.submissionCount ? row.submissionCount : '0'}`,
        getSort: (a, b) => a.submissionCount - b.submissionCount,
    },
    {
        key: 6,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton href={`/companies/${row.id}`}>View</LinkButton>
            </ButtonRow>
        ),
    },
];

interface CompaniesTableProps {
    companies: Record<number, Company>;
}

export default CompaniesTable;
