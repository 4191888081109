import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { APIError } from 'lib/src/types/APIError';

export const deleteDimensionQuestionRequest = createAction('deleteDimensionQuestionRequest');
export const deleteDimensionQuestionSuccess = createAction<number>(
    'deleteDimensionQuestionSuccess',
);
export const deleteDimensionQuestionFailure = createAction('deleteDimensionQuestionFailure');

export const deleteDimensionQuestion = (questionID: number) => async (dispatch: AppDispatch) => {
    dispatch(deleteDimensionQuestionRequest());
    try {
        await api.delete(`dimensions/questions/${questionID}`);
        dispatch(deleteDimensionQuestionSuccess(questionID));
    } catch (e) {
        handleApiErrors(dispatch, deleteDimensionQuestionFailure, e as APIError);
    }
};
