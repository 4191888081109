import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';

import { APIError } from 'lib/src/types/APIError';
import { DimensionSubcategory } from '../../../types/shared/Dimensions';

export const fetchAllDimensionSubcategoriesRequest = createAction(
    'fetchAllDimensionSubcategoriesRequest',
);
export const fetchAllDimensionSubcategoriesSuccess = createAction<DimensionSubcategory[]>(
    'fetchAllDimensionSubcategoriesSuccess',
);
export const fetchAllDimensionSubcategoriesFailure = createAction(
    'fetchAllDimensionSubcategoriesFailure',
);

export const fetchAllDimensionSubcategories = () => async (dispatch: AppDispatch) => {
    dispatch(fetchAllDimensionSubcategoriesRequest());
    try {
        const { data }: Response = await api.get(`dimensions/subcategories`);

        dispatch(fetchAllDimensionSubcategoriesSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchAllDimensionSubcategoriesFailure, e as APIError);
    }
};

interface Response {
    data: DimensionSubcategory[];
}
