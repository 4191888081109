import { RootState } from '@reducers/index';
import { Company } from 'src/types/shared/Companies';

export const getAllCompanies = (state: RootState): Record<number, Company> =>
    state.companiesReducer.companies;
export const getCompany = (state: RootState, id: number): Company | undefined =>
    state.companiesReducer.companies[id];
export const getCompaniesIsFetching = (state: RootState): boolean =>
    state.companiesReducer.isFetching;
export const getCompaniesError = (state: RootState): string | null => state.companiesReducer.error;

export const getCompaniesIsPosting = (state: RootState): boolean =>
    state.companiesReducer.isPosting;
export const getCompaniesPostSuccess = (state: RootState): boolean =>
    state.companiesReducer.postSuccess;
export const getCompanyCreated = (state: RootState): number | null =>
    state.companiesReducer.companyCreated;
export const selectModulesError = (state: RootState): string | null =>
    state.companiesReducer.modulesError;
