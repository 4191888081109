import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { Dimension } from '../../../types/shared/Dimensions';

export const copyQuestionToDimensionSubcategoryRequest = createAction(
    'copyQuestionToDimensionSubcategoryRequest',
);
export const copyQuestionToDimensionSubcategorySuccess = createAction<Dimension>(
    'copyQuestionToDimensionSubcategorySuccess',
);
export const copyQuestionToDimensionSubcategoryFailure = createAction(
    'copyQuestionToDimensionSubcategoryFailure',
);

export const copyQuestionToDimensionSubcategory =
    (subcategoryDestinationID: number, questionID: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(copyQuestionToDimensionSubcategoryRequest());

        try {
            const { data }: Response = await api.post(
                `dimensions/subcategories/${subcategoryDestinationID}/questions/copy?sourceQuestionId=${questionID}`,
                {},
            );

            dispatch(copyQuestionToDimensionSubcategorySuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, copyQuestionToDimensionSubcategoryFailure, e as APIError);
        }
    };

interface Response {
    data: Dimension;
}
