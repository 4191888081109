import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/store';
import { getCompanySection } from '@selectors/companyQuestions';
import CompanySectionComponent from './CompanySection';
import UpdateCompanySectionModal from '../modals/UpdateCompanySectionModal';
import DeleteCompanySectionModal from './DeleteCompanySectionModal';
import { CustomSection } from 'src/types/shared/Companies';
import { useEffect, useState } from 'react';
import usePrevious from 'lib/src/hooks/usePrevious';
import CreateCompanyQuestionModal from '../modals/CreateCompanyQuestionModal';
import { useDispatch } from 'react-redux';
import { fetchCompanyOnboardingSections } from '@actions/companyQuestions/fetchCompanyOnboardingSections';

const CompanySectionContainer = () => {
    const { sectionID, companyID } = useParams<{
        sectionID: string;
        companyID: string;
    }>();

    const history = useHistory();

    const [showUpdateSectionModal, setShowUpdateSectionModal] = useState<boolean>(false);
    const [showDeleteSectionModal, setShowDeleteSectionModal] = useState<boolean>(false);
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

    const dispatch = useDispatch();

    const section = useAppSelector(state => getCompanySection(state, +sectionID));
    const prevSection = usePrevious(section);

    if (!section) {
        dispatch(fetchCompanyOnboardingSections(+companyID));
    }
    useEffect(() => {
        if (!section && prevSection) {
            history.replace(`/companies/${companyID}`);
        }
    }, [section, prevSection, companyID, history]);

    return (
        <>
            <CompanySectionComponent
                section={section as CustomSection}
                showUpdateModal={() => setShowUpdateSectionModal(true)}
                showDeleteModal={() => setShowDeleteSectionModal(true)}
                showCreateModal={() => setShowCreateModal(true)}
            />
            {showUpdateSectionModal && !!section && (
                <UpdateCompanySectionModal
                    section={section}
                    closeModal={() => setShowUpdateSectionModal(false)}
                />
            )}
            {showDeleteSectionModal && !!section && (
                <DeleteCompanySectionModal
                    section={section as CustomSection}
                    closeModal={() => setShowDeleteSectionModal(false)}
                />
            )}
            {showCreateModal && (
                <CreateCompanyQuestionModal
                    companyID={+companyID}
                    sectionID={+sectionID}
                    setSectionToCreateQuestion={() => setShowCreateModal(false)}
                />
            )}
        </>
    );
};

export default CompanySectionContainer;
