import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import Loading from '@components/layout/loading/Loading';
import Select from 'lib/src/components/form/Select';
import FormField from 'lib/src/components/form/FormField';
import useImportSectionsFromModule from '../hooks/useImportDimensionsFromModule';
import { Module } from 'src/types/shared/Modules';

function ImportSectionsModal({ closeModal, item }: Props) {
    const { id } = item;
    const {
        selectedModuleID,
        selectedDimensionID,
        handleChange,
        handleSubmit,
        isFetchingModules,
        modulesFetchError,
        moduleOptions,
        dimensionOptions,
        isPosting,
    } = useImportSectionsFromModule(closeModal, id);

    return (
        <Modal closeModal={closeModal} title="Import Dimensions" extraClasses="show-overflow">
            {isFetchingModules ? (
                <Loading />
            ) : (
                <Form
                    onSubmit={handleSubmit}
                    onCancel={closeModal}
                    error={modulesFetchError}
                    isPosting={isPosting}
                >
                    <FormField name="selectedModuleID" label="Module">
                        <Select
                            search
                            options={moduleOptions}
                            name="selectedModuleID"
                            value={selectedModuleID}
                            onChange={(name, val) => handleChange(name, val)}
                        />
                    </FormField>
                    <FormField name="selectedDimensionID" label="Dimension">
                        <Select
                            search
                            options={dimensionOptions}
                            name="selectedDimensionID"
                            disabled={!selectedModuleID}
                            value={selectedDimensionID}
                            onChange={(name, val) => handleChange(name, val)}
                        />
                    </FormField>
                </Form>
            )}
        </Modal>
    );
}

interface Props {
    closeModal: () => void;
    item: Module;
}
export default ImportSectionsModal;
