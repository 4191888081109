import { Config } from '.';

const config: Config = {
    API_URL: 'https://api.87wellbeingadmin.com',
    S3_URL: 'https://s3-eu-west-1.amazonaws.com/87-percent-live-media',
    URL: 'https://www.87wellbeingadmin.com/',
    WEB_URL: 'https://www.87wellbeingassessment.com/',
};

export default config;
