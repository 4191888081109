import Title from 'lib/src/components/typography/Title';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import { CustomSection } from '../../../types/shared/Companies';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import CompanyQuestionsTable from '../CompanyQuestionsTable';
import config from '../../../config';
const { S3_URL } = config;

const CompanySection = ({
    section,
    showUpdateModal,
    showDeleteModal,
    showCreateModal,
}: CompanySectionProps) => {
    if (!section) return null;
    const { name, description, imageS3Key } = section;

    return (
        <>
            <Title>Section - {name}</Title>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Name">
                        <p>{name}</p>
                    </ContentItem>
                    <ContentItem label="Description">
                        <p>{description}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    {imageS3Key && (
                        <ContentItem label="Image">
                            <img
                                src={`${S3_URL}/${imageS3Key}`}
                                className="logo"
                                alt={'section logo'}
                            />
                        </ContentItem>
                    )}
                </ContentRow>
            </ContentBlock>

            <ButtonRow alignment="left">
                <ActionButton color="green" icon="plus" onClick={showCreateModal}>
                    Create Question
                </ActionButton>
                <ActionButton color="grey" onClick={showUpdateModal}>
                    Edit
                </ActionButton>
                <ActionButton color="red" onClick={showDeleteModal}>
                    Delete
                </ActionButton>
            </ButtonRow>

            <div style={{ minHeight: '2em' }} />

            <Title>Questions</Title>
            <CompanyQuestionsTable section={section} />
        </>
    );
};

interface CompanySectionProps {
    section: CustomSection;
    showUpdateModal: () => void;
    showDeleteModal: () => void;
    showCreateModal: () => void;
}

export default CompanySection;
