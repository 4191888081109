import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
    createCompanyFailure,
    createCompanyRequest,
    createCompanySuccess,
    deleteAssociationFailure,
    deleteAssociationRequest,
    deleteAssociationSuccess,
    deleteCompanyFailure,
    deleteCompanyRequest,
    deleteCompanySuccess,
    fetchAllCompaniesFailure,
    fetchAllCompaniesRequest,
    fetchAllCompaniesSuccess,
} from '@actions/companies';
import {
    fetchCompanyFailure,
    fetchCompanyRequest,
    fetchCompanySuccess,
} from '@actions/companies/fetchCompany';
import {
    updateCompanyFailure,
    updateCompanyRequest,
    updateCompanySuccess,
} from '@actions/companies/updateCompany';

import { convertArrToObj } from 'lib/src/utils/generic';

import { Company } from 'src/types/shared/Companies';
import {
    createAssociationFailure,
    createAssociationRequest,
    createAssociationSuccess,
} from '@actions/companies/createAssociation';
import { Module } from 'src/types/shared/Modules';
import {
    patchSortCompanyModulesFailure,
    patchSortCompanyModulesRequest,
    patchSortCompanyModulesSuccess,
} from '@actions/companies/patchSortCompanyModules';

interface CompaniesState {
    isFetching: boolean;
    error: string | null;
    modulesError: string | null;
    companies: Record<number, Company>;
    isPosting: boolean;
    postSuccess: boolean;
    companyCreated: number | null;
}

const initialState: CompaniesState = {
    isFetching: false,
    error: null,
    modulesError: null,
    companies: {},
    isPosting: false,
    postSuccess: false,
    companyCreated: null,
};

export default createReducer(initialState, {
    [fetchAllCompaniesRequest.type]: handleFetchRequest,
    [fetchAllCompaniesSuccess.type]: handleFetchAllSuccess,
    [fetchAllCompaniesFailure.type]: handleFailure,
    [createCompanyRequest.type]: handlePostRequest,
    [createCompanySuccess.type]: handlePostCompanySuccess,
    [createCompanyFailure.type]: handleFailure,
    [fetchCompanyRequest.type]: handleFetchRequest,
    [fetchCompanySuccess.type]: handleFetchSingleSuccess,
    [fetchCompanyFailure.type]: handleFailure,
    [deleteCompanyRequest.type]: handlePostRequest,
    [deleteCompanySuccess.type]: handleDeleteCompanySuccess,
    [deleteCompanyFailure.type]: handleFailure,
    [updateCompanyRequest.type]: handlePostRequest,
    [updateCompanySuccess.type]: handlePostCompanySuccess,
    [updateCompanyFailure.type]: handleFailure,
    [createAssociationRequest.type]: handlePostRequest,
    [createAssociationSuccess.type]: handleCreateAssociationSuccess,
    [createAssociationFailure.type]: handleFailure,
    [deleteAssociationRequest.type]: handlePostRequest,
    [deleteAssociationSuccess.type]: handleDeleteAssociationSuccess,
    [deleteAssociationFailure.type]: handleFailure,
    [patchSortCompanyModulesRequest.type]: handlePostRequest,
    [patchSortCompanyModulesSuccess.type]: handlePostCompanySuccess,
    [patchSortCompanyModulesFailure.type]: handleModulesFailure,
});

function handleFetchRequest(state: CompaniesState) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchAllSuccess(state: CompaniesState, action: PayloadAction<Company[]>) {
    state.companies = convertArrToObj(action.payload);
    state.isFetching = false;
}

function handleFailure(state: CompaniesState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isFetching = false;
    state.isPosting = false;
    state.companyCreated = null;
}

function handleModulesFailure(state: CompaniesState, action: PayloadAction<string>) {
    state.modulesError = action.payload;
    state.isFetching = false;
    state.isPosting = false;
}

function handlePostRequest(state: CompaniesState) {
    state.error = null;
    state.isPosting = true;
    state.postSuccess = false;
    state.companyCreated = null;
}

function handlePostCompanySuccess(state: CompaniesState, action: PayloadAction<Company>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.companies[action.payload.id] = action.payload;
    state.companyCreated = action.payload.id;
}

function handleFetchSingleSuccess(state: CompaniesState, action: PayloadAction<Company>) {
    state.isPosting = false;
    state.isFetching = false;
    state.postSuccess = true;
    state.companies[action.payload.id] = action.payload;
}

function handleDeleteCompanySuccess(state: CompaniesState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.companies[action.payload];
}

function handleCreateAssociationSuccess(state: CompaniesState, action: PayloadAction<Company>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.companies[action.payload.id] = action.payload;
}

function handleDeleteAssociationSuccess(state: CompaniesState, action: PayloadAction<any>) {
    state.isPosting = false;
    state.postSuccess = true;
    if (state?.companies === undefined) return;
    const index = state.companies[action.payload.companyID]?.modules?.findIndex(
        (x: Module) => x.id === action.payload.moduleID,
    );
    state.companies[action.payload.companyID]?.modules?.splice(index, 1);
}
