import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from 'src/redux/store';
import { Dimension } from '../../../types/shared/Dimensions';

import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const fetchAllDimensionsRequest = createAction('fetchAllDimensionsRequest');
export const fetchAllDimensionsSuccess = createAction<Dimension[]>('fetchAllDimensionsSuccess');
export const fetchAllDimensionsFailure = createAction('fetchAllDimensionsFailure');

export const fetchAllDimensions =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchAllDimensionsRequest());

        try {
            const { data }: Response = await api.get('dimensions');

            dispatch(fetchAllDimensionsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchAllDimensionsFailure, e as APIError);
        }
    };

interface Response {
    data: Dimension[];
}
