import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { CustomSection } from 'src/types/shared/Companies';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const postCopyCompanyQuestionsRequest = createAction('postCopyCompanyQuestionsRequest');
export const postCopyCompanyQuestionsSuccess = createAction<CustomSection[]>(
    'postCopyCompanyQuestionsSuccess',
);
export const postCopyCompanyQuestionsFailure = createAction('postCopyCompanyQuestionsFailure');

export const postCopyCompanyQuestions =
    (destinationID: number, sourceID: number) => async (dispatch: AppDispatch) => {
        dispatch(postCopyCompanyQuestionsRequest());

        try {
            const { data }: CopyQuestionsResponse = await api.post<
                Record<string, unknown>,
                CustomSection[]
            >(`company/${destinationID}/onboarding/copy?sourceID=${sourceID}`, {});

            dispatch(postCopyCompanyQuestionsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postCopyCompanyQuestionsFailure, e as APIError);
        }
    };

interface CopyQuestionsResponse {
    data: CustomSection[];
}
