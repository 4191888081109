import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import useDeleteDimensionSubcategory from '../hooks/useDeleteDimensionSubcategory';

const DeleteDimensionAnswerModal = ({ id, closeModal }: DeleteModalProps) => {
    const { handleDelete, isPosting, error } = useDeleteDimensionSubcategory(id, closeModal);

    return (
        <ConfirmModal
            title="Delete Subcategory"
            description={`Are you sure you want to delete this subcategory?`}
            closeModal={closeModal}
            handleSubmit={handleDelete}
            isPosting={isPosting}
            error={error}
        />
    );
};

interface DeleteModalProps {
    id: number;
    closeModal: () => void;
}
export default DeleteDimensionAnswerModal;
