import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';
import {
    getAllCompanies,
    getCompaniesError,
    getCompaniesIsPosting,
    getCompaniesPostSuccess,
} from '@selectors/companies';
import { Company } from 'src/types/shared/Companies';
import { Module } from 'src/types/shared/Modules';
import { createAssociation } from '@actions/companies/createAssociation';
import { setFieldErrors } from 'lib/src/redux/actions/fieldErrors';
import useFetchCompanies from '@pages/companies/hooks/useFetchCompanies';

const useCreateCompanyAssociation = (module: Module, closeModal: () => void) => {
    const dispatch = useDispatch();

    const [selectedCompanyID, setSelectedCompanyID] = useState<number | null>(null);

    const handleChange = (name: string, id: number | null) => {
        setSelectedCompanyID(id);
    };

    useFetchCompanies(true);

    const companies = Object.values(useSelector(getAllCompanies) ?? []);
    const handleSubmit = () => {
        if (selectedCompanyID) {
            dispatch(createAssociation({ companyID: selectedCompanyID, moduleID: module.id }));
            closeModal();
        } else {
            dispatch(setFieldErrors({ selectedCompanyID: 'Please select a module' }));
        }
    };

    const isPosting = useSelector(getCompaniesIsPosting);
    const error = useSelector(getCompaniesError);
    const postSuccess = useSelector(getCompaniesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    const companyOptions = useMemo(() => {
        return companies
            ?.filter(
                (company: Company) =>
                    !module?.companies?.some(
                        (otherCompany: Company) => company.id === otherCompany.id,
                    ),
            )
            ?.map((company: Company) => ({
                value: company.id,
                label: company.companyReference,
            }));
    }, [companies, module.companies]);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        companyOptions,
        module,
        selectedCompanyID,
        handleSubmit,
        handleChange,
        isPosting,
        error,
    };
};

export default useCreateCompanyAssociation;
