import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from 'src/redux/store';
import { DimensionQuestion } from '../../../types/shared/Dimensions';

import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const fetchSingleDimensionQuestionRequest = createAction(
    'fetchSingleDimensionQuestionRequest',
);
export const fetchSingleDimensionQuestionSuccess = createAction<DimensionQuestion>(
    'fetchSingleDimensionQuestionSuccess',
);
export const fetchSingleDimensionQuestionFailure = createAction(
    'fetchSingleDimensionQuestionFailure',
);

export const fetchSingleDimensionQuestion =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSingleDimensionQuestionRequest());

        try {
            const { data }: Response = await api.get(`dimensions/questions/${id}`);

            dispatch(fetchSingleDimensionQuestionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSingleDimensionQuestionFailure, e as APIError);
        }
    };

interface Response {
    data: DimensionQuestion;
}
