import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { AccessCode } from 'src/types/shared/AccessCode';

export const updateAccessCodeRequest = createAction('updateAccessCodeRequest');
export const updateAccessCodeSuccess = createAction<AccessCode>('updateAccessCodeSuccess');
export const updateAccessCodeFailure = createAction('updateAccessCodeFailure');

export const updateAccessCode =
    (id: number, postBody: PostBody) => async (dispatch: AppDispatch) => {
        dispatch(updateAccessCodeRequest());

        try {
            const { data }: updateAccessCodeResponse = await api.patch(
                `access-codes/${id}`,
                postBody,
            );

            dispatch(updateAccessCodeSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateAccessCodeFailure, e as APIError);
        }
    };

interface PostBody {
    accessCode: string;
    maxUses: number | null;
}

interface updateAccessCodeResponse {
    data: AccessCode;
}
