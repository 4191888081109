import { useCallback } from 'react';

import useFieldValidation from '../../hooks/useFieldValidation';
import { FormInputProps } from '../../types/shared/FormInputProps';

import FormField from './FormField';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [
            { color: [
                '#200870',
                '#34268e',
                '#000000',
                '#e60000',
                '#ff9900',
                '#ffff00',
                '#008a00',
                '#0066cc',
                '#9933ff',
                '#ffffff',
                '#facccc',
                '#ffebcc',
                '#ffffcc',
                '#cce8cc',
                '#cce0f5',
                '#ebd6ff',
                '#bbbbbb',
                '#f06666',
                '#ffc266',
                '#ffff66',
                '#66b966',
                '#66a3e0',
                '#c285ff',
                '#888888',
                '#a10000',
                '#b26b00',
                '#b2b200',
                '#006100',
                '#0047b2',
                '#6b24b2',
                '#444444',
                '#5c0000',
                '#663d00',
                '#666600',
                '#003700',
                '#002966',
                '#3d1466',
            ] },
            { background: [] },
        ],
        ['link'],
    ],
};

const WysiwygInput: React.FC<WysiwygProps> = ({
    name,
    value,
    onChange,
    label = '',
    required = false,
    validationRegExp,
    minLength,
    maxLength,
    limitInputToMax = false,
    customValidate,
}) => {
    const memoizedValidate = useCallback(_validate, [minLength, maxLength, validationRegExp]);
    const [error, showError] = useFieldValidation({
        name,
        required,
        value,
        customValidate,
        extendedValidate: memoizedValidate,
    });

    return (
        <FormField name={name} label={label} required={required} error={error}>
            <ReactQuill
                onKeyDown={checkCharacterCount}
                id={name}
                theme="snow"
                onChange={handleChange}
                value={value}
                className={`wysiwyg-input ${error ? 'error' : ''}`}
                onBlur={handleBlur}
                modules={modules}
            />
        </FormField>
    );

    function checkCharacterCount(event: KeyboardEvent) {
        if (
            !!maxLength &&
            value.length >= maxLength &&
            limitInputToMax &&
            event.key !== 'Backspace'
        ) {
            event.preventDefault();
        }
    }

    function handleChange(content: string) {
        onChange(name, content);
    }

    function handleBlur() {
        showError();
    }

    function _validate(val: string) {
        if (!val) return;

        if (minLength && val.length < minLength) {
            return `Value must have at least ${minLength} characters.`;
        }
        if (maxLength && val.length > maxLength) {
            return `Value cannot have more than ${maxLength} characters.`;
        }
        if (validationRegExp && !RegExp(validationRegExp).test(val)) {
            return 'Invalid value provided.';
        }
    }
};

interface WysiwygProps extends FormInputProps<string> {
    placeholder?: string;
    label?: string;
    validationRegExp?: string;
    minLength?: number;
    maxLength?: number;
    limitInputToMax?: boolean;
}

export default WysiwygInput;
