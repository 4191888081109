import { RootState } from '@reducers/index';
import { DimensionQuestion } from '../../../types/shared/Dimensions';

import { useHistory, useParams } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import usePrevious from 'lib/src/hooks/usePrevious';

import {
    selectDimensionQuestion,
    selectDimensionQuestionsIsFetching,
} from '@selectors/dimensionQuestions';
import { fetchSingleDimensionQuestion } from '@actions/dimensionQuestions/fetchSingleDimensionQuestion';
import { fetchQuestionAnswers } from '@actions/dimensionQuestions/answers/fetchQuestionAnswers';

const useDimensionQuestion = () => {
    const { questionID } = useParams<{ questionID: string }>();
    const dispatch = useDispatch();
    const history = useHistory();

    const isFetching = useSelector(selectDimensionQuestionsIsFetching);
    const question: DimensionQuestion = useSelector((state: RootState) =>
        selectDimensionQuestion(state, +questionID),
    );
    const prevQuestion = usePrevious(question);

    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    useEffect(() => {
        batch(() => {
            dispatch(fetchSingleDimensionQuestion(+questionID));
            dispatch(fetchQuestionAnswers(+questionID));
        });
    }, [dispatch, questionID]);

    useEffect(() => {
        if (!question && prevQuestion) {
            history.replace(
                `/dimensions/${prevQuestion.dimensionID}/subcategories/${prevQuestion.subcategoryID}`,
            );
        }
    }, [question, prevQuestion, history]);

    return {
        isFetching,
        question,
        showUpdateModal,
        setShowUpdateModal,
        showDeleteModal,
        setShowDeleteModal,
    };
};

export default useDimensionQuestion;
