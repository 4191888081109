import { RootState } from '@reducers/index';
import { AccessCode } from 'src/types/shared/AccessCode';

export const getAllAccessCodes = (state: RootState): Record<number, AccessCode> =>
    state.accessCodesReducer.accessCodes;
export const getAccessCodesIsFetching = (state: RootState): boolean =>
    state.accessCodesReducer.isFetching;
export const getAccessCodesError = (state: RootState): string | null =>
    state.accessCodesReducer.error;

export const getAccessCodesIsPosting = (state: RootState): boolean =>
    state.accessCodesReducer.isPosting;
export const getAccessCodesPostSuccess = (state: RootState): boolean =>
    state.accessCodesReducer.postSuccess;
export const getAccessCode = (state: RootState, id: number): AccessCode =>
    state.accessCodesReducer.accessCodes[id];
