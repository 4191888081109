import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';

import {
    getModuleQuestionPostSuccess,
    getModuleQuestionsError,
    getModuleQuestionsIsPosting,
} from '@selectors/moduleQuestions';
import { deleteDimension } from '@actions/dimensions/deleteDimension';
import { Dimension } from 'src/types/shared/Dimensions';

const useDeleteDimension = ({ id, moduleID }: Dimension, closeModal: () => void) => {
    const dispatch = useDispatch();

    const handleDeleteModuleDimension = useCallback(() => {
        dispatch(deleteDimension(id));
    }, [dispatch, id]);

    const isPosting = useSelector(getModuleQuestionsIsPosting);
    const error = useSelector(getModuleQuestionsError);
    const postSuccess = useSelector(getModuleQuestionPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal, moduleID]);

    return { handleDeleteModuleDimension, isPosting, error };
};

export default useDeleteDimension;
