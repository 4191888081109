import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import {
    getAllModules,
    getModulesError,
    getModulesIsPosting,
    getModulesPostSuccess,
} from '@selectors/modules';
import { Module, ModuleRequestModel } from 'src/types/shared/Modules';
import { updateModule } from '@actions/modules/updateModule';

const useUpdateModule = (
    { id, name, startDate, endDate, isActive, isScoring, companyCount, dimensions }: Module,
    closeModal: () => void,
) => {
    const dispatch = useDispatch();

    const [form, handleChange, resetData] = useForm<FormData>({
        name,
        startDate,
        endDate,
        isActive,
        isScoring,
    });

    useEffect(
        () => {
            const newData = {
                id,
                name,
                startDate,
                endDate,
                isActive,
                isScoring,
                companyCount,
                dimensions,
            };
            resetData({
                ...newData,
            });
        },
        //eslint-disable-next-line
        [id, name, startDate, endDate, isActive, isScoring],
    );

    const handleSubmit = useCallback(() => {
        dispatch(updateModule(id, { ...form }));
    }, [dispatch, id, form]);

    const isPosting = useSelector(getModulesIsPosting);
    const error = useSelector(getModulesError);
    const postSuccess = useSelector(getModulesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    const modules = Object.values(useSelector(getAllModules) ?? []);

    return {
        form,
        handleSubmit,
        handleChange,
        isPosting,
        error,
        modules,
    };
};

type FormData = ModuleRequestModel;

export default useUpdateModule;
