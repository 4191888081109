import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCompaniesError, getCompaniesIsPosting } from '@selectors/companies';

import { setFieldErrors } from 'lib/src/redux/actions/fieldErrors';
import { AccessCode } from '../../../types/shared/AccessCode';
import { getAccessCodeModules } from '@selectors/modules';
import { deleteModuleFromAccessCode } from '@actions/accessCodes/deleteModuleFromAccessCode';

const useDeleteAssociation = (accessCode: AccessCode, closeModal: () => void) => {
    const dispatch = useDispatch();

    const [selectedModuleID, setSelectedModuleID] = useState<number | null>(null);

    const handleChange = (_: string, id: number | null) => {
        setSelectedModuleID(id);
    };
    const modules = useSelector(getAccessCodeModules);

    const moduleOptions = Object.values(modules).map(module => ({
        label: module.name,
        value: module.id,
    }));

    const handleSubmit = () => {
        if (selectedModuleID) {
            dispatch(deleteModuleFromAccessCode(accessCode.id, selectedModuleID));
            closeModal();
        } else {
            dispatch(setFieldErrors({ selectedCompanyID: 'Please select a company' }));
        }
    };

    const isPosting = useSelector(getCompaniesIsPosting);
    const error = useSelector(getCompaniesError);

    return {
        handleSubmit,
        isPosting,
        selectedModuleID,
        error,
        handleChange,
        moduleOptions,
    };
};

export default useDeleteAssociation;
