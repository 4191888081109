import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';
import { AccessCode } from 'src/types/shared/AccessCode';
import {
    createAccessCodeFailure,
    createAccessCodeRequest,
    createAccessCodeSuccess,
    deleteAccessCodeFailure,
    deleteAccessCodeRequest,
    deleteAccessCodeSuccess,
    fetchCompanyAccessCodesFailure,
    fetchCompanyAccessCodesRequest,
    fetchCompanyAccessCodesSuccess,
    fetchSingleAccessCodeFailure,
    fetchSingleAccessCodeRequest,
    fetchSingleAccessCodeSuccess,
    updateAccessCodeFailure,
    updateAccessCodeRequest,
    updateAccessCodeSuccess,
} from '@actions/accessCodes';
import { fetchCompanySuccess } from '@actions/companies';
import { Company } from '../../types/shared/Companies';

interface AccessCodeState {
    isFetching: boolean;
    accessCodes: Record<number, AccessCode>;
    isPosting: boolean;
    postSuccess: boolean;
    error: string | null;
}

const initialState: AccessCodeState = {
    accessCodes: {},
    isPosting: false,
    postSuccess: false,
    error: null,
    isFetching: false,
};

export default createReducer(initialState, {
    [createAccessCodeRequest.type]: handlePostRequest,
    [createAccessCodeSuccess.type]: handlePostSuccess,
    [createAccessCodeFailure.type]: handleError,
    [fetchCompanyAccessCodesRequest.type]: handleFetchRequest,
    [fetchCompanyAccessCodesSuccess.type]: handleFetchAllSuccess,
    [fetchCompanyAccessCodesFailure.type]: handleFailure,
    [deleteAccessCodeRequest.type]: handlePostRequest,
    [deleteAccessCodeSuccess.type]: handleDeleteSuccess,
    [deleteAccessCodeFailure.type]: handleError,
    [updateAccessCodeRequest.type]: handlePostRequest,
    [updateAccessCodeSuccess.type]: handlePostSuccess,
    [updateAccessCodeFailure.type]: handleError,
    [fetchCompanySuccess.type]: handleCompanyFetchSuccess,
    [fetchSingleAccessCodeRequest.type]: handleFetchRequest,
    [fetchSingleAccessCodeSuccess.type]: handleFetchSingleSuccess,
    [fetchSingleAccessCodeFailure.type]: handleFailure,
});

function handlePostRequest(state: AccessCodeState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;
}

function handlePostSuccess(state: AccessCodeState, action: PayloadAction<AccessCode>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.accessCodes[action.payload.id] = action.payload;
}

function handleError(state: AccessCodeState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.error = action.payload;
}

function handleFetchRequest(state: AccessCodeState) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchAllSuccess(state: AccessCodeState, action: PayloadAction<AccessCode[]>) {
    state.isFetching = false;
    state.accessCodes = convertArrToObj(action.payload);
}

function handleFailure(state: AccessCodeState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.isPosting = false;
    state.error = action.payload;
}

function handleDeleteSuccess(state: AccessCodeState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.accessCodes[action.payload];
}

function handleCompanyFetchSuccess(state: AccessCodeState, action: PayloadAction<Company>) {
    state.accessCodes = convertArrToObj(action.payload.accessCodes);
}

function handleFetchSingleSuccess(state: AccessCodeState, action: PayloadAction<AccessCode>) {
    state.isFetching = false;
    state.accessCodes[action.payload.id] = action.payload;
}
