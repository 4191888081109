import dayjs from 'dayjs';
import config from '../../../config';

import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import ContentItem from '@components/layout/contentBlock/ContentItem';

import { Company as CompanyModel, CustomSection } from 'src/types/shared/Companies';
import ImportSectionsModal from '@pages/companyQuestions/modals/ImportSectionsModal';
import { getAllCompanySections, getCompanyQuestionsIsFetching } from '@selectors/companyQuestions';
import { selectModulesError } from '@selectors/companies';
import { getAllAccessCodes } from '@selectors/accessCodes';

import UpdateCompanyModal from '@pages/companies/single/UpdateCompanyModal';
import CreateCompanyAssociationModal from './association/CreateCompanyAssociationModal';
import DeleteCompanyAssociationModal from './association/DeleteCompanyAssociationModal';
import UpdateCompanySectionModal from '@pages/companyQuestions/modals/UpdateCompanySectionModal';
import CreateCompanySectionModal from '@pages/companyQuestions/modals/CreateCompanySectionModal';
import DeleteCompanySectionModal from '@pages/companyQuestions/section/DeleteCompanySectionModal';
import CreateCompanyQuestionModal from '@pages/companyQuestions/modals/CreateCompanyQuestionModal';
import CreateAccessCodeModal from '@pages/companies/single/accessCodes/modals/CreateAccessCodeModal';

import Title from 'lib/src/components/typography/Title';
import Accordion from '@components/accordion/Accordion';
import ModalActionButtons from '@components/actions/ModalActionButtons';
import CompanyModuleAssociationButtons from '@components/actions/CompanyModuleAssociationButtons';

import { postSortCompanySections } from '@actions/companyQuestions/postSortCompanySections';

import ModulesTable from '@pages/modules/ModulesTable';
import CompanyQuestionsTable from '@pages/companyQuestions/CompanyQuestionsTable';
import AccessCodeTable from './AccessCodeTable';

import { Module } from 'src/types/shared/Modules';
import useDarkMode from 'lib/src/hooks/useDarkMode';
import { patchSortCompanyModules } from '@actions/companies/patchSortCompanyModules';

const { S3_URL } = config;

const Company = ({ company }: CompanyProps) => {
    const [showImportModal, setShowImportModal] = useState<boolean>(false);
    const [sectionToCreateQuestion, setSectionToCreateQuestion] = useState<number | null>(null);
    const [sectionToUpdate, setSectionToUpdate] = useState<number | null>(null);
    const [sectionToDelete, setSectionToDelete] = useState<number | null>(null);

    const {
        id,
        companyReference,
        startDate,
        endDate,
        submissionCount,
        isEnabled,
        couldImproveMessage,
        imageS3Key,
        supportServiceLogoS3Key,
        hideDimensionWheel,
        supportLink,
        languageCode,
    } = company;

    const accessCodes = useSelector(getAllAccessCodes);
    const sections = useSelector(getAllCompanySections);
    const isFetching = useSelector(getCompanyQuestionsIsFetching);
    const modulesError = useSelector(selectModulesError);
    const [darkMode] = useDarkMode();

    const history = useHistory();
    const dispatch = useDispatch();

    const processedModules = useMemo(() => {
        const modules: Record<number, Module> = {};
        if (!company?.modules) return [];
        company.modules.forEach((module: Module) => {
            modules[module.id] = module;
        });
        return modules;
    }, [company.modules]);

    const handleViewSingle = (sectionID: number) => {
        history.push(`/companies/${id}/sections/${sectionID}`);
    };

    const postSort = (newSections: CustomSection[]) => {
        dispatch(postSortCompanySections(id, newSections));
    };

    const sortAction = (modules: Module[]) => {
        dispatch(patchSortCompanyModules(company.id, modules));
    };

    return (
        <>
            <Title>Company - {companyReference}</Title>
            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Company reference">
                        <p>{companyReference}</p>
                    </ContentItem>
                    <ContentItem label="Is company active?">
                        <p>{isEnabled ? 'Yes' : 'No'}</p>
                    </ContentItem>
                    <ContentItem label="Is dimension wheel hidden">
                        <p>{hideDimensionWheel ? 'Yes' : 'No'}</p>
                    </ContentItem>
                    <ContentItem label=""></ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Start date">
                        <p>{startDate ? dayjs(startDate).format('DD-MM-YYYY') : 'No start date'}</p>
                    </ContentItem>
                    <ContentItem label="End date">
                        <p>{endDate ? dayjs(endDate).format('DD-MM-YYYY') : 'No end date'}</p>
                    </ContentItem>
                    <ContentItem label="Submission count">
                        <p>{submissionCount}</p>
                    </ContentItem>
                    <ContentItem label=""></ContentItem>
                </ContentRow>
                {couldImproveMessage && (
                    <>
                        <ContentRow>
                            <ContentItem label="Could improve message">
                                <div
                                    dangerouslySetInnerHTML={{ __html: couldImproveMessage }}
                                    className="wysiwyg"
                                />
                            </ContentItem>
                            <ContentItem label="Support link">
                                <p>{supportLink ? supportLink : 'No support link'}</p>
                            </ContentItem>
                        </ContentRow>
                        <ContentRow>
                            <ContentItem label="Support logo">
                                <img
                                    className={`logo ${darkMode ? 'dark-mode' : ''}`}
                                    src={`${S3_URL}/${supportServiceLogoS3Key}`}
                                    alt="Company logo"
                                />
                            </ContentItem>
                        </ContentRow>
                    </>
                )}
                {imageS3Key && (
                    <ContentRow>
                        <ContentItem label="Company logo">
                            <img
                                className={`logo ${darkMode ? 'dark-mode' : ''}`}
                                src={`${S3_URL}/${imageS3Key}`}
                                alt="Company logo"
                            />
                        </ContentItem>
                    </ContentRow>
                )}
                <ContentRow>
                    <ContentItem label="Language code">
                        <p>{languageCode}</p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>
            <ModalActionButtons
                name="Section"
                CreateModal={CreateCompanySectionModal}
                UpdateModal={UpdateCompanyModal}
                ImportModal={ImportSectionsModal}
                item={company}
            />
            <Accordion
                setSectionToCreateQuestion={setSectionToCreateQuestion}
                setSectionToUpdate={setSectionToUpdate}
                setSectionToDelete={setSectionToDelete}
                sections={Object.values(sections)}
                isFetching={isFetching}
                handleViewSingle={handleViewSingle}
                postSort={postSort}
                tableComponent={item => <CompanyQuestionsTable section={item} />}
            />
            <Title>Access codes</Title>
            <ModalActionButtons
                name="Access Code"
                CreateModal={CreateAccessCodeModal}
                item={company}
            />
            <AccessCodeTable accessCodes={accessCodes} />
            <Title>Module Associations</Title>
            <div style={{ minHeight: '2em' }} />
            <CompanyModuleAssociationButtons
                item={company}
                CreateModal={CreateCompanyAssociationModal}
                DeleteModal={DeleteCompanyAssociationModal}
                name={'Association'}
            />
            <div style={{ minHeight: '2em' }} />
            <ModulesTable
                modules={processedModules}
                isFetching={isFetching}
                error={modulesError}
                sortAction={sortAction}
                showSort
            />
            {showImportModal && (
                <ImportSectionsModal closeModal={() => setShowImportModal(false)} item={company} />
            )}
            {sectionToCreateQuestion && (
                <CreateCompanyQuestionModal
                    companyID={+id}
                    sectionID={sectionToCreateQuestion}
                    setSectionToCreateQuestion={setSectionToCreateQuestion}
                />
            )}

            {sectionToUpdate && (
                <UpdateCompanySectionModal
                    section={sections[sectionToUpdate]}
                    closeModal={() => setSectionToUpdate(null)}
                />
            )}
            {sectionToDelete && !!sections[sectionToDelete] && (
                <DeleteCompanySectionModal
                    section={sections[sectionToDelete]}
                    closeModal={() => setSectionToDelete(null)}
                />
            )}
        </>
    );
};

interface CompanyProps {
    company: CompanyModel;
}

export default Company;
