import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { APIError } from 'lib/src/types/APIError';
import { DimensionAnswer } from '../../../../types/shared/Dimensions';

export const createDimensionQuestionAnswerRequest = createAction(
    'createDimensionQuestionAnswerRequest',
);
export const createDimensionQuestionAnswerSuccess = createAction<DimensionAnswer>(
    'createDimensionQuestionAnswerSuccess',
);
export const createDimensionQuestionAnswerFailure = createAction(
    'createDimensionQuestionAnswerFailure',
);

export const createDimensionQuestionAnswer =
    (questionID: number, postBody: { text: string; score: number }) =>
    async (dispatch: AppDispatch) => {
        dispatch(createDimensionQuestionAnswerRequest());
        try {
            const { data }: CreateDimensionReponse = await api.post(
                `dimensions/questions/${questionID}/answers`,
                postBody,
            );
            dispatch(createDimensionQuestionAnswerSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createDimensionQuestionAnswerFailure, e as APIError);
        }
    };

interface CreateDimensionReponse {
    data: DimensionAnswer;
}
