import { useEffect } from 'react';
import useForm from 'lib/src/hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';

import { selectDimensionAnswersPostSuccess } from '@selectors/dimensionQuestions';
import { updateDimensionAnswer } from '@actions/dimensionQuestions/answers/updateDimensionAnswer';
import { DimensionAnswer } from '../../../../types/shared/Dimensions';

const useUpdateDimensionAnswer = (answer: DimensionAnswer, closeModal: () => void) => {
    const { id, text, score } = answer;

    const dispatch = useDispatch();

    const [form, handleChange] = useForm<{ text: string; score: number }>({ text, score });

    const postSuccess = useSelector(selectDimensionAnswersPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [closeModal, postSuccess, prevPostSuccess]);

    const handleSubmit = () => {
        const postBody = { ...form, score: +form.score };

        dispatch(updateDimensionAnswer(id, postBody));
    };

    return { form, handleChange, handleSubmit };
};

export default useUpdateDimensionAnswer;
