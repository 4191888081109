import { useAppSelector } from '../../../redux/store';
import useUpdateAnswer from './hooks/useUpdateAnswer';

import Modal from 'lib/src/components/modal/Modal';
import { getSingleAnswer } from '@selectors/companyQuestions';
import Form from 'lib/src/components/form/Form';
import TextInput from 'lib/src/components/form/TextInput';
import FormRow from 'lib/src/components/form/FormRow';

const UpdateAnswerModal = ({ answerID, closeModal }: UpdateAnswerModalProps) => {
    const answer = useAppSelector(state => getSingleAnswer(state, +answerID));

    const {
        form: { answerText },
        handleChange,
        handleSubmit,
        isPosting,
        error,
    } = useUpdateAnswer(answer, closeModal);

    return (
        <Modal title="Update answer" closeModal={closeModal}>
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting} error={error}>
                <FormRow>
                    <TextInput name="answerText" value={answerText} onChange={handleChange} />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface UpdateAnswerModalProps {
    answerID: number;
    closeModal: () => void;
}
export default UpdateAnswerModal;
