import { useDispatch, useSelector } from 'react-redux';

import { getCompanyQuestionsIsFetching } from '@selectors/companyQuestions';
import Table from 'lib/src/components/table/Table';
import ButtonRow from 'lib/src/components/button/ButtonRow';

import { QuestionAnswer } from 'src/types/shared/Companies';
import { Column } from 'lib/src/types/table';
import ActionButton from 'lib/src/components/button/ActionButton';
import { postSortQuestionAnswers } from '@actions/companyQuestions/answers/postSortQuestionAnswers';
import { Dispatch, SetStateAction, useMemo } from 'react';

const QuestionAnswersTable = ({
    answers,
    questionID,
    setAnswerToDelete,
    setAnswerToUpdate,
}: QuestionAnswersTableProps) => {
    const isFetching = useSelector(getCompanyQuestionsIsFetching);
    const dispatch = useDispatch();
    const handleSort = (answerID: number, direction: 'up' | 'down') => {
        const currentSorted = Object.values(answers).sort((a, b) => a.sort - b.sort);
        const newQuestions: QuestionAnswer[] = [];
        const questionToMove = currentSorted.find(question => question.id === answerID);
        if (!questionToMove) return;
        const question = { ...questionToMove };

        const sortAdjustment = direction === 'up' ? -1 : 1;
        question.sort += sortAdjustment;
        for (let i = 0; i < currentSorted.length; i++) {
            const curQuestion = { ...currentSorted[i] };
            if (curQuestion.id === answerID) {
                curQuestion.sort += sortAdjustment;
            }
            if (curQuestion.id !== answerID && curQuestion.sort === question.sort) {
                curQuestion.sort -= sortAdjustment;
            }
            newQuestions.push(curQuestion);
        }
        dispatch(postSortQuestionAnswers(questionID, newQuestions));
    };
    const columns: Column<QuestionAnswer>[] = [
        {
            key: 1,
            heading: 'Question text',
            getValue: row => row.answerText,
        },
        {
            key: 2,
            heading: '',
            getValue: (row, i, rows) => (
                <ButtonRow alignment="right">
                    {i > 0 && (
                        <ActionButton
                            className="mini-button"
                            type="button"
                            onClick={() => handleSort(row.id, 'up')}
                        >
                            ↑
                        </ActionButton>
                    )}
                    {i < rows.length - 1 && (
                        <ActionButton
                            className="mini-button"
                            type="button"
                            onClick={() => handleSort(row.id, 'down')}
                        >
                            ↓
                        </ActionButton>
                    )}
                    <ActionButton
                        color="grey"
                        icon="pencil"
                        onClick={() => setAnswerToUpdate(row.id)}
                    >
                        Edit
                    </ActionButton>
                    <ActionButton
                        icon="trash-alt"
                        onClick={() => setAnswerToDelete(row.id)}
                        color="red"
                    >
                        Delete
                    </ActionButton>
                </ButtonRow>
            ),
        },
    ];

    const sortedAnswers = useMemo(() => {
        return Object.values(answers).sort((a, b) => a.sort - b.sort);
    }, [answers]);
    return <Table isLoading={isFetching} columns={columns} rows={sortedAnswers} />;
};

interface QuestionAnswersTableProps {
    answers: Record<number, QuestionAnswer>;
    questionID: number;
    setAnswerToDelete: Dispatch<SetStateAction<number | null>>;
    setAnswerToUpdate: Dispatch<SetStateAction<number | null>>;
}
export default QuestionAnswersTable;
