import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';

import { AccessCode } from '../../../types/shared/AccessCode';

import { setFieldErrors } from 'lib/src/redux/actions/fieldErrors';
import {
    getAccessCodesError,
    getAccessCodesIsPosting,
    getAccessCodesPostSuccess,
} from '@selectors/accessCodes';
import { getCompany } from '@selectors/companies';
import { RootState } from '@reducers/index';
import { addModuleToAccessCode } from '@actions/accessCodes/addModuleToAccessCode';
import { getAccessCodeModules } from '@selectors/modules';

const useCreateCompanyAssociation = (accessCode: AccessCode, closeModal: () => void) => {
    const dispatch = useDispatch();

    const [selectedModuleID, setSelectedModuleID] = useState<number | null>(null);

    const handleChange = (name: string, id: number | null) => {
        setSelectedModuleID(id);
    };

    const company = useSelector((state: RootState) => getCompany(state, +accessCode.companyID));
    const accessCodeModules = Object.values(useSelector(getAccessCodeModules));

    const moduleOptions = company?.modules.reduce(
        (acc: { label: string; value: number }[], module) => {
            if (!accessCodeModules.some(({ id }) => id === module.id)) {
                acc.push({
                    label: module.name,
                    value: module.id,
                });
            }

            return acc;
        },
        [],
    );

    const handleSubmit = () => {
        if (selectedModuleID) {
            dispatch(addModuleToAccessCode(accessCode.id, selectedModuleID));
            closeModal();
        } else {
            dispatch(setFieldErrors({ selectedCompanyID: 'Please select a module' }));
        }
    };

    const isPosting = useSelector(getAccessCodesIsPosting);
    const error = useSelector(getAccessCodesError);
    const postSuccess = useSelector(getAccessCodesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        moduleOptions,
        module,
        selectedModuleID,
        handleSubmit,
        handleChange,
        isPosting,
        error,
    };
};

export default useCreateCompanyAssociation;
