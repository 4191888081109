import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';

enum optionValues {
    view = 1,
    update = 2,
    delete = 3,
    addQuestion = 4,
    moveUp = 5,
    moveDown = 6,
}

interface FormData {
    action: optionValues | null;
}

const useAccordionActions = (
    sectionID: number,
    setSectionToCreateQuestion: Dispatch<SetStateAction<number | null>>,
    setSectionToDelete: Dispatch<SetStateAction<number | null>>,
    setSectionToUpdate: Dispatch<SetStateAction<number | null>>,
    isFirstSection: boolean,
    isLastSection: boolean,
    handleSort: (sectionID: number, direction: 'up' | 'down') => void,
    handleViewSingle: (id: number) => void,
    addText: string,
) => {
    const [formData, handleChange] = useForm<FormData>({ action: null });
    const prevProps = usePrevious({ action: formData.action });

    const actionOptions = [
        { value: optionValues.view, label: 'View' },
        { value: optionValues.update, label: 'Edit' },
        { value: optionValues.delete, label: 'Delete' },
        { value: optionValues.addQuestion, label: addText },
    ];

    const viewSingle = useCallback(handleViewSingle, [handleViewSingle]);

    if (!isFirstSection) actionOptions.push({ value: optionValues.moveUp, label: 'Move Up' });
    if (!isLastSection) actionOptions.push({ value: optionValues.moveDown, label: 'Move Down' });

    useEffect(() => {
        if (!!sectionID && formData.action !== prevProps.action) {
            switch (formData.action) {
                case optionValues.view:
                    viewSingle(sectionID);
                    break;
                case optionValues.update:
                    setSectionToUpdate(sectionID);
                    break;
                case optionValues.delete:
                    setSectionToDelete(sectionID);
                    break;
                case optionValues.addQuestion:
                    setSectionToCreateQuestion(sectionID);
                    break;
                case optionValues.moveUp:
                    handleSort(sectionID, 'up');
                    break;
                case optionValues.moveDown:
                    handleSort(sectionID, 'down');
                    break;
                default:
                    break;
            }
            handleChange('action', null);
        }
    }, [
        sectionID,
        handleChange,
        formData.action,
        prevProps.action,
        setSectionToCreateQuestion,
        setSectionToDelete,
        setSectionToUpdate,
        handleSort,
        viewSingle,
    ]);

    return { formData, handleChange, actionOptions };
};

export default useAccordionActions;
