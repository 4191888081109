import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import store from './redux/store';
import App from './App';

import 'lib/src/_styles/generic.scss';
import './_styles/main.scss';

import { initApi } from 'lib/src/utils/api';

import config from './config';

const { API_URL } = config;

initApi(API_URL);

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <App />
        </Provider>
    </Router>,
    document.getElementById('root'),
);
