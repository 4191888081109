import { useState } from 'react';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';

const CompanyModuleAssociationButtons = ({
    CreateModal,
    DeleteModal,
    item,
    disableCreate,
}: Props) => {
    const [showAssociationModalCreate, setShowAssociationModalCreate] = useState<boolean>(false);
    const [showAssociationModalDelete, setShowAssociationModalDelete] = useState<boolean>(false);
    return (
        <>
            <ButtonRow alignment="left">
                <ActionButton
                    color="green"
                    icon="plus"
                    onClick={() => setShowAssociationModalCreate(true)}
                    disabled={disableCreate}
                >
                    Create Association
                </ActionButton>
                <ActionButton color="red" onClick={() => setShowAssociationModalDelete(true)}>
                    Delete Association
                </ActionButton>
            </ButtonRow>

            {showAssociationModalCreate && (
                <CreateModal item={item} closeModal={() => setShowAssociationModalCreate(false)} />
            )}
            {showAssociationModalDelete && (
                <DeleteModal item={item} closeModal={() => setShowAssociationModalDelete(false)} />
            )}
        </>
    );
};

interface Props {
    name: string;
    CreateModal: (props: any) => JSX.Element;
    DeleteModal: (props: any) => JSX.Element;
    item: Record<number | string, any>;
    disableCreate?: boolean;
}

export default CompanyModuleAssociationButtons;
