import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const deleteModuleRequest = createAction('deleteModuleRequest');
export const deleteModuleSuccess = createAction('deleteModuleSuccess');
export const deleteModuleFailure = createAction('deleteModuleFailure');

export const deleteModule =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteModuleRequest());

        try {
            await api.delete(`modules/${id}`);

            dispatch(deleteModuleSuccess());
        } catch (e) {
            handleApiErrors(dispatch, deleteModuleFailure, e as APIError);
        }
    };
