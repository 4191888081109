import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { DimensionQuestion, DimensionQuestionPost } from 'src/types/shared/Dimensions';
import { APIError } from 'lib/src/types/APIError';

export const createDimensionQuestionRequest = createAction('createDimensionQuestionRequest');
export const createDimensionQuestionSuccess = createAction<DimensionQuestion>(
    'createDimensionQuestionSuccess',
);
export const createDimensionQuestionFailure = createAction('createDimensionQuestionFailure');

export const createDimensionQuestion =
    (dimensionID: number, postBody: DimensionQuestionPost) => async (dispatch: AppDispatch) => {
        dispatch(createDimensionQuestionRequest());
        try {
            const { data }: CreateDimensionReponse = await api.post(
                `dimensions/subcategories/${dimensionID}/questions`,
                postBody,
            );
            dispatch(createDimensionQuestionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createDimensionQuestionFailure, e as APIError);
        }
    };

interface CreateDimensionReponse {
    data: DimensionQuestion;
}
