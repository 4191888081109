import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { Module, ModuleRequestModel } from '../../../types/shared/Modules';
import { APIError } from 'lib/src/types/APIError';

export const createModuleRequest = createAction('createModuleRequest');
export const createModuleSuccess = createAction<Module[]>('createModuleSuccess');
export const createModuleFailure = createAction('createModuleFailure');

export const createModule = (postBody: ModuleRequestModel) => async (dispatch: AppDispatch) => {
    dispatch(createModuleRequest());

    try {
        const { data }: CreateModuleResponse = await api.post('modules', postBody);

        dispatch(createModuleSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createModuleFailure, e as APIError);
    }
};

interface CreateModuleResponse {
    data: Module[];
}
