import { useEffect } from 'react';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';

import { deleteDimensionAnswer } from '@actions/dimensionQuestions/answers/deleteDimensionAnswer';
import {
    selectDimensionAnswersIsPosting,
    selectDimensionAnswersPostSuccess,
    selectDimensionQuestionsError,
} from '@selectors/dimensionQuestions';

const useDeleteDimensionAnswer = (answerID: number, questionID: number, closeModal: () => void) => {
    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(deleteDimensionAnswer(answerID, questionID));
    };

    const isPosting = useSelector(selectDimensionAnswersIsPosting);
    const error = useSelector(selectDimensionQuestionsError);
    const postSuccess = useSelector(selectDimensionAnswersPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [closeModal, postSuccess, prevPostSuccess]);

    return { handleDelete, isPosting, error };
};

export default useDeleteDimensionAnswer;
