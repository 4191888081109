import { AccessCode } from '../../../types/shared/AccessCode';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import Select from 'lib/src/components/form/Select';
import useDeleteAssociation from '@pages/accessCodes/hooks/useDeleteAssociation';

const DeleteAccessCodeAssociation = ({ item, closeModal }: IModalProps) => {
    const { isPosting, handleSubmit, moduleOptions, selectedModuleID, handleChange } =
        useDeleteAssociation(item, closeModal);
    return (
        <Modal
            title="Delete association"
            closeModal={closeModal}
            size="medium"
            style={{ overflow: 'visible' }}
        >
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting}>
                <FormRow>
                    <Select
                        search
                        options={moduleOptions}
                        name="selectedModuleID"
                        value={selectedModuleID}
                        onChange={handleChange}
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface IModalProps {
    item: AccessCode;
    closeModal: () => void;
}
export default DeleteAccessCodeAssociation;
