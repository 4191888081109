import { RootState } from '@reducers/index';

export const getAllCompanySections = (state: RootState) => state.companyQuestionsReducer.sections;
export const getAllCompanyImportingSections = (state: RootState) =>
    state.companyQuestionsReducer.importingSections;

export const getCompanySection = (state: RootState, id: number) =>
    state.companyQuestionsReducer.sections[id];

export const getCompanyQuestion = (state: RootState, id: number, sectionID: number) =>
    state.companyQuestionsReducer.sections[sectionID].questions.find(q => q.id === id);

export const getCompanyQuestionsIsFetching = (state: RootState) =>
    state.companyQuestionsReducer.isFetching;

export const getCompanyQuestionsError = (state: RootState) => state.companyQuestionsReducer.error;

export const getCompanyQuestionsIsPosting = (state: RootState) =>
    state.companyQuestionsReducer.isPosting;

export const getCompanyQuestionPostSuccess = (state: RootState) =>
    state.companyQuestionsReducer.postSuccess;

export const getIsFetchingAnswers = (state: RootState) =>
    state.companyQuestionsReducer.isFetchingAnswers;

export const getAllQuestionAnswers = (state: RootState) => state.companyQuestionsReducer.answers;

export const getSingleAnswer = (state: RootState, id: number) =>
    state.companyQuestionsReducer.answers[id];

export const getCompanyOnboardingQuestions = (state: RootState) =>
    state.companyQuestionsReducer.onboardingQuestions;

export const getCompanyOnboardingIsFetching = (state: RootState) =>
    state.companyQuestionsReducer.isFetchingOnboarding;
