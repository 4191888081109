import useUpdateDimension from '@pages/dimensions/hooks/useUpdateDimension';

import { Dimension } from '../../../types/shared/Dimensions';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextArea from 'lib/src/components/form/TextArea';
import TextInput from 'lib/src/components/form/TextInput';
import FilePicker from 'lib/src/components/filePicker/FilePicker';
import Title from 'lib/src/components/typography/Title';
import config from '../../../config';
import {
    formatBoundaries,
    validateEmpty,
    validateScoresHigh,
    validateScoresMedium,
} from '../../../utils/form';
import ToggleSwitch from 'lib/src/components/form/ToggleSwitch';

const { API_URL, S3_URL } = config;

const UpdateDimensionModal = ({ closeModal, item }: Props) => {
    const {
        form: {
            name,
            description,
            isActive,
            mediumScoreBoundary,
            highScoreBoundary,
            lowScoreDetails,
            mediumScoreDetails,
            highScoreDetails,
            imageS3Key,
        },
        handleChange,
        handleSubmit,
        isScoring,
    } = useUpdateDimension(item, closeModal);

    return (
        <Modal
            title={`Update dimension - ${name} ${!isScoring ? '- (Non-scoring)' : ''}`}
            closeModal={closeModal}
            size="medium"
        >
            <Form onSubmit={handleSubmit} onCancel={closeModal}>
                <FormRow>
                    <TextInput
                        name="name"
                        value={name}
                        onChange={handleChange}
                        maxLength={20}
                        label="Name"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextArea
                        name="description"
                        value={description}
                        onChange={handleChange}
                        label="Description"
                    />
                </FormRow>
                <FormRow>
                    <ToggleSwitch
                        name="isActive"
                        value={isActive}
                        onChange={handleChange}
                        label="Is active?"
                    />
                </FormRow>
                <FormRow>
                    <FilePicker
                        name="imageS3Key"
                        value={imageS3Key}
                        onChange={handleChange}
                        label="Image"
                        maxFiles={1}
                        apiURL={API_URL}
                        storageURL={S3_URL}
                    />
                </FormRow>
                {isScoring && (
                    <>
                        <Title>Score Details</Title>
                        <FormRow>
                            <TextArea
                                name="summary"
                                value={lowScoreDetails.summary}
                                onChange={(name, value) =>
                                    handleChange('lowScoreDetails', {
                                        ...lowScoreDetails,
                                        [name]: value,
                                    })
                                }
                                label="Low Score Summary"
                                required
                                customValidate={x => validateEmpty(x)}
                            />
                        </FormRow>
                        <FormRow>
                            <TextArea
                                name="action"
                                value={lowScoreDetails.action}
                                onChange={(name, value) =>
                                    handleChange('lowScoreDetails', {
                                        ...lowScoreDetails,
                                        [name]: value,
                                    })
                                }
                                label="Low Score Action"
                                required
                                customValidate={x => validateEmpty(x)}
                            />
                        </FormRow>
                        <FormRow>
                            <TextArea
                                name="breakdown"
                                value={lowScoreDetails.breakdown}
                                onChange={(name, value) =>
                                    handleChange('lowScoreDetails', {
                                        ...lowScoreDetails,
                                        [name]: value,
                                    })
                                }
                                label="Low Score Breakdown"
                            />
                        </FormRow>
                        <FormRow>
                            <TextInput
                                name="mediumScoreBoundary"
                                value={mediumScoreBoundary.toString()}
                                onChange={(name, value) =>
                                    handleChange(name, formatBoundaries(value))
                                }
                                label="Low/Medium Score Boundary"
                                required
                                customValidate={x =>
                                    validateEmpty(x) +
                                    validateScoresMedium(+mediumScoreBoundary, +highScoreBoundary)
                                }
                            />
                        </FormRow>
                        <FormRow>
                            <TextArea
                                name="summary"
                                value={mediumScoreDetails.summary}
                                onChange={(name, value) =>
                                    handleChange('mediumScoreDetails', {
                                        ...mediumScoreDetails,
                                        [name]: value,
                                    })
                                }
                                label="Medium Score Summary"
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <TextArea
                                name="action"
                                value={mediumScoreDetails.action}
                                onChange={(name, value) =>
                                    handleChange('mediumScoreDetails', {
                                        ...mediumScoreDetails,
                                        [name]: value,
                                    })
                                }
                                label="Medium Score Action"
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <TextArea
                                name="breakdown"
                                value={mediumScoreDetails.breakdown}
                                onChange={(name, value) =>
                                    handleChange('mediumScoreDetails', {
                                        ...mediumScoreDetails,
                                        [name]: value,
                                    })
                                }
                                label="Medium Score Breakdown"
                            />
                        </FormRow>
                        <FormRow>
                            <TextInput
                                name="highScoreBoundary"
                                value={highScoreBoundary}
                                onChange={(name, value) =>
                                    handleChange(name, formatBoundaries(value))
                                }
                                label="Medium/High Score Boundary"
                                required
                                customValidate={x =>
                                    validateEmpty(x) +
                                    validateScoresHigh(+mediumScoreBoundary, +highScoreBoundary)
                                }
                            />
                        </FormRow>
                        <FormRow>
                            <TextArea
                                name="summary"
                                value={highScoreDetails.summary}
                                onChange={(name, value) =>
                                    handleChange('highScoreDetails', {
                                        ...highScoreDetails,
                                        [name]: value,
                                    })
                                }
                                label="High Score Summary"
                            />
                        </FormRow>
                        <FormRow>
                            <TextArea
                                name="action"
                                value={highScoreDetails.action}
                                onChange={(name, value) =>
                                    handleChange('highScoreDetails', {
                                        ...highScoreDetails,
                                        [name]: value,
                                    })
                                }
                                label="High Score Action"
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <TextArea
                                name="breakdown"
                                value={highScoreDetails.breakdown}
                                onChange={(name, value) =>
                                    handleChange('highScoreDetails', {
                                        ...highScoreDetails,
                                        [name]: value,
                                    })
                                }
                                label="High Score Breakdown"
                            />
                        </FormRow>
                    </>
                )}
            </Form>
        </Modal>
    );
};

interface Props {
    closeModal: () => void;
    item: Dimension;
}

export default UpdateDimensionModal;
