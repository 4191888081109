import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { CustomSection } from 'src/types/shared/Companies';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const postSortCompanySectionsRequest = createAction('postSortCompanySectionsRequest');
export const postSortCompanySectionsSuccess = createAction<CustomSection[]>(
    'postSortCompanySectionsSuccess',
);
export const postSortCompanySectionsFailure = createAction('postSortCompanySectionsFailure');

export const postSortCompanySections =
    (companyID: number, questions: CustomSection[]) => async (dispatch: AppDispatch) => {
        dispatch(postSortCompanySectionsRequest());

        const sorts = questions
            .sort((a, b) => a.sort - b.sort)
            .map((a, i) => ({ id: a.id, sort: i }));
        const postBody: PostBody = { sorts };

        try {
            const { data } = await api.patch<PostBody, CustomSection[]>(
                `company/${companyID}/onboarding/sections/sort`,
                postBody,
            );

            dispatch(postSortCompanySectionsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postSortCompanySectionsFailure, e as APIError);
        }
    };

interface Sort {
    id: number;
    sort: number;
}
interface PostBody {
    sorts: Sort[];
}
