import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { AccessCode } from 'src/types/shared/AccessCode';

export const fetchCompanyAccessCodesRequest = createAction('fetchCompanyAccessCodesRequest');
export const fetchCompanyAccessCodesSuccess = createAction<AccessCode[]>(
    'fetchCompanyAccessCodesSuccess',
);
export const fetchCompanyAccessCodesFailure = createAction('fetchCompanyAccessCodesFailure');

export const fetchCompanyAccessCodes =
    (companyID: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchCompanyAccessCodesRequest());

        try {
            const { data }: Response = await api.get(`company/${companyID}/access-codes`);

            dispatch(fetchCompanyAccessCodesSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchCompanyAccessCodesFailure, e as APIError);
        }
    };

interface Response {
    data: AccessCode[];
}
