import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from 'src/redux/store';
import { Dimension } from '../../../types/shared/Dimensions';

import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const fetchSingleDimensionRequest = createAction('fetchSingleDimension');
export const fetchSingleDimensionSuccess = createAction<Dimension>('fetchSingleDimensionSuccess');
export const fetchSingleDimensionFailure = createAction('fetchSingleDimensionFailure');

export const fetchSingleDimension =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSingleDimensionRequest());

        try {
            const { data }: Response = await api.get(`dimensions/${id}`);

            dispatch(fetchSingleDimensionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSingleDimensionFailure, e as APIError);
        }
    };

interface Response {
    data: Dimension;
}
