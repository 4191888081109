import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';

import { APIError } from 'lib/src/types/APIError';
import { Module } from '../../../types/shared/Modules';

export const addModuleToAccessCodeRequest = createAction('addModuleToAccessCodeRequest');
export const addModuleToAccessCodeSuccess = createAction<Module>('addModuleToAccessCodeSuccess');
export const addModuleToAccessCodeFailure = createAction('addModuleToAccessCodeFailure');

export const addModuleToAccessCode =
    (id: number, moduleID: number) => async (dispatch: AppDispatch) => {
        dispatch(addModuleToAccessCodeRequest());
        try {
            const { data }: Response = await api.post(`access-codes/${id}/modules/${moduleID}`, {});

            dispatch(addModuleToAccessCodeSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, addModuleToAccessCodeFailure, e as APIError);
        }
    };

interface Response {
    data: Module;
}
