import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { CustomSection, CustomSectionPost } from 'src/types/shared/Companies';
import { APIError } from 'lib/src/types/APIError';

export const createCompanySectionRequest = createAction('createCompanySectionRequest');
export const createCompanySectionSuccess = createAction<CustomSection>(
    'createCompanySectionSuccess',
);
export const createCompanySectionFailure = createAction('createCompanySectionFailure');

export const createCompanySection =
    (companyID: number, postBody: CustomSectionPost) => async (dispatch: AppDispatch) => {
        dispatch(createCompanySectionRequest());

        try {
            const { data }: CreateSectionReponse = await api.post(
                `company/${companyID}/onboarding/sections`,
                postBody,
            );

            dispatch(createCompanySectionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createCompanySectionFailure, e as APIError);
        }
    };

interface CreateSectionReponse {
    data: CustomSection;
}
