import { useState } from 'react';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';

const ModalActionButtons = ({
    name,
    CreateModal,
    UpdateModal,
    DeleteModal,
    ImportModal,
    item,
    disableCreate,
    disableImport,
}: Props) => {
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showImportModal, setShowImportModal] = useState<boolean>(false);

    return (
        <div style={{ margin: '2em 0 2em 0' }}>
            <ButtonRow alignment="left">
                <ActionButton
                    color="green"
                    icon="plus"
                    onClick={() => setShowCreateModal(!showCreateModal)}
                    disabled={disableCreate}
                >
                    Create {name}
                </ActionButton>
                {ImportModal && (
                    <ActionButton
                        color="blue"
                        icon="clipboard"
                        onClick={() => setShowImportModal(!showImportModal)}
                        disabled={disableImport}
                    >
                        Import {name}
                    </ActionButton>
                )}
                {UpdateModal && (
                    <ActionButton color="grey" onClick={() => setShowUpdateModal(!showUpdateModal)}>
                        Edit
                    </ActionButton>
                )}
                {DeleteModal && (
                    <ActionButton color="red" onClick={() => setShowDeleteModal(!showDeleteModal)}>
                        Delete
                    </ActionButton>
                )}
            </ButtonRow>

            {showCreateModal && (
                <CreateModal
                    id={item.id}
                    item={item}
                    closeModal={() => setShowCreateModal(false)}
                />
            )}
            {showUpdateModal && UpdateModal && (
                <UpdateModal item={item} closeModal={() => setShowUpdateModal(false)} />
            )}
            {showDeleteModal && DeleteModal && (
                <DeleteModal item={item} closeModal={() => setShowDeleteModal(false)} />
            )}
            {showImportModal && ImportModal && (
                <ImportModal item={item} closeModal={() => setShowImportModal(false)} />
            )}
        </div>
    );
};

interface Props {
    name: string;
    CreateModal: (props: any) => JSX.Element;
    UpdateModal?: (props: any) => JSX.Element;
    DeleteModal?: (props: any) => JSX.Element;
    ImportModal?: (props: any) => JSX.Element;
    item: Record<number | string, any>;
    disableCreate?: boolean;
    disableImport?: boolean;
}

export default ModalActionButtons;
