import React from 'react';

import useUpdateDimensionQuestion from '@pages/dimensionQuestions/hooks/useUpdateDimensionQuestion';

import { DimensionQuestion } from '../../../types/shared/Dimensions';
import {
    dimensionQuestionTypeOptions,
    DimensionQuestionTypes,
} from '../../../types/shared/Companies';

import Modal from 'lib/src/components/modal/Modal';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import Select from 'lib/src/components/form/Select';
import DatePicker from 'lib/src/components/form/DatePicker';
import Form from 'lib/src/components/form/Form';
import Title from 'lib/src/components/typography/Title';
import NumberInput from 'lib/src/components/form/NumberInput';

const UpdateDimensionQuestionModal = ({ question, closeModal }: Props) => {
    const {
        form: { text, answerType, startDate, endDate, textInputScore },
        handleChange,
        handleSubmit,
        isPosting,
    } = useUpdateDimensionQuestion(question, closeModal);

    return (
        <Modal closeModal={closeModal} extraClasses="show-overflow">
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal}>
                <FormRow>
                    <TextInput
                        name="text"
                        value={text}
                        onChange={handleChange}
                        label="Question text"
                        placeholder="Enter question text"
                        required
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="answerType"
                        value={answerType}
                        onChange={handleChange}
                        label="Question type"
                        options={dimensionQuestionTypeOptions}
                        required
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        name="startDate"
                        value={!!startDate ? new Date(startDate) : null}
                        onChange={handleChange}
                        label="Start date"
                        placeholder="Select start date"
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        name="endDate"
                        value={!!endDate ? new Date(endDate) : null}
                        onChange={handleChange}
                        label="End date"
                        customValidate={() => {
                            if (!!endDate && !!startDate && endDate < startDate) {
                                return 'End date must greater than start date';
                            }
                        }}
                        placeholder="Select end date"
                    />
                </FormRow>

                {answerType === DimensionQuestionTypes.TextInput && (
                    <>
                        <Title>Score</Title>
                        <FormRow>
                            <NumberInput
                                name="textInputScore"
                                value={textInputScore ?? 0}
                                onChange={handleChange}
                                label="Text Input Score"
                                required
                            />
                        </FormRow>
                    </>
                )}
            </Form>
        </Modal>
    );
};

interface Props {
    question: DimensionQuestion;
    closeModal: () => void;
}

export default UpdateDimensionQuestionModal;
