import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';

import {
    selectDimensionSubcategoriesError,
    selectDimensionSubcategoriesIsPosting,
    selectDimensionSubcategoriesPostSuccess,
} from '@selectors/dimensionSubcategories';
import { createDimensionSubcategory } from '@actions/dimensionSubcategories/createDimensionSubcategory';
import { DimensionSubcategoryRequest } from '../../../types/shared/Dimensions';
import { selectDimension } from '@selectors/dimensions';
import { RootState } from '@reducers/index';

const useCreateDimensionSubcategory = (id: number, closeModal: () => void) => {
    const dispatch = useDispatch();

    const { isScoring } = useSelector((state: RootState) => selectDimension(state, id));

    const postSuccess = useSelector(selectDimensionSubcategoriesPostSuccess);
    const isPosting = useSelector(selectDimensionSubcategoriesIsPosting);
    const error = useSelector(selectDimensionSubcategoriesError);
    const prevPostSuccess = usePrevious(postSuccess);

    const [form, handleChange] = useForm<DimensionSubcategoryRequest>({
        name: '',
        title: '',
        strengthDescription: '',
        difficultyDescription: '',
        highScoreBoundary: 0,
        mediumScoreBoundary: 0,
    });

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    const handleSubmit = () => {
        const postBody = {
            ...form,
            strengthDescription: isScoring ? form.strengthDescription : null,
            difficultyDescription: isScoring ? form.difficultyDescription : null,
            highScoreBoundary: isScoring ? form.highScoreBoundary : null,
            mediumScoreBoundary: isScoring ? form.mediumScoreBoundary : null,
        };
        dispatch(createDimensionSubcategory(id, postBody));
    };

    return { form, handleChange, handleSubmit, isScoring, isPosting, error };
};

export default useCreateDimensionSubcategory;
