import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../../redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { CompanyQuestion } from '../../../types/shared/Companies';
import { APIError } from 'lib/src/types/APIError';

export const fetchCompanyQuestionRequest = createAction('fetchCompanyQuestionRequest');
export const fetchCompanyQuestionSuccess = createAction<CompanyQuestion>(
    'fetchCompanyQuestionSuccess',
);
export const fetchCompanyQuestionFailure = createAction('fetchCompanyQuestionFailure');

export const fetchCompanyQuestion = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchCompanyQuestionRequest());

    try {
        const { data }: FetchCompanyQuestionResponse = await api.get(`onboarding/questions/${id}`);

        dispatch(fetchCompanyQuestionSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchCompanyQuestionFailure, e as APIError);
    }
};

interface FetchCompanyQuestionResponse {
    data: CompanyQuestion;
}
