import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { CustomSection } from 'src/types/shared/Companies';
import { APIError } from 'lib/src/types/APIError';

export const fetchAllCompanyQuestionsRequest = createAction('fetchAllCompanyQuestionsRequest');
export const fetchAllCompanyQuestionsSuccess = createAction<CustomSection[]>(
    'fetchAllCompanyQuestionsSuccess',
);
export const fetchAllCompanyQuestionsFailure = createAction('fetchAllCompanyQuestionsFailure');

export const fetchAllCompanyQuestions = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchAllCompanyQuestionsRequest());

    try {
        const { data }: FetchQuestionsResponse = await api.get(`company/${id}/onboarding`);

        dispatch(fetchAllCompanyQuestionsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchAllCompanyQuestionsFailure, e as APIError);
    }
};

interface FetchQuestionsResponse {
    data: CustomSection[];
}
