import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const deleteCompanyQuestionRequest = createAction('deleteCompanyQuestionRequest');
export const deleteCompanyQuestionSuccess = createAction<Payload>('deleteCompanyQuestionSuccess');
export const deleteCompanyQuestionFailure = createAction('deleteCompanyQuestionFailure');

export const deleteCompanyQuestion =
    (id: number, sectionID: number) => async (dispatch: AppDispatch) => {
        dispatch(deleteCompanyQuestionRequest());

        try {
            await api.delete(`onboarding/questions/${id}`);

            dispatch(deleteCompanyQuestionSuccess({ id, sectionID }));
        } catch (e) {
            handleApiErrors(dispatch, deleteCompanyQuestionFailure, e as APIError);
        }
    };

interface Payload {
    id: number;
    sectionID: number;
}
