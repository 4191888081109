import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { Company } from 'src/types/shared/Companies';
import { APIError } from 'lib/src/types/APIError';

export const updateCompanyRequest = createAction('updateCompanyRequest');
export const updateCompanySuccess = createAction<Company>('updateCompanySuccess');
export const updateCompanyFailure = createAction('updateCompanyFailure');

export const updateCompany = (id: number, postBody: PostBody) => async (dispatch: AppDispatch) => {
    dispatch(updateCompanyRequest());

    try {
        const { data }: UpdateCompanyResponse = await api.patch(`company/${id}`, postBody);

        dispatch(updateCompanySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, updateCompanyFailure, e as APIError);
    }
};

interface PostBody {
    companyReference: string;
    startDate: Date | null;
    endDate: Date | null;
    isEnabled: boolean;
}

interface UpdateCompanyResponse {
    data: Company;
}
