import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { DimensionAnswer } from 'src/types/shared/Dimensions';
import { convertArrToObj } from 'lib/src/utils/generic';

export const patchSortDimensionAnswersRequest = createAction('patchSortDimensionAnswersRequest');
export const patchSortDimensionAnswersSuccess = createAction<Record<number, DimensionAnswer>>(
    'patchSortDimensionAnswersSuccess',
);
export const patchSortDimensionAnswersFailure = createAction('patchSortDimensionAnswersFailure');

export const patchSortDimensionAnswers =
    (questionID: number, answers: DimensionAnswer[]) => async (dispatch: AppDispatch) => {
        dispatch(patchSortDimensionAnswersRequest());

        const sorts = answers
            .sort((a, b) => a.sort - b.sort)
            .map((a, i) => ({ id: a.id, sort: i + 1 }));

        const postBody: PostBody = { sorts };

        try {
            const { data } = await api.patch<PostBody, DimensionAnswer[]>(
                `dimensions/questions/${questionID}/answers/sort`,
                postBody,
            );

            dispatch(patchSortDimensionAnswersSuccess(convertArrToObj(data)));
        } catch (e) {
            handleApiErrors(dispatch, patchSortDimensionAnswersFailure, e as APIError);
        }
    };

interface Sort {
    id: number;
    sort: number;
}
interface PostBody {
    sorts: Sort[];
}
