import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Company } from 'src/types/shared/Companies';

export const fetchAllCompaniesRequest = createAction('fetchAllCompanies');
export const fetchAllCompaniesSuccess = createAction<Company[]>('fetchAllCompaniesSuccess');
export const fetchAllCompaniesFailure = createAction('fetchAllCompaniesFailure');

export const fetchAllCompanies =
    (noModule?: boolean) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchAllCompaniesRequest());

        const findAllWithoutModule = noModule || false;

        try {
            const { data }: Response = await api.get(`company?noModule=${findAllWithoutModule}`);

            dispatch(fetchAllCompaniesSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchAllCompaniesFailure, e as APIError);
        }
    };

interface Response {
    data: Company[];
}
