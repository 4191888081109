import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';

import { fetchCompany } from '@actions/companies';
import { fetchSingleAccessCode } from '@actions/accessCodes';

import { getAccessCodeModules } from '@selectors/modules';
import {
    getAccessCode,
    getAccessCodesError,
    getAccessCodesIsFetching,
} from '@selectors/accessCodes';
import { getCompany } from '@selectors/companies';
import { RootState } from '@reducers/index';

import AccessCode from '@pages/accessCodes/AccessCode';
import Loading from '@components/layout/loading/Loading';
import { Module } from '../../types/shared/Modules';
import { patchSortAccessCodeModules } from '@actions/accessCodes/patchSortAccessCodeModules';

const AccessCodeContainer = () => {
    const dispatch = useDispatch();

    const { companyID, codeID } = useParams<{
        companyID: string;
        codeID: string;
    }>();

    useEffect(() => {
        batch(() => {
            dispatch(fetchCompany(+companyID));
            dispatch(fetchSingleAccessCode(+codeID));
        });
    }, [dispatch, codeID, companyID]);

    const accessCode = useSelector((state: RootState) => getAccessCode(state, +codeID));
    const company = useSelector((state: RootState) => getCompany(state, +companyID));
    const modules = useSelector(getAccessCodeModules);
    const isFetching = useSelector(getAccessCodesIsFetching);
    const error = useSelector(getAccessCodesError);

    const sortAction = (modules: Module[]) => {
        dispatch(patchSortAccessCodeModules(+codeID, modules));
    };

    if (!accessCode || !company) return <Loading />;

    return (
        <AccessCode
            accessCode={accessCode}
            company={company}
            modules={Object.values(modules)}
            isFetching={isFetching}
            error={error}
            sortAction={sortAction}
        />
    );
};

export default AccessCodeContainer;
