import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAllModules } from '@actions/modules/fetchAllModules';

const useFetchModules = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllModules());
    }, [dispatch]);
};

export default useFetchModules;
