import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from 'src/redux/store';
import { DimensionQuestion } from '../../../types/shared/Dimensions';

import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const fetchDimensionQuestionsRequest = createAction('fetchDimensionQuestionsRequest');
export const fetchDimensionQuestionsSuccess = createAction<DimensionQuestion[]>(
    'fetchDimensionQuestionsSuccess',
);
export const fetchDimensionQuestionsFailure = createAction('fetchDimensionQuestionsFailure');

export const fetchDimensionQuestions =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchDimensionQuestionsRequest());

        try {
            const { data }: Response = await api.get(`dimensions/subcategories/${id}/questions`);

            dispatch(fetchDimensionQuestionsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchDimensionQuestionsFailure, e as APIError);
        }
    };

interface Response {
    data: DimensionQuestion[];
}
