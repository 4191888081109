import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAllDimensions } from '@actions/dimensions/fetchAllDimensions';

const useFetchAllDimensions = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllDimensions());
    }, [dispatch]);
};

export default useFetchAllDimensions;
