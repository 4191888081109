export function isEmpty(item: any): boolean {
    if (Array.isArray(item)) return !item.length;
    if (typeof item === 'string') return !item.trim().length;
    if (item instanceof Date) return isNaN(item.valueOf());
    if (typeof item === 'object') return isObjEmpty(item);
    if (typeof item === 'number') return false;

    return !item;
}

function isObjEmpty(obj: Record<string, unknown>): boolean {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

export function convertArrToObj<T>(arr: T[], field: string = 'id'): Record<string, T> {
    return arr.reduce<Record<string, T>>((acc, item) => {
        // todo
        //@ts-ignore
        acc[item[field]] = item;
        return acc;
    }, {});
}

export function updateObj(origObj: any, key: string | number, newItem: any) {
    return {
        ...origObj,
        [key]: newItem,
    };
}

export function removeObjItem(obj: any, key: number | string) {
    const {
        [key]: removedItem, // eslint-disable-line
        ...rest
    } = obj;

    return rest;
}

export function convertPascalCaseToSentanceCase(string: string) {
    return string.replace(/([A-Z])/g, ' $1');
}

export const areArraysEqual = (a: any[], b: any[]): boolean => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
};

export function getFileName(src: any) {
    return src.match('[^/]*$')[0];
}

export function moveItemInArray<T>(arr: T[], fromIndex: number, toIndex: number): T[] {
    const arrCopy = [...arr];
    const element = arrCopy[fromIndex];
    arrCopy.splice(fromIndex, 1);
    arrCopy.splice(toIndex, 0, element);
    return arrCopy;
}

export const stripHtml = (html: string) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
};

// Remove trailing breaklines
export const sanitizeWysiwyg = (str: string): string => str.replaceAll(/<p><br><\/p>(<p><br><\/p>*)+/g, '<p><br></p>');
