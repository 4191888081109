import { RootState } from '@reducers/index';

export const selectDimensionsIsFetching = (state: RootState) => state.dimensionsReducer.isFetching;
export const selectDimensions = (state: RootState) => state.dimensionsReducer.dimensions;
export const selectDimension = (state: RootState, id: number) =>
    state.dimensionsReducer.dimensions[id];

export const selectDimensionsError = (state: RootState) => state.dimensionsReducer.error;

export const selectDimensionsIsPosting = (state: RootState) => state.dimensionsReducer.isPosting;
export const selectDimensionPostSuccess = (state: RootState) => state.dimensionsReducer.postSuccess;
