import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAllModuleDimensions } from '@actions/dimensions/fetchAllModuleDimensions';

const useFetchAllModuleDimensions = (id: number | null): void => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!id) return;

        dispatch(fetchAllModuleDimensions(id));
    }, [dispatch, id]);
};

export default useFetchAllModuleDimensions;
