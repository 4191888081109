import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useEffect } from 'react';

import { deleteDimensionSubcategory } from '@actions/dimensionSubcategories/deleteDimensionSubcategory';

import {
    selectDimensionSubcategoriesError,
    selectDimensionSubcategoriesIsPosting,
    selectDimensionSubcategoriesPostSuccess,
} from '@selectors/dimensionSubcategories';

const useDeleteDimensionSubcategory = (subcategoryId: number, closeModal: () => void) => {
    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(deleteDimensionSubcategory(subcategoryId));
    };

    const isPosting = useSelector(selectDimensionSubcategoriesIsPosting);
    const error = useSelector(selectDimensionSubcategoriesError);
    const postSuccess = useSelector(selectDimensionSubcategoriesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [closeModal, postSuccess, prevPostSuccess]);

    return { handleDelete, isPosting, error };
};

export default useDeleteDimensionSubcategory;
