import useForm from 'lib/src/hooks/useForm';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';
import { createAccessCode } from '@actions/accessCodes';
import {
    getAccessCodesError,
    getAccessCodesIsPosting,
    getAccessCodesPostSuccess,
} from '@selectors/accessCodes';

const initialFormData: FormState = {
    accessCode: '',
    maxUses: 0,
    startDate: null,
    endDate: null,
};

const useCreateAccessCode = (companyID: number, closeModal: () => void) => {
    const dispatch = useDispatch();
    const isPosting = useSelector(getAccessCodesIsPosting);
    const postSuccess = useSelector(getAccessCodesPostSuccess);
    const error = useSelector(getAccessCodesError);
    const prevPostSuccess = usePrevious(postSuccess);

    const [form, handleChange] = useForm(initialFormData);

    const handleSubmit = () => {
        const postBody = { ...form, maxUses: form.maxUses || null };
        dispatch(createAccessCode(companyID, postBody));
    };

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        form,
        handleChange,
        closeModal,
        handleSubmit,
        isPosting,
        error,
    };
};

interface FormState {
    accessCode: string;
    maxUses: number;
    startDate: Date | null;
    endDate: Date | null;
}

export default useCreateAccessCode;
