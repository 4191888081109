import { DropdownOption } from 'lib/src/types/shared/DropdownOption';
import { AccessCode } from './AccessCode';
import { Module } from './Modules';

export interface Company {
    id: number;
    companyReference: string;
    accessCodes: AccessCode[];
    startDate: Date | null;
    endDate: Date | null;
    isEnabled: boolean;
    couldImproveMessage: string | null;
    submissionCount: number;
    createdOn: string;
    updatedOn: string | null;
    imageS3Key: string | null;
    supportServiceLogoS3Key: string | null;
    supportLink: string;
    modules: Module[];
    hideDimensionWheel: boolean;
    languageCode: string;
}

export interface CustomSection {
    id: number;
    companyID: number;
    sort: number;
    name: string;
    description: string;
    imageS3Key: string | null;
    questions: CompanyQuestion[] | [];
}
export interface CustomSectionPost {
    companyID: number;
    sort: number;
    name: string;
    description: string;
    imageS3Key: string | null;
}

export interface CompanyQuestion {
    id: number;
    createdByUserID: number;
    sectionID: number;
    createdOn: Date;
    updatedOn: Date | null;
    companyID: number;
    isRequired: boolean;
    questionText: string;
    sort: number;
    answers: QuestionAnswer[] | [];
    type: number;
    sliderOptions: SliderOptions | null;
}

export interface SliderOptions {
    minValue: number;
    maxValue: number;
    increment: number;
} // All integers

export interface QuestionAnswer {
    id: number;
    questionID: number;
    companyID: number;
    answerText: string;
    sort: number;
    sectionID: number;
}

export interface QuestionAnswerPostBody {
    answerText: string;
    sort?: number;
}

export enum QuestionTypes {
    Dropdown = 1,
    TextInput = 2,
    Radio = 3,
    Slider = 4,
    Checkbox = 5,
    MultiSelect = 6,
}

export const questionTypeOptions = [
    { value: QuestionTypes.Dropdown, label: 'Dropdown' },
    { value: QuestionTypes.TextInput, label: 'Text Input' },
    { value: QuestionTypes.Radio, label: 'Radio' },
    { value: QuestionTypes.Slider, label: 'Slider' },
    { value: QuestionTypes.Checkbox, label: 'Checkbox' },
    { value: QuestionTypes.MultiSelect, label: 'Multi-Select' },
];

export enum DimensionQuestionTypes {
    Dropdown = 1,
    Radio = 2,
    Slider = 3,
    TextInput = 4,
    MultiSelect = 5,
}

export const dimensionQuestionTypeOptions = [
    { value: DimensionQuestionTypes.Dropdown, label: 'Dropdown' },
    { value: DimensionQuestionTypes.Radio, label: 'Radio' },
    { value: DimensionQuestionTypes.Slider, label: 'Slider' },
    { value: DimensionQuestionTypes.TextInput, label: 'Text Input' },
    { value: DimensionQuestionTypes.MultiSelect, label: 'Multi-Select' },
];

export const defaultSliderOptions: SliderOptions = {
    minValue: 0,
    maxValue: 10,
    increment: 1,
};

export const numbers = new RegExp('/[0-9]/', 'g');

export enum importingQuestionOptions {
    Import = 1,
    Create = 2,
}

export const importingQuestionDropdownOptions: DropdownOption<number>[] = [
    { value: importingQuestionOptions.Import, label: 'Import from another company' },
    { value: importingQuestionOptions.Create, label: 'Create from scratch' },
];

export const importingDimensionQuestionDropdownOptions: DropdownOption<number>[] = [
    { value: importingQuestionOptions.Import, label: 'Import from another dimension' },
    { value: importingQuestionOptions.Create, label: 'Create from scratch' },
];
