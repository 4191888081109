import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { Dimension } from '../../../types/shared/Dimensions';

export const copySubcategoryToDimensionRequest = createAction('copySubcategoryToDimensionRequest');
export const copySubcategoryToDimensionSuccess = createAction<Dimension>(
    'copySubcategoryToDimensionSuccess',
);
export const copySubcategoryToDimensionFailure = createAction('copySubcategoryToDimensionFailure');

export const copySubcategoryToDimension =
    (id: number, selectedSubcategoryID: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(copySubcategoryToDimensionRequest());

        try {
            const { data }: Response = await api.post(
                `dimensions/${id}/subcategories/copy?sourceSubcategoryID=${selectedSubcategoryID}`,
                {},
            );

            dispatch(copySubcategoryToDimensionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, copySubcategoryToDimensionFailure, e as APIError);
        }
    };

interface Response {
    data: Dimension;
}
