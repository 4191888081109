import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';
import { deleteAccessCode } from '@actions/accessCodes';
import {
    getAccessCodesError,
    getAccessCodesIsPosting,
    getAccessCodesPostSuccess,
} from '@selectors/accessCodes';

const useDeleteAccessCode = (id: number, closeModal: () => void) => {
    const dispatch = useDispatch();

    const handleDeleteAccessCode = useCallback(() => {
        dispatch(deleteAccessCode(id));
    }, [dispatch, id]);

    const isPosting = useSelector(getAccessCodesIsPosting);
    const error = useSelector(getAccessCodesError);
    const postSuccess = useSelector(getAccessCodesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return { handleDeleteAccessCode, isPosting, error, closeModal };
};

export default useDeleteAccessCode;
