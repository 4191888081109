import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/store';
import { useDispatch } from 'react-redux';

import { fetchCompany } from '@actions/companies/fetchCompany';
import { getCompany } from '@selectors/companies';

import Company from './Company';
import { fetchCompanyOnboardingSections } from '@actions/companyQuestions/fetchCompanyOnboardingSections';

const CompanyContainer = () => {
    const dispatch = useDispatch();

    const { companyID } = useParams<{
        companyID: string;
        questionID: string;
    }>();

    useEffect(() => {
        dispatch(fetchCompany(+companyID));
        dispatch(fetchCompanyOnboardingSections(+companyID));
    }, [companyID, dispatch]);

    const company = useAppSelector(state => getCompany(state, +companyID));

    if (!company) return null;

    return <Company company={company} />;
};

export default CompanyContainer;
