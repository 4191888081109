import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { CompanyQuestion, QuestionAnswerPostBody } from 'src/types/shared/Companies';
import { APIError } from 'lib/src/types/APIError';

export const createCompanyQuestionRequest = createAction('createCompanyQuestionRequest');
export const createCompanyQuestionSuccess = createAction<CompanyQuestion>(
    'createCompanyQuestionSuccess',
);
export const createCompanyQuestionFailure = createAction('createCompanyQuestionFailure');

export const createCompanyQuestion =
    (sectionID: number, postBody: PostBody) => async (dispatch: AppDispatch) => {
        dispatch(createCompanyQuestionRequest());

        try {
            const { data }: CreateQuestionReponse = await api.post(
                `onboarding/sections/${sectionID}/questions`,
                postBody,
            );

            dispatch(createCompanyQuestionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createCompanyQuestionFailure, e as APIError);
        }
    };

interface PostBody {
    companyID: number;
    questionText: string;
    isRequired: boolean;
    answers: QuestionAnswerPostBody[];
}

interface CreateQuestionReponse {
    data: CompanyQuestion;
}
