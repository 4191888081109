import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { CompanyQuestion } from 'src/types/shared/Companies';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const fetchCompanyOnboardingSectionsRequest = createAction(
    'fetchCompanyOnboardingSectionsRequest',
);
export const fetchCompanyOnboardingSectionsSuccess = createAction<CompanyQuestion[]>(
    'fetchCompanyOnboardingSectionsSuccess',
);
export const fetchImportSectionsSuccess = createAction<CompanyQuestion[]>(
    'fetchImportSectionsSuccess',
);
export const fetchCompanyOnboardingSectionsFailure = createAction(
    'fetchCompanyOnboardingSectionsFailure',
);

export const fetchCompanyOnboardingSections =
    (id: number, isImporting?: boolean) => async (dispatch: AppDispatch) => {
        dispatch(fetchCompanyOnboardingSectionsRequest());

        try {
            const { data }: FetchQuestionsResponse = await api.get(
                `company/${id}/onboarding/sections`,
            );

            if (isImporting) dispatch(fetchImportSectionsSuccess(data));
            else dispatch(fetchCompanyOnboardingSectionsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchCompanyOnboardingSectionsFailure, e as APIError);
        }
    };

interface FetchQuestionsResponse {
    data: CompanyQuestion[];
}
