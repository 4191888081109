import React from 'react';

import useSingleDimension from '@pages/dimensions/single/hooks/useSingleDimension';

import Title from 'lib/src/components/typography/Title';
import Loading from '@components/layout/loading/Loading';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ModalActionButtons from '@components/actions/ModalActionButtons';

import CreateDimensionSubcategoryModal from '@pages/dimensionSubcategories/modals/CreateDimensionSubcategoryModal';
import UpdateDimensionModal from '@pages/dimensions/modals/UpdateDimensionModal';
import DeleteModuleDimensionModal from '@pages/dimensions/single/DeleteDimensionModal';

import DimensionSubcategoriesTable from '@pages/dimensionSubcategories/DimensionSubcategoriesTable';

import config from '../../../config';
import ImportSubcategoriesModal from '@pages/dimensionSubcategories/modals/ImportSubcategoriesModal';
const { S3_URL } = config;

const Dimension = () => {
    const { dimension } = useSingleDimension();

    if (!dimension) return <Loading />;

    const {
        id,
        name,
        imageS3Key,
        lowScoreDetails,
        mediumScoreBoundary,
        mediumScoreDetails,
        highScoreBoundary,
        highScoreDetails,
        isActive,
        isScoring,
    } = dimension;

    return (
        <>
            <Title>Dimension - {name}</Title>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Name">
                        <p>{name}</p>
                    </ContentItem>
                    <ContentItem label="Is Dimension Active">
                        <p>{isActive ? 'Yes' : 'No'}</p>
                    </ContentItem>
                    <ContentItem label=""></ContentItem>
                </ContentRow>

                {isScoring && (
                    <>
                        <ContentRow>
                            <ContentItem label="High Score Boundary">
                                <p>{highScoreBoundary}</p>
                            </ContentItem>
                            <ContentItem label="Medium Score Boundary">
                                <p>{mediumScoreBoundary}</p>
                            </ContentItem>
                            <ContentItem label=""></ContentItem>
                        </ContentRow>

                        <ContentRow>
                            <ContentItem label="High Score Details">
                                <h4>Action</h4>
                                <p>{highScoreDetails.action}</p>
                                <h4>Summary</h4>
                                <p>{highScoreDetails.summary}</p>
                                <h4>Breakdown</h4>
                                <p>{highScoreDetails.breakdown}</p>
                            </ContentItem>

                            <ContentItem label="Medium Score Details">
                                <h4>Action</h4>
                                <p>{mediumScoreDetails.action}</p>
                                <h4>Summary</h4>
                                <p>{mediumScoreDetails.summary}</p>
                                <h4>Breakdown</h4>
                                <p>{mediumScoreDetails.breakdown}</p>
                            </ContentItem>

                            <ContentItem label="Low Score Details">
                                <h4>Action</h4>
                                <p>{lowScoreDetails.action}</p>
                                <h4>Summary</h4>
                                <p>{lowScoreDetails.summary}</p>
                                <h4>Breakdown</h4>
                                <p>{lowScoreDetails.breakdown}</p>
                            </ContentItem>
                        </ContentRow>
                    </>
                )}

                <ContentRow>
                    <ContentItem label="Image">
                        {imageS3Key && (
                            <img
                                src={`${S3_URL}/${imageS3Key}`}
                                className="logo"
                                alt={'section logo'}
                            />
                        )}
                    </ContentItem>
                </ContentRow>
            </ContentBlock>

            <ModalActionButtons
                name="Subcategory"
                item={dimension}
                CreateModal={CreateDimensionSubcategoryModal}
                UpdateModal={UpdateDimensionModal}
                DeleteModal={DeleteModuleDimensionModal}
                ImportModal={ImportSubcategoriesModal}
            />
            <div style={{ minHeight: '2em' }} />

            <Title>Subcategories</Title>

            <DimensionSubcategoriesTable dimensionID={id} />
        </>
    );
};

export default Dimension;
