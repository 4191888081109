import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';
import {
    getCompaniesError,
    getCompaniesIsPosting,
    getCompaniesPostSuccess,
} from '@selectors/companies';
import { Company } from 'src/types/shared/Companies';
import { Module } from 'src/types/shared/Modules';
import useFetchModules from '@pages/modules/hooks/useFetchModules';
import { getAllModules } from '@selectors/modules';
import { createAssociation } from '@actions/companies/createAssociation';
import { setFieldErrors } from 'lib/src/redux/actions/fieldErrors';

const useCreateCompanyAssociation = (company: Company, closeModal: () => void) => {
    const dispatch = useDispatch();

    const [selectedModuleID, setSelectedModuleID] = useState<number | null>(null);

    const handleChange = (name: string, id: number | null) => {
        setSelectedModuleID(id);
    };

    dispatch(useFetchModules);
    const modules = Object.values(useSelector(getAllModules) ?? []);

    const handleSubmit = () => {
        if (selectedModuleID) {
            dispatch(createAssociation({ companyID: company.id, moduleID: selectedModuleID }));
            closeModal();
        } else {
            dispatch(setFieldErrors({ selectedCompanyID: 'Please select a module' }));
        }
    };

    const isPosting = useSelector(getCompaniesIsPosting);
    const error = useSelector(getCompaniesError);
    const postSuccess = useSelector(getCompaniesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    const moduleOptions = useMemo(() => {
        return modules
            ?.filter(
                (module: Module) =>
                    !company?.modules?.some((otherModule: Module) => module.id === otherModule.id),
            )
            ?.map((module: Module) => ({
                value: module.id,
                label: module.name,
            }));
    }, [company.modules, modules]);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        moduleOptions,
        module,
        selectedModuleID,
        handleSubmit,
        handleChange,
        isPosting,
        error,
    };
};

export default useCreateCompanyAssociation;
