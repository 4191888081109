import useUpdateModule from './hooks/useUpdateModule';
import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import TextInput from 'lib/src/components/form/TextInput';
import FormRow from 'lib/src/components/form/FormRow';
import DatePicker from 'lib/src/components/form/DatePicker';
import ToggleSwitch from 'lib/src/components/form/ToggleSwitch';
import { Module } from '../../../types/shared/Modules';

const UpdateModuleModal = ({ item, closeModal }: UpdateModuleModalProps) => {
    const {
        form: { name, startDate, endDate, isActive },
        handleSubmit,
        handleChange,
        isPosting,
        error,
        modules,
    } = useUpdateModule(item ?? {}, closeModal);

    return (
        <Modal title={`Update module - ${name}`} closeModal={closeModal} size="medium">
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting} error={error}>
                <FormRow>
                    <TextInput
                        name="name"
                        onChange={handleChange}
                        label="Name"
                        value={name}
                        customValidate={() => {
                            if (modules.some(x => x.name === name && x.id !== item.id)) {
                                return 'Module name already exists';
                            }
                        }}
                        required
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        name="startDate"
                        value={startDate && new Date(startDate)}
                        onChange={handleChange}
                        label="Start date"
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        name="endDate"
                        value={endDate && new Date(endDate)}
                        onChange={handleChange}
                        label="End date"
                    />
                </FormRow>
                <br />
                <FormRow>
                    <ToggleSwitch
                        name="isActive"
                        value={isActive}
                        onChange={handleChange}
                        label="Is Module Active?"
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface UpdateModuleModalProps {
    item: Module;
    closeModal: () => void;
}

export default UpdateModuleModal;
