import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { DimensionQuestion } from 'src/types/shared/Dimensions';
import { convertArrToObj } from 'lib/src/utils/generic';

export const patchSortDimensionQuestionsRequest = createAction(
    'patchSortDimensionQuestionsRequest',
);
export const patchSortDimensionQuestionsSuccess = createAction<Record<number, DimensionQuestion>>(
    'patchSortDimensionQuestionsSuccess',
);
export const patchSortDimensionQuestionsFailure = createAction(
    'patchSortDimensionQuestionsFailure',
);

export const patchSortDimensionQuestions =
    (categoryID: number, questions: DimensionQuestion[]) => async (dispatch: AppDispatch) => {
        dispatch(patchSortDimensionQuestionsRequest());

        const sorts = questions
            .sort((a, b) => a.sort - b.sort)
            .map((a, i) => ({ id: a.id, sort: i + 1 }));
        const postBody: PostBody = { sorts };

        try {
            const { data } = await api.patch<PostBody, DimensionQuestion[]>(
                `dimensions/subcategories/${categoryID}/questions/sort`,
                postBody,
            );

            dispatch(patchSortDimensionQuestionsSuccess(convertArrToObj(data)));
        } catch (e) {
            handleApiErrors(dispatch, patchSortDimensionQuestionsFailure, e as APIError);
        }
    };

interface Sort {
    id: number;
    sort: number;
}
interface PostBody {
    sorts: Sort[];
}
