import useCreateAssociation from '@pages/accessCodes/hooks/useCreateAssociation';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import Select from 'lib/src/components/form/Select';
import { AccessCode } from '../../../types/shared/AccessCode';

const CreateAccessCodeAssociation = ({ item, closeModal }: IModalProps) => {
    const { handleSubmit, handleChange, isPosting, moduleOptions, selectedModuleID } =
        useCreateAssociation(item, closeModal);

    return (
        <Modal
            title="Create Association with module"
            closeModal={closeModal}
            size="medium"
            style={{ overflow: 'visible' }}
        >
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting}>
                <FormRow>
                    <Select
                        search
                        options={moduleOptions}
                        name="selectedModuleID"
                        value={selectedModuleID}
                        onChange={handleChange}
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface IModalProps {
    item: AccessCode;
    closeModal: () => void;
}

export default CreateAccessCodeAssociation;
