import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import usePrevious from 'lib/src/hooks/usePrevious';

import dayjs from 'dayjs';

import Table from 'lib/src/components/table/Table';
import { Column } from 'lib/src/types/table';
import { Module } from 'src/types/shared/Modules';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import ActionButton from 'lib/src/components/button/ActionButton';
import ConfirmModal from 'lib/src/components/modal/ConfirmModal';

import { copyModule } from '@actions/modules';
import {
    getModulesError,
    getModulesIsFetching,
    getModulesIsPosting,
    getModulesPostSuccess,
} from '@selectors/modules';
import { moveItemInArray } from 'lib/src/utils/generic';

const ModulesTable = ({
    modules,
    isFetching: isFetchingProp,
    showSort,
    error: errorProp,
    sortAction,
    hideDuplicate,
}: ModulesTableProps) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getModulesIsFetching);
    const isPosting = useSelector(getModulesIsPosting);
    const error = useSelector(getModulesError);
    const postSuccess = useSelector(getModulesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [moduleToCopy, setModuleToCopy] = useState<number | null>(null);

    const handleDuplicateModule = (id: number) => {
        setModuleToCopy(id);
        setShowConfirmModal(true);
    };

    const duplicateModule = () => {
        if (moduleToCopy) {
            dispatch(copyModule(moduleToCopy));
        }
    };

    const handleSort = (moduleID: number, direction: 'up' | 'down') => {
        const currentSorted = Object.values(modules).sort((a, b) => a.sort - b.sort);
        const sortAdjustment = direction === 'up' ? -1 : 1;

        const indexToMove = currentSorted.reduce((acc: number | null, curr: Module) => {
            if (acc !== null) return acc;
            if (curr.id === moduleID) return currentSorted.indexOf(curr);
            return acc;
        }, null);

        if (indexToMove === null) return;

        const newModules: Module[] = moveItemInArray(
            currentSorted,
            indexToMove,
            indexToMove + sortAdjustment,
        ).map((s, i) => ({ ...s, sort: i }));
        !!sortAction && sortAction(newModules);
    };

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            setModuleToCopy(null);
            setShowConfirmModal(false);
        }
    }, [postSuccess, prevPostSuccess]);

    const columns: Column<Module>[] = [
        {
            key: 1,
            heading: '',
            getValue: (row, i, rows) => (
                <ButtonRow alignment="left">
                    {i > 0 && (
                        <ActionButton
                            className="mini-button"
                            type="button"
                            onClick={() => handleSort(row.id, 'up')}
                        >
                            ↑
                        </ActionButton>
                    )}
                    {i < rows.length - 1 && (
                        <ActionButton
                            className="mini-button"
                            type="button"
                            onClick={() => handleSort(row.id, 'down')}
                        >
                            ↓
                        </ActionButton>
                    )}
                </ButtonRow>
            ),
        },
        {
            key: 2,
            heading: 'Name',
            getValue: row => row.name,
            searchable: true,
        },
        {
            key: 3,
            heading: 'Start date',
            getValue: row =>
                row.startDate ? dayjs(row.startDate).format('DD-MM-YYYY') : 'No start date',
        },
        {
            key: 4,
            heading: 'End date',
            getValue: row =>
                row.endDate ? dayjs(row.endDate).format('DD-MM-YYYY') : 'No end date',
        },
        {
            key: 5,
            heading: 'Is Active?',
            getValue: row => (row.isActive ? 'Yes' : 'No'),
            getSort: (a, b) => {
                if (a.isActive > b.isActive) return +1;
                if (a.isActive < b.isActive) return -1;
                return 0;
            },
        },
        {
            key: 6,
            heading: 'Is External Scoring?',
            getValue: row => `${row.isScoring ? 'Yes' : 'No'}`,
        },
        {
            key: 7,
            heading: '',
            getValue: row => (
                <ButtonRow alignment="right">
                    {!hideDuplicate && (
                        <ActionButton onClick={() => handleDuplicateModule(row.id)} color="grey">
                            Duplicate
                        </ActionButton>
                    )}
                    <LinkButton href={`/modules/${row.id}`}>View</LinkButton>
                </ButtonRow>
            ),
        },
    ];

    return (
        <>
            <Table
                isLoading={isFetching || isFetchingProp}
                columns={columns.filter((c: Column<Module>) => (!showSort ? c.key !== 1 : true))}
                rows={Object.values(modules).sort((a, b) => a.sort - b.sort)}
                error={error || errorProp}
            />

            {showConfirmModal && (
                <ConfirmModal
                    title="Duplicate Module"
                    description="Are you sure you want to duplicate this module?"
                    handleSubmit={() => duplicateModule()}
                    closeModal={() => setShowConfirmModal(false)}
                    isPosting={isPosting}
                />
            )}
        </>
    );
};

interface ModulesTableProps {
    modules: Record<number, Module>;
    isFetching?: boolean;
    showSort?: boolean;
    error?: string | null;
    sortAction?: (modules: Module[]) => void;
    hideDuplicate?: boolean;
}

export default ModulesTable;
