import { useDispatch, useSelector } from 'react-redux';
import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useCallback, useEffect } from 'react';

import {
    getCompanyQuestionPostSuccess,
    getCompanyQuestionsError,
    getCompanyQuestionsIsPosting,
} from '@selectors/companyQuestions';

import { CustomSection } from '../../../types/shared/Companies';
import { updateCompanySection } from '@actions/companyQuestions/updateCompanySection';

const useUpdateCompanySection = (
    { id, name, description, imageS3Key }: CustomSection,
    closeModal: () => void,
) => {
    const dispatch = useDispatch();

    const initialFormData: FormData = {
        name,
        description,
        imageS3Key: imageS3Key ? [imageS3Key] : [],
    };

    const [form, handleChange] = useForm(initialFormData);

    const handleSubmit = useCallback(() => {
        const formattedFormData: RequestBody = {
            ...form,
            imageS3Key: form.imageS3Key[0] || null,
        };
        dispatch(updateCompanySection(id, formattedFormData));
    }, [dispatch, id, form]);

    const isPosting = useSelector(getCompanyQuestionsIsPosting);
    const postSuccess = useSelector(getCompanyQuestionPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);
    const error = useSelector(getCompanyQuestionsError);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return { form, handleChange, handleSubmit, isPosting, error };
}; // WIP

interface FormData {
    name: string;
    description: string;
    imageS3Key: string[];
}

interface RequestBody {
    name: string;
    description: string;
    imageS3Key: string | null;
}

export default useUpdateCompanySection;
