import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Login from '@pages/auth/login/Login';

const AuthRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}/login`}>
                <Login />
            </Route>
            <Route exact path={`${path}/register`}>
                <p>register</p>
            </Route>
        </Switch>
    );
};

export default AuthRoutes;
