import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
    fetchAllModuleDimensionsFailure,
    fetchAllModuleDimensionsRequest,
    fetchAllModuleDimensionsSuccess,
} from '@actions/dimensions/fetchAllModuleDimensions';
import { convertArrToObj } from 'lib/src/utils/generic';
import { Dimension } from '../../types/shared/Dimensions';
import {
    deleteDimensionFailure,
    deleteDimensionRequest,
    deleteDimensionSuccess,
} from '@actions/dimensions/deleteDimension';
import {
    createDimensionFailure,
    createDimensionRequest,
    createDimensionSuccess,
} from '@actions/dimensions/createDimension';
import {
    fetchSingleDimensionRequest,
    fetchSingleDimensionSuccess,
    fetchSingleDimensionFailure,
} from '@actions/dimensions/fetchSingleDimension';
import {
    postCopyDimensionsFailure,
    postCopyDimensionsRequest,
    postCopyDimensionsSuccess,
} from '@actions/dimensions/postCopyDimensions';
import {
    updateDimensionFailure,
    updateDimensionRequest,
    updateDimensionSuccess,
} from '@actions/dimensions/updateDimension';

import {
    patchSortDimensionsFailure,
    patchSortDimensionsRequest,
    patchSortDimensionsSuccess,
} from '@actions/dimensions/patchSortDimensions';
import {
    fetchAllDimensionsFailure,
    fetchAllDimensionsRequest,
    fetchAllDimensionsSuccess,
} from '@actions/dimensions/fetchAllDimensions';

interface DimensionsState {
    postSuccess: boolean;
    isPosting: boolean;
    isFetching: boolean;
    dimensions: Record<number, Dimension>;
    error: string | null;
}

const initialState: DimensionsState = {
    isFetching: false,
    dimensions: {},
    error: null,
    postSuccess: false,
    isPosting: false,
};

export default createReducer(initialState, {
    [fetchAllModuleDimensionsRequest.type]: handleFetchRequest,
    [fetchAllModuleDimensionsSuccess.type]: handleFetchSuccess,
    [fetchAllModuleDimensionsFailure.type]: handleFailure,
    [deleteDimensionRequest.type]: handlePostRequest,
    [deleteDimensionSuccess.type]: handleDimensionSuccessDelete,
    [deleteDimensionFailure.type]: handleFailure,
    [createDimensionRequest.type]: handlePostRequest,
    [createDimensionSuccess.type]: handleDimensionSuccessCreate,
    [createDimensionFailure.type]: handleFailure,
    [fetchSingleDimensionRequest.type]: handleFetchRequest,
    [fetchSingleDimensionSuccess.type]: handleFetchSingleSuccess,
    [fetchSingleDimensionFailure.type]: handleFailure,
    [postCopyDimensionsSuccess.type]: handleDimensionSuccessCopy,
    [postCopyDimensionsRequest.type]: handlePostRequest,
    [postCopyDimensionsFailure.type]: handleFailure,
    [updateDimensionRequest.type]: handlePostRequest,
    [updateDimensionSuccess.type]: handleUpdateDimensionSuccess,
    [updateDimensionFailure.type]: handleFailure,
    [patchSortDimensionsRequest.type]: handlePostRequest,
    [patchSortDimensionsSuccess.type]: handleDimensionSuccessSort,
    [patchSortDimensionsFailure.type]: handleFailure,
    [fetchAllDimensionsRequest.type]: handleFetchRequest,
    [fetchAllDimensionsSuccess.type]: handleFetchSuccess,
    [fetchAllDimensionsFailure.type]: handleFailure,
});

function handleFetchRequest(state: DimensionsState) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchSuccess(state: DimensionsState, action: PayloadAction<Dimension[]>) {
    state.isFetching = false;
    state.dimensions = { ...convertArrToObj(action.payload), ...state.dimensions };
}

function handleFetchSingleSuccess(state: DimensionsState, action: PayloadAction<Dimension>) {
    state.isFetching = false;
    state.dimensions[action.payload.id] = action.payload;
}

function handleFailure(state: DimensionsState, error: string) {
    state.isFetching = false;
    state.isPosting = false;
    state.error = error;
}

function handlePostRequest(state: DimensionsState) {
    state.error = null;
    state.isPosting = true;
    state.postSuccess = false;
}

function handleDimensionSuccess(state: DimensionsState) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handleDimensionSuccessDelete(state: DimensionsState, action: PayloadAction<number>) {
    handleDimensionSuccess(state);
    delete state.dimensions[action.payload];
}

function handleDimensionSuccessCreate(state: DimensionsState, action: PayloadAction<Dimension>) {
    handleDimensionSuccess(state);
    state.dimensions[action.payload.id] = action.payload;
}

function handleUpdateDimensionSuccess(state: DimensionsState, action: PayloadAction<Dimension>) {
    handleDimensionSuccess(state);
    state.dimensions[action.payload.id] = action.payload;
}

function handleDimensionSuccessCopy(state: DimensionsState, action: PayloadAction<Dimension>) {
    handleDimensionSuccess(state);
    state.dimensions[action.payload.id] = action.payload;
}

function handleDimensionSuccessSort(
    state: DimensionsState,
    action: PayloadAction<Record<number | string, Dimension>>,
) {
    handleDimensionSuccess(state);
    state.dimensions = action.payload;
}
