import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAllQuestionAnswers } from '@actions/companyQuestions/answers/fetchAllQuestionAnswers';

const useFetchAnswers = (id: number) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllQuestionAnswers(id));
    }, [dispatch, id]);
};

export default useFetchAnswers;
