import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { QuestionAnswer } from 'src/types/shared/Companies';
import { APIError } from 'lib/src/types/APIError';

export const createAnswerRequest = createAction('createAnswerRequest');
export const createAnswerSuccess = createAction<QuestionAnswer>('createAnswerSuccess');
export const createAnswerFailure = createAction('createAnswerFailure');

export const createAnswer =
    (questionID: number, postBody: { answerText: string }) => async (dispatch: AppDispatch) => {
        dispatch(createAnswerRequest());

        try {
            const { data }: CreateAnswerResponse = await api.post(
                `onboarding/questions/${questionID}/answers`,
                postBody,
            );

            dispatch(createAnswerSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createAnswerFailure, e as APIError);
        }
    };

interface CreateAnswerResponse {
    data: QuestionAnswer;
}
