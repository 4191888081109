import { sanitizeWysiwyg } from 'lib/src/utils/generic';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';

import {
    getCompaniesError,
    getCompaniesIsPosting,
    getCompaniesPostSuccess,
} from '@selectors/companies';

import { Company } from 'src/types/shared/Companies';
import { updateCompany } from '@actions/companies/updateCompany';

const useUpdateCompany = (
    {
        id,
        companyReference,
        startDate,
        endDate,
        isEnabled,
        couldImproveMessage,
        imageS3Key,
        supportServiceLogoS3Key,
        supportLink,
        hideDimensionWheel,
        languageCode,
    }: Company,
    closeModal: () => void,
) => {
    const dispatch = useDispatch();

    const [form, handleChange, resetData] = useForm<FormData>({
        companyReference,
        startDate,
        endDate,
        isEnabled,
        couldImproveMessage: couldImproveMessage || '',
        imageS3Key: imageS3Key ? [imageS3Key] : [],
        supportServiceLogoS3Key: supportServiceLogoS3Key ? [supportServiceLogoS3Key] : [],
        supportLink,
        hideDimensionWheel,
        languageCode,
    });

    // I have no idea why they added this?!?
    // React Quill seems to handle paste fine without come kind of paste listener
    // useEffect(() => {
    //     window.addEventListener('paste', (e: Event) => {
    //         if (!(e instanceof ClipboardEvent)) return;
    //         e.preventDefault();
    //         console.log({
    //             status: 'crappy paste listerner',
    //             text: e.clipboardData?.getData('text/plain') ?? '',
    //         });
    //         const text = e.clipboardData?.getData('text/plain') ?? '';
    //         document.execCommand('insertHTML', false, text);
    //     });
    // }, []);

    useEffect(
        () => {
            const newData = {
                companyReference,
                startDate,
                endDate,
                isEnabled,
                imageS3Key: imageS3Key ? [imageS3Key] : [],
                supportServiceLogoS3Key: supportServiceLogoS3Key ? [supportServiceLogoS3Key] : [],
                supportLink,
                hideDimensionWheel,
                languageCode,
            };
            resetData({ ...newData, couldImproveMessage: couldImproveMessage || '' });
        },
        //eslint-disable-next-line
        [companyReference, startDate, endDate, isEnabled, imageS3Key],
    );

    const handleSubmit = useCallback(() => {
        let imageS3Key: string | null = null;
        let supportServiceLogoS3Key: string | null = null;

        if (Array.isArray(form.imageS3Key) && form.imageS3Key.length > 0) {
            imageS3Key = form.imageS3Key[0] as string;
        }
        if (
            Array.isArray(form.supportServiceLogoS3Key) &&
            form.supportServiceLogoS3Key.length > 0
        ) {
            supportServiceLogoS3Key = form.supportServiceLogoS3Key[0] as string;
        }

        dispatch(
            updateCompany(id, {
                ...form,
                imageS3Key,
                supportServiceLogoS3Key,
                couldImproveMessage: sanitizeWysiwyg(form.couldImproveMessage),
            } as FormattedFormData),
        );
    }, [dispatch, id, form]);

    const isPosting = useSelector(getCompaniesIsPosting);
    const error = useSelector(getCompaniesError);
    const postSuccess = useSelector(getCompaniesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        form,
        handleSubmit,
        handleChange,
        isPosting,
        error,
    };
};

interface FormData {
    companyReference: string;
    startDate: Date | null;
    endDate: Date | null;
    isEnabled: boolean;
    couldImproveMessage: string;
    imageS3Key: string[];
    supportServiceLogoS3Key: string[];
    supportLink: string;
    hideDimensionWheel: boolean;
    languageCode: string;
}

interface FormattedFormData {
    companyReference: string;
    startDate: Date | null;
    endDate: Date | null;
    isEnabled: boolean;
    imageS3Key: string;
    couldImproveMessage: string;
}

export default useUpdateCompany;
