import useDeleteDimension from '@pages/dimensions/single/hooks/useDeleteDimension';
import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import { Dimension } from 'src/types/shared/Dimensions';

const DeleteModuleDimensionModal = ({ item, closeModal }: DeleteModalProps) => {
    const { handleDeleteModuleDimension, isPosting, error } = useDeleteDimension(item, closeModal);
    return (
        <ConfirmModal
            title={`Delete Dimension: '${item.name}'`}
            description={`Are you sure you want to delete this dimension?`}
            closeModal={closeModal}
            handleSubmit={handleDeleteModuleDimension}
            isPosting={isPosting}
            error={error}
        />
    );
};

interface DeleteModalProps {
    item: Dimension;
    closeModal: () => void;
}
export default DeleteModuleDimensionModal;
