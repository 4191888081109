import useForm from 'lib/src/hooks/useForm';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';

import { selectDimensionQuestionPostSuccess } from '@selectors/dimensionQuestions';

import { createDimensionQuestion } from '@actions/dimensionQuestions/createDimensionQuestion';

import { v1 } from 'uuid';
import { removeObjItem, updateObj } from 'lib/src/utils/generic';
import dayjs from 'dayjs';

import { dateFormat } from '../../../constants/formats';
import { DimensionQuestionTypes } from '../../../types/shared/Companies';

const initialFormData: FormState = {
    text: '',
    answerType: 2,
    startDate: null,
    endDate: null,
    textInputScore: null,
};

const initialAnswerForm: AnswerState = {};

const useCreateDimensionQuestion = (dimensionID: number, closeModal: () => void) => {
    const dispatch = useDispatch();

    const [form, handleChange] = useForm({ ...initialFormData });
    const [answersObject, setAnswersObject] = useState(initialAnswerForm);

    const postSuccess = useSelector(selectDimensionQuestionPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    useEffect(() => {
        handleChange('textInputScore', 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.answerType]);

    const addAnswerObj = () => {
        const id = v1();
        const updated = updateObj(answersObject, id, { id: id, text: '', score: 0 });

        setAnswersObject(updated);
    };

    const updateAnswer = (id: string, key: string, value: string | number) => {
        const answer = answersObject[id];
        const updated = updateObj(answersObject, id, { ...answer, [key]: value });

        setAnswersObject(updated);
    };

    const removeAnswer = (id: string) => {
        const updated = removeObjItem(answersObject, id);

        setAnswersObject(updated);
    };

    const handleSubmit = () => {
        const answersArray = Object.values(answersObject).reduce(
            (acc: any[], answer: { id: string; text: string | null; score: number }) => {
                const { id, ...rest } = answer;

                return [...acc, rest];
            },
            [],
        );

        const formattedStartDate = form.startDate ? dayjs(form.startDate).format(dateFormat) : null;
        const formattedEndDate = form.endDate ? dayjs(form.endDate).format(dateFormat) : null;

        const postBody = {
            ...form,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            answers: form.answerType !== DimensionQuestionTypes.TextInput ? answersArray : [],
        };

        dispatch(createDimensionQuestion(dimensionID, postBody));
    };

    return {
        form,
        handleChange,
        handleSubmit,
        answersObject,
        addAnswerObj,
        updateAnswer,
        removeAnswer,
    };
};

interface FormState {
    text: string;
    answerType: DimensionQuestionTypes;
    startDate: Date | null;
    endDate: Date | null;
    textInputScore: number | null;
}

interface AnswerState {
    [key: string]: { id: string; text: string | null; score: number };
}

export default useCreateDimensionQuestion;
