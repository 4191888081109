import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';

import {
    selectDimensionSubcategoriesError,
    selectDimensionSubcategoriesIsPosting,
    selectDimensionSubcategoriesPostSuccess,
} from '@selectors/dimensionSubcategories';
import {
    DimensionSubcategory,
    DimensionSubcategoryRequest,
} from '../../../types/shared/Dimensions';
import { updateDimensionSubcategory } from '@actions/dimensionSubcategories';

const useUpdateDimensionSubcategory = (
    subcategory: DimensionSubcategory,
    closeModal: () => void,
) => {
    const dispatch = useDispatch();
    const {
        name,
        title,
        strengthDescription,
        difficultyDescription,
        highScoreBoundary,
        mediumScoreBoundary,
        isScoring,
    } = subcategory;

    const postSuccess = useSelector(selectDimensionSubcategoriesPostSuccess);
    const isPosting = useSelector(selectDimensionSubcategoriesIsPosting);
    const error = useSelector(selectDimensionSubcategoriesError);
    const prevPostSuccess = usePrevious(postSuccess);

    const [form, handleChange] = useForm<DimensionSubcategoryRequest>({
        name,
        title,
        strengthDescription,
        difficultyDescription,
        highScoreBoundary,
        mediumScoreBoundary,
    });

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    const handleSubmit = () => {
        const postBody = {
            ...form,
            strengthDescription: isScoring ? form.strengthDescription : null,
            difficultyDescription: isScoring ? form.difficultyDescription : null,
            highScoreBoundary: isScoring ? form.highScoreBoundary : null,
            mediumScoreBoundary: isScoring ? form.mediumScoreBoundary : null,
        };
        dispatch(updateDimensionSubcategory(subcategory.id, postBody));
    };

    return { form, handleChange, handleSubmit, isScoring, isPosting, error };
};

export default useUpdateDimensionSubcategory;
