import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';

import {
    getCompanyQuestionPostSuccess,
    getCompanyQuestionsError,
    getCompanyQuestionsIsPosting,
} from '@selectors/companyQuestions';

import { QuestionAnswer } from 'src/types/shared/Companies';
import { updateAnswer } from '@actions/companyQuestions/answers/updateAnswer';

const useUpdateAnswer = (answer: QuestionAnswer, closeModal: () => void) => {
    const dispatch = useDispatch();

    const [form, handleChange] = useForm({ answerText: answer?.answerText });

    const handleSubmit = useCallback(() => {
        dispatch(updateAnswer(answer.id, form));
    }, [dispatch, answer?.id, form]);

    const isPosting = useSelector(getCompanyQuestionsIsPosting);
    const postSuccess = useSelector(getCompanyQuestionPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);
    const error = useSelector(getCompanyQuestionsError);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return { form, handleChange, handleSubmit, isPosting, error };
};

export default useUpdateAnswer;
