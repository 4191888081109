import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { APIError } from 'lib/src/types/APIError';

export const deleteDimensionSubcategoryRequest = createAction('deleteDimensionSubcategoryRequest');
export const deleteDimensionSubcategorySuccess = createAction<number>(
    'deleteDimensionSubcategorySuccess',
);
export const deleteDimensionSubcategoryFailure = createAction('deleteDimensionSubcategoryFailure');

export const deleteDimensionSubcategory =
    (subcategoryID: number) => async (dispatch: AppDispatch) => {
        dispatch(deleteDimensionSubcategoryRequest());
        try {
            await api.delete(`dimensions/subcategories/${subcategoryID}`);
            dispatch(deleteDimensionSubcategorySuccess(subcategoryID));
        } catch (e) {
            handleApiErrors(dispatch, deleteDimensionSubcategoryFailure, e as APIError);
        }
    };
