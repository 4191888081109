import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Modules from '@pages/modules/Modules';
import ModuleContainer from '@pages/modules/single/ModuleContainer';
import Dimension from '@pages/dimensions/single/Dimension';

const ModulesRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <Modules />
            </Route>
            <Route exact path={`${path}/create`}>
                <Modules />
            </Route>
            <Route exact path={`${path}/:moduleID`}>
                <ModuleContainer />
            </Route>
            <Route exact path={`${path}/:moduleID/dimensions/:dimensionID`}>
                <Dimension />
            </Route>
            <Route exact path={`${path}/:moduleID/dimensions/:dimensionID/questions/:questionID`}>
                <Dimension />
            </Route>
        </Switch>
    );
};

export default ModulesRoutes;
