import { useHistory } from 'react-router-dom';
import useDeleteCompanyQuestion from './hooks/useDeleteCompanyQuestion';

import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import { CompanyQuestion } from 'src/types/shared/Companies';

const DeleteCompanyQuestionModal = ({ question, closeModal }: DeleteModalProps) => {
    const history = useHistory();
    const { handleDeleteCompanyQuestion, isPosting, error } = useDeleteCompanyQuestion(
        question,
        closeModal,
    );

    return (
        <ConfirmModal
            title="Delete custom question"
            description={`Are you sure you want to delete this question?`}
            closeModal={() => history.replace(`/companies/${question.companyID}`)}
            handleSubmit={handleDeleteCompanyQuestion}
            isPosting={isPosting}
            error={error}
        />
    );
};

interface DeleteModalProps {
    question: CompanyQuestion;
    closeModal: () => void;
}
export default DeleteCompanyQuestionModal;
