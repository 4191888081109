import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import Select from 'lib/src/components/form/Select';
import useCreateModuleAssociation from './hooks/useCreateModuleAssociation';
import { Module } from 'src/types/shared/Modules';
const CreateModuleAssociationModal = ({ item, closeModal }: ModalProps) => {
    const { handleSubmit, handleChange, isPosting, companyOptions, selectedCompanyID } =
        useCreateModuleAssociation(item, closeModal);

    return (
        <Modal
            title={`Create Association with company`}
            closeModal={closeModal}
            size="medium"
            style={{ overflow: 'visible' }}
        >
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting}>
                <FormRow>
                    <Select
                        search
                        options={Object.values(companyOptions)}
                        name="selectedCompanyID"
                        value={selectedCompanyID}
                        onChange={handleChange}
                    />
                </FormRow>

                <FormRow></FormRow>
            </Form>
        </Modal>
    );
};

interface ModalProps {
    item: Module;
    closeModal: () => void;
}

export default CreateModuleAssociationModal;
