import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';

import { APIError } from 'lib/src/types/APIError';
import { DimensionAnswer } from '../../../../types/shared/Dimensions';

export const fetchQuestionAnswersRequest = createAction('fetchQuestionAnswersRequest');
export const fetchQuestionAnswersSuccess = createAction<DimensionAnswer[]>(
    'fetchQuestionAnswersSuccess',
);
export const fetchQuestionAnswersFailure = createAction('fetchQuestionAnswersFailure');

export const fetchQuestionAnswers = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchQuestionAnswersRequest());
    try {
        const { data }: Response = await api.get(`dimensions/questions/${id}/answers`);

        dispatch(fetchQuestionAnswersSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchQuestionAnswersFailure, e as APIError);
    }
};

interface Response {
    data: DimensionAnswer[];
}
