import useCreateCompanyQuestion from '../hooks/useCreateCompanyQuestion';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import NumberInput from 'lib/src/components/form/NumberInput';
import Title from 'lib/src/components/typography/Title';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import {
    QuestionTypes,
    questionTypeOptions,
    importingQuestionOptions,
} from '../../../types/shared/Companies';
import Select from 'lib/src/components/form/Select';
import Loading from '@components/layout/loading/Loading';
import ToggleSwitch from 'lib/src/components/form/ToggleSwitch';

const CreateCompanyQuestionModal = ({
    companyID,
    sectionID,
    setSectionToCreateQuestion,
}: CreateModalProps) => {
    const {
        form: {
            questionText,
            isRequired,
            type,
            sliderOptions,
            importingQuestion,
            companyToImportFrom,
            questionToImport,
        },
        handleChange,
        closeModal,
        handleSubmit,
        isPosting,
        error,
        answersObject,
        importingQuestionDropdownOptions,
        companyToImportDropdownOptions,
        isFetchingCompanies,
        questionsDropdownOptions,
        addAnswerObj,
        updateAnswer,
        removeAnswer,
    } = useCreateCompanyQuestion(companyID, sectionID, setSectionToCreateQuestion);

    const showAnswers =
        importingQuestion === importingQuestionOptions.Create &&
        (type === QuestionTypes.MultiSelect ||
            type === QuestionTypes.Dropdown ||
            type === QuestionTypes.Radio ||
            type === QuestionTypes.Checkbox);

    return (
        <Modal closeModal={closeModal} title="Create custom question" extraClasses="show-overflow">
            {isFetchingCompanies ? (
                <Loading />
            ) : (
                <Form
                    onSubmit={handleSubmit}
                    isPosting={isPosting}
                    onCancel={closeModal}
                    error={error}
                >
                    <FormRow>
                        <Select
                            name="importingQuestion"
                            value={importingQuestion}
                            onChange={handleChange}
                            label="Import/Create Question?"
                            options={importingQuestionDropdownOptions}
                        />
                    </FormRow>
                    {importingQuestion === importingQuestionOptions.Import && (
                        <>
                            <FormRow>
                                <Select
                                    name="companyToImportFrom"
                                    value={companyToImportFrom}
                                    onChange={handleChange}
                                    label="Select Company to import from"
                                    options={companyToImportDropdownOptions}
                                    search
                                />
                            </FormRow>
                        </>
                    )}
                    {importingQuestion === importingQuestionOptions.Import &&
                        companyToImportFrom && (
                            <FormRow>
                                <Select
                                    name="questionToImport"
                                    value={questionToImport}
                                    onChange={handleChange}
                                    label="Select question to import"
                                    options={questionsDropdownOptions}
                                    search
                                />
                            </FormRow>
                        )}
                    {importingQuestion === importingQuestionOptions.Create && (
                        <>
                            <FormRow>
                                <TextInput
                                    name="questionText"
                                    value={questionText}
                                    onChange={handleChange}
                                    label="Question text"
                                    required
                                />
                            </FormRow>
                            <FormRow>
                                <Select
                                    name="type"
                                    value={type}
                                    onChange={handleChange}
                                    label="Question type"
                                    options={questionTypeOptions}
                                />
                            </FormRow>
                            <FormRow>
                                <ToggleSwitch
                                    name="isRequired"
                                    value={isRequired}
                                    onChange={handleChange}
                                    label="Is required"
                                />
                            </FormRow>
                        </>
                    )}
                    {showAnswers && (
                        <>
                            <Title>Answers</Title>
                            <div className="scrollable small">
                                {Object.values(answersObject).map(
                                    (answer: { id: string; answerText: string; sort?: number }) => (
                                        <FormRow>
                                            <TextInput
                                                name="answerText"
                                                value={answer.answerText}
                                                onChange={(_, value) =>
                                                    updateAnswer(answer.id, value)
                                                }
                                                label="Answer text"
                                            />
                                            <button
                                                className="icon-button delete"
                                                onClick={() => removeAnswer(answer.id)}
                                                type="button"
                                            >
                                                <i className="fal fa-trash-alt"></i>
                                            </button>
                                        </FormRow>
                                    ),
                                )}
                            </div>
                            <ButtonRow alignment="left">
                                <ActionButton
                                    type="button"
                                    onClick={() => addAnswerObj()}
                                    color="green"
                                    icon="plus"
                                >
                                    Add answer
                                </ActionButton>
                            </ButtonRow>
                        </>
                    )}
                    {importingQuestion === importingQuestionOptions.Create &&
                        type === QuestionTypes.Slider && (
                            <>
                                <Title>Slider Options</Title>
                                <FormRow>
                                    <NumberInput
                                        name="minValue"
                                        value={sliderOptions.minValue}
                                        onChange={(_, value) => {
                                            handleChange('sliderOptions', {
                                                ...sliderOptions,
                                                minValue: value,
                                            });
                                        }}
                                        label="Min value"
                                        maxNumber={sliderOptions.maxValue}
                                    />
                                </FormRow>
                                <FormRow>
                                    <NumberInput
                                        name="maxValue"
                                        value={sliderOptions.maxValue}
                                        onChange={(_, value) => {
                                            handleChange('sliderOptions', {
                                                ...sliderOptions,
                                                maxValue: value,
                                            });
                                        }}
                                        label="Max value"
                                        minNumber={sliderOptions.minValue}
                                    />
                                </FormRow>
                                <FormRow>
                                    <NumberInput
                                        name="increment"
                                        value={sliderOptions.increment}
                                        onChange={(_, value) => {
                                            handleChange('sliderOptions', {
                                                ...sliderOptions,
                                                increment: value,
                                            });
                                        }}
                                        label="Increment"
                                    />
                                </FormRow>
                            </>
                        )}
                </Form>
            )}
        </Modal>
    );
};

interface CreateModalProps {
    companyID: number;
    sectionID: number;
    setSectionToCreateQuestion: React.Dispatch<React.SetStateAction<number | null>>;
}
export default CreateCompanyQuestionModal;
