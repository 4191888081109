import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCompanyQuestionsIsFetching } from '@selectors/companyQuestions';
import { postSortCompanyQuestions } from '@actions/companyQuestions/postSortCompanyQuestions';

import { Column } from 'lib/src/types/table';
import { CompanyQuestion, CustomSection, QuestionTypes } from '../../types/shared/Companies';

import Table from 'lib/src/components/table/Table';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import ActionButton from 'lib/src/components/button/ActionButton';
import { convertPascalCaseToSentanceCase, moveItemInArray } from 'lib/src/utils/generic';

const CompanyQuestionsTable = ({
    section: { questions, id: sectionID },
}: CompanyQuestionTableProps) => {
    const isFetching = useSelector(getCompanyQuestionsIsFetching);
    const dispatch = useDispatch();
    const doesRowHaveAnswers = (row: CompanyQuestion) => {
        if (row.type === QuestionTypes.TextInput || row.type === QuestionTypes.Slider) return false;

        return !!(row.answers?.length > 0);
    };

    const handleSort = (questionID: number, direction: 'up' | 'down') => {
        const currentSorted = Object.values(questions).sort((a, b) => a.sort - b.sort);
        const sortAdjustment = direction === 'up' ? -1 : 1;

        const indexToMove = currentSorted.reduce((acc: number | null, curr: CompanyQuestion) => {
            if (acc !== null) return acc;
            if (curr.id === questionID) return currentSorted.indexOf(curr);
            return acc;
        }, null);

        if (indexToMove === null) return;

        const newQuestions: CompanyQuestion[] = moveItemInArray(
            currentSorted,
            indexToMove,
            indexToMove + sortAdjustment,
        ).map((s, i) => ({ ...s, sort: i }));
        dispatch(postSortCompanyQuestions(sectionID, newQuestions));
    };

    const columns: Column<CompanyQuestion>[] = [
        {
            key: 1,
            heading: '',
            getValue: (row, i, rows) => (
                <ButtonRow alignment="left">
                    {i > 0 && (
                        <ActionButton
                            className="mini-button"
                            type="button"
                            onClick={() => handleSort(row.id, 'up')}
                        >
                            ↑
                        </ActionButton>
                    )}
                    {i < rows.length - 1 && (
                        <ActionButton
                            className="mini-button"
                            type="button"
                            onClick={() => handleSort(row.id, 'down')}
                        >
                            ↓
                        </ActionButton>
                    )}
                </ButtonRow>
            ),
        },
        {
            key: 2,
            heading: 'Question text',
            getValue: row => row.questionText,
        },
        {
            key: 3,
            heading: 'Is required',
            getValue: row => (row.isRequired ? 'Yes' : 'No'),
        },
        {
            key: 4,
            heading: 'Answer type',
            getValue: row => convertPascalCaseToSentanceCase(QuestionTypes[row.type]),
        },
        {
            key: 5,
            heading: 'Answer count',
            getValue: row => (doesRowHaveAnswers(row) ? `${row.answers.length}` : 'N/A'),
        },
        {
            key: 6,
            heading: '',
            getValue: row => (
                <ButtonRow alignment="right">
                    <LinkButton
                        href={`/companies/${row.companyID}/sections/${row.sectionID}/questions/${row.id}`}
                    >
                        View
                    </LinkButton>
                </ButtonRow>
            ),
        },
    ];

    const sortedQuestions = useMemo(() => {
        if (!questions) return [];
        return Object.values(questions).sort((a, b) => a.sort - b.sort);
    }, [questions]);

    return <Table isLoading={isFetching} columns={columns} rows={sortedQuestions} />;
};

interface CompanyQuestionTableProps {
    section: CustomSection;
}
export default CompanyQuestionsTable;
