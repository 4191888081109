import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFetchModules from '@pages/modules/hooks/useFetchModules';
import usePrevious from 'lib/src/hooks/usePrevious';

import { getAllModules, getModulesError, getModulesIsFetching } from '@selectors/modules';
import { setFieldErrors } from 'lib/src/redux/actions/fieldErrors';

import { postCopyDimensions } from '@actions/dimensions/postCopyDimensions';

import { Module } from '../../../types/shared/Modules';
import {
    selectDimensionPostSuccess,
    selectDimensions,
    selectDimensionsIsPosting,
} from '@selectors/dimensions';
import useFetchAllModuleDimensions from '@pages/modules/hooks/useFetchDimensions';

const useImportDimensionsFromModule = (closeModal: () => void, moduleID: number) => {
    const dispatch = useDispatch();

    const [selectedModuleID, setSelectedModuleID] = useState<number | null>(null);
    const [selectedDimensionID, setSelectedDimensionID] = useState<number | null>(null);

    useFetchModules();
    useFetchAllModuleDimensions(selectedModuleID);

    const isFetchingModules = useSelector(getModulesIsFetching);
    const modules = Object.values(useSelector(getAllModules));
    const dimensions = Object.values(useSelector(selectDimensions));
    const modulesFetchError = useSelector(getModulesError);

    const isPosting = useSelector(selectDimensionsIsPosting);
    const postSuccess = useSelector(selectDimensionPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    const moduleOptions = useMemo(() => {
        return modules
            .filter((module: Module) => module.id !== moduleID)
            .map((module: Module) => ({
                value: module.id,
                label: module.name,
            }));
    }, [moduleID, modules]);

    const dimensionOptions = useMemo(() => {
        return dimensions.reduce((acc: { value: number; label: string }[], dimension) => {
            if (dimension.moduleID === selectedModuleID) {
                acc.push({ value: dimension.id, label: dimension.name });
            }
            return acc;
        }, []);
    }, [dimensions, selectedModuleID]);

    const handleChange = (name: string, id: number | null) => {
        if (name === 'selectedModuleID') {
            return setSelectedModuleID(id);
        }
        if (name === 'selectedDimensionID') {
            return setSelectedDimensionID(id);
        }
    };

    const handleSubmit = () => {
        const addError = (errors: any, newError: any): any => ({ ...errors, ...newError });
        if (selectedModuleID && selectedDimensionID) {
            dispatch(postCopyDimensions(moduleID, selectedDimensionID));
        } else {
            let obj = {};
            if (!selectedModuleID) {
                obj = addError(obj, { selectedModuleID: 'Please select a module' });
            }
            if (!selectedDimensionID) {
                obj = addError(obj, { selectedDimensionID: 'Please select a dimension' });
            }
            return dispatch(setFieldErrors(obj));
        }
    };

    return {
        selectedModuleID,
        selectedDimensionID,
        handleChange,
        handleSubmit,
        isFetchingModules,
        modulesFetchError,
        moduleOptions,
        dimensionOptions,
        isPosting,
    };
};

export default useImportDimensionsFromModule;
