import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const deleteModuleFromAccessCodeRequest = createAction('deleteModuleFromAccessCodeRequest');
export const deleteModuleFromAccessCodeSuccess = createAction<number>(
    'deleteModuleFromAccessCodeSuccess',
);
export const deleteModuleFromAccessCodeFailure = createAction('deleteModuleFromAccessCodeFailure');

export const deleteModuleFromAccessCode =
    (id: number, moduleID: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteModuleFromAccessCodeRequest());

        try {
            await api.delete(`access-codes/${id}/modules/${moduleID}`);

            dispatch(deleteModuleFromAccessCodeSuccess(moduleID));
        } catch (e) {
            handleApiErrors(dispatch, deleteModuleFromAccessCodeFailure, e as APIError);
        }
    };
