import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { Company } from 'src/types/shared/Companies';
import { APIError } from 'lib/src/types/APIError';

export const createCompanyRequest = createAction('createCompanyRequest');
export const createCompanySuccess = createAction<Company>('createCompanySuccess');
export const createCompanyFailure = createAction('createCompanyFailure');

export const createCompany =
    (postBody: PostBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(createCompanyRequest());

        try {
            const { data }: CreateCompanyResponse = await api.post('company', postBody);

            dispatch(createCompanySuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createCompanyFailure, e as APIError);
        }
    };

interface PostBody {
    companyReference: string;
    startDate: Date | null;
    endDate: Date | null;
    isEnabled: boolean;
}

interface CreateCompanyResponse {
    data: Company;
}
