import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { Company } from 'src/types/shared/Companies';

export const createAssociationRequest = createAction('createAssociationRequest');
export const createAssociationSuccess = createAction<ExtendedResponse>('createAssociationSuccess');
export const createAssociationFailure = createAction('createAssociationFailure');

export const createAssociation =
    ({ companyID, moduleID }: PostProps) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(createAssociationRequest());

        try {
            const { data }: Response = await api.post(
                `company/${companyID}/modules/${moduleID}`,
                {},
            );
            data.companyID = companyID;
            data.moduleID = moduleID;
            dispatch(createAssociationSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createAssociationFailure, e as APIError);
        }
    };

interface PostProps {
    companyID: number;
    moduleID: number;
}

interface Response {
    data: ExtendedResponse;
}

export interface ExtendedResponse extends Company, PostProps {}
