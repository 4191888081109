import axios, { AxiosResponse } from 'axios';
import { batch } from 'react-redux';
import { Action, ActionCreator, Dispatch } from 'redux';
import { setFieldErrors } from '../redux/actions/fieldErrors';
import { addFormError } from '../redux/actions/fieldErrors';
import { setRedirectUrl } from '../redux/actions/redirects';
import { APIError } from '../types/APIError';

let token: string | null = '';

const getToken = () => {
    try {
        token = localStorage.getItem('token');
    } catch (e) {
        console.warn('Error getting token from localStorage:', e);
    }
};

const getConfig = () => {
    if (!token) return {};
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export let API_URL = '';
export const initApi = (apiUrl: string) => {
    API_URL = apiUrl;
};
const get = <TResponseBody>(url: string): Promise<AxiosResponse<TResponseBody>> => {
    getToken();
    return axios.get<TResponseBody>(`${API_URL}/${url}`, getConfig());
};
const post = <TRequestBody, TResponseBody>(url: string, data: TRequestBody) => {
    getToken();
    return axios.post<TResponseBody>(`${API_URL}/${url}`, data, getConfig());
};
const put = <TRequestBody, TResponseBody>(url: string, data: TRequestBody) => {
    getToken();
    return axios.put<TResponseBody>(`${API_URL}/${url}`, data, getConfig());
};
const patch = <TRequestBody, TResponseBody>(url: string, data: TRequestBody) => {
    getToken();
    return axios.patch<TResponseBody>(`${API_URL}/${url}`, data, getConfig());
};
const del = <TResponseBody>(url: string) => {
    getToken();
    return axios.delete<TResponseBody>(`${API_URL}/${url}`, getConfig());
};
export const api = {
    get,
    post,
    put,
    patch,
    delete: del,
};

export const handleApiErrors = <A extends Action>(
    dispatch: Dispatch,
    failureAction: ActionCreator<A>,
    err: APIError,
) => {
    const { response, message } = err;

    if (response && response.status === 400) {
        if (typeof response.data === 'string') {
            dispatch(addFormError(response.data));
            dispatch(failureAction(response.data));
        } else {
            dispatch(setFieldErrors(response.data.errors));
            dispatch(failureAction(null));
        }
    }
    if (response && response.status === 401) {
        dispatch(failureAction('Unauthorized'));
        try {
            localStorage.removeItem('token');
        } catch (e) {
            console.warn('Error removing token from localStorage:', e);
        }
        return dispatch(setRedirectUrl('/auth/login'));
    }

    return dispatch(failureAction(message));
};
