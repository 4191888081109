import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { Module } from 'src/types/shared/Modules';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const fetchModuleRequest = createAction('fetchModuleRequest');
export const fetchModuleSuccess = createAction<Module>('fetchModuleSuccess');
export const fetchModuleFailure = createAction('fetchModuleFailure');

export const fetchModule = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchModuleRequest());
    try {
        const { data }: Response = await api.get(`modules/${id}`);
        dispatch(fetchModuleSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchModuleFailure, e as APIError);
    }
};

interface Response {
    data: Module;
}
