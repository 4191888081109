const FormField: React.FC<FormFieldProps> = ({
    children,
    name,
    label,
    required,
    showRequiredAsterix,
    error,
    className,
    labelClassName,
    hideError = false,
    showErrorTop = false,
}) => (
    <div className={`form-field ${className || ''}`}>
        {!!label && (
            <label htmlFor={name} className={`form-label ${labelClassName ? labelClassName : ''}`}>
                {label}
                {required && showRequiredAsterix && <span className="form-asterisk"> *</span>}
            </label>
        )}

        {!hideError && showErrorTop && <p className="form-error">{!!error && error}</p>}

        {children}

        {!hideError && !showErrorTop && <p className="form-error">{!!error && error}</p>}
    </div>
);

interface FormFieldProps {
    name: string;
    label?: string;
    required?: boolean;
    showRequiredAsterix?: boolean;
    error?: string | null;
    className?: string;
    labelClassName?: string;
    hideError?: boolean;
    showErrorTop?: boolean;
}

export default FormField;
