import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { Module } from 'src/types/shared/Modules';

export const postCopyDimensionsRequest = createAction('postCopyDimensionsRequest');
export const postCopyDimensionsSuccess = createAction<Module>('postCopyDimensionsSuccess');
export const postCopyDimensionsFailure = createAction('postCopyDimensionsFailure');

export const postCopyDimensions =
    (destinationID: number, sourceID: number) => async (dispatch: AppDispatch) => {
        dispatch(postCopyDimensionsRequest());

        try {
            const { data }: CopyDimensionsResponse = await api.post<
                Record<string, unknown>,
                Module
            >(`modules/${destinationID}/dimensions/copy?sourceDimensionId=${sourceID}`, {});

            dispatch(postCopyDimensionsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postCopyDimensionsFailure, e as APIError);
        }
    };

interface CopyDimensionsResponse {
    data: Module;
}
