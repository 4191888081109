import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Companies from '@pages/companies/Companies';
import CompanyContainer from '@pages/companies/single/CompanyContainer';
import CompanyQuestionContainer from '@pages/companyQuestions/single/CompanyQuestionContainer';
import CompanySectionContainer from '@pages/companyQuestions/section/CompanySectionContainer';
import AccessCodeContainer from '@pages/accessCodes/AccessCodeContainer';

const CompaniesRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <Companies />
            </Route>
            <Route exact path={`${path}/create`}>
                <Companies showCreateModal />
            </Route>
            <Route exact path={`${path}/:companyID`}>
                <CompanyContainer />
            </Route>
            <Route exact path={`${path}/:companyID/sections/:sectionID`}>
                <CompanySectionContainer />
            </Route>
            <Route exact path={`${path}/:companyID/sections/:sectionID/questions/:questionID`}>
                <CompanyQuestionContainer />
            </Route>

            <Route exact path={`${path}/:companyID/access-codes/:codeID`}>
                <AccessCodeContainer />
            </Route>
        </Switch>
    );
};

export default CompaniesRoutes;
