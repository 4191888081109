import { useSelector } from 'react-redux';
import useFetchCompanies from './hooks/useFetchCompanies';

import { getAllCompanies, getCompanyCreated } from '@selectors/companies';

import Title from 'lib/src/components/typography/Title';
import LinkButton from 'lib/src/components/button/LinkButton';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import CompaniesTable from './CompaniesTable';
import CreateCompanyModal from './CreateCompanyModal';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Companies: React.FC<CompaniesProps> = ({ showCreateModal }) => {
    const history = useHistory();

    useFetchCompanies();
    const companies = useSelector(getAllCompanies);

    const companyCreated = useSelector(getCompanyCreated);
    const prevCompanyCreated = usePrevious(companyCreated);

    useEffect(() => {
        if (companyCreated && !prevCompanyCreated) history.push(`/companies/${companyCreated}`);
    }, [companyCreated, prevCompanyCreated, history]);

    return (
        <>
            <CreateHeader>
                <Title>Companies</Title>
                <LinkButton color="green" icon="plus" href="/companies/create">
                    Create
                </LinkButton>
            </CreateHeader>

            <CompaniesTable companies={companies} />

            {showCreateModal && <CreateCompanyModal />}
        </>
    );
};

interface CompaniesProps {
    showCreateModal?: boolean;
}
export default Companies;
