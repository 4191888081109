import * as React from 'react';

const CreateHeader: React.FC<CreateHeaderProps> = ({ children, marginTop = false }) => (
    <div className={`create-header ${marginTop && 'margin-top'}`}>{children}</div>
);

interface CreateHeaderProps {
    marginTop?: boolean;
}

export default CreateHeader;
