import { RootState } from '@reducers/index';

export const selectDimensionSubcategoriesIsFetching = (state: RootState) =>
    state.dimensionSubcategoriesReducer.isFetching;
export const selectDimensionSubcategoriesError = (state: RootState) =>
    state.dimensionSubcategoriesReducer.error;
export const selectDimensionSubcategories = (state: RootState) =>
    state.dimensionSubcategoriesReducer.subcategories;
export const selectDimensionSubcategory = (state: RootState, id: number) =>
    state.dimensionSubcategoriesReducer.subcategories[id];
export const selectDimensionSubcategoriesIsPosting = (state: RootState) =>
    state.dimensionSubcategoriesReducer.isPosting;
export const selectDimensionSubcategoriesPostSuccess = (state: RootState) =>
    state.dimensionSubcategoriesReducer.postSuccess;
