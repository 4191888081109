import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const deleteDimensionRequest = createAction('deleteDimensionRequest');
export const deleteDimensionSuccess = createAction<number>('deleteDimensionSuccess');
export const deleteDimensionFailure = createAction('deleteDimensionFailure');

export const deleteDimension =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteDimensionRequest());

        try {
            await api.delete(`dimensions/${id}`);
            dispatch(deleteDimensionSuccess(id));
        } catch (e) {
            handleApiErrors(dispatch, deleteDimensionFailure, e as APIError);
        }
    };
