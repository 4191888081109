import Title from 'lib/src/components/typography/Title';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import {
    CompanyQuestion as CompanyQuestionModel,
    QuestionTypes,
} from '../../../types/shared/Companies';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import QuestionAnswers from '../answers/QuestionAnswers';
import { convertPascalCaseToSentanceCase } from 'lib/src/utils/generic';
import { useSelector } from 'react-redux';
import { getCompanySection } from '@selectors/companyQuestions';
import { RootState } from '@reducers/index';
import ActionButton from 'lib/src/components/button/ActionButton';
import { useState } from 'react';
import UpdateCompanyQuestionModal from '../modals/UpdateCompanyQuestionModal';
import DeleteCompanyQuestionModal from './DeleteCompanyQuestionModal';

const CompanyQuestion = ({ question }: CompanyQuestionProps) => {
    const { id, questionText, isRequired, type, sectionID, sliderOptions } = question;
    const section = useSelector((state: RootState) => getCompanySection(state, sectionID));

    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    return (
        <>
            <Title>Custom question{!!section ? ` from ${section.name}` : ''}</Title>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Question text">
                        <p>{questionText}</p>
                    </ContentItem>
                    <ContentItem label="Answer type">
                        <p>{convertPascalCaseToSentanceCase(QuestionTypes[type])}</p>
                    </ContentItem>
                    <ContentItem label="Is required">
                        <p>{isRequired ? 'Yes' : 'No'}</p>
                    </ContentItem>
                </ContentRow>
                {type === QuestionTypes.Slider && !!sliderOptions && (
                    <ContentRow>
                        <ContentItem label="Min value">
                            <p>{sliderOptions.minValue}</p>
                        </ContentItem>
                        <ContentItem label="Max value">
                            <p>{sliderOptions.maxValue}</p>
                        </ContentItem>
                        <ContentItem label="Increment">
                            <p>{sliderOptions.increment}</p>
                        </ContentItem>
                    </ContentRow>
                )}
            </ContentBlock>

            <ButtonRow alignment="left">
                <ActionButton color="grey" icon="pencil" onClick={() => setShowUpdateModal(true)}>
                    Edit
                </ActionButton>
                <ActionButton color="red" icon="trash-alt" onClick={() => setShowDeleteModal(true)}>
                    Delete
                </ActionButton>
            </ButtonRow>
            {type !== QuestionTypes.TextInput && type !== QuestionTypes.Slider && (
                <QuestionAnswers questionID={+id} sectionID={+sectionID} />
            )}
            {showUpdateModal && (
                <UpdateCompanyQuestionModal
                    question={question}
                    closeModal={() => setShowUpdateModal(false)}
                />
            )}
            {showDeleteModal && (
                <DeleteCompanyQuestionModal
                    question={question}
                    closeModal={() => setShowDeleteModal(false)}
                />
            )}
        </>
    );
};

interface CompanyQuestionProps {
    question: CompanyQuestionModel;
}

export default CompanyQuestion;
