import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import useDeleteAnswer from './hooks/useDeleteAnswer';
import { useAppSelector } from '../../../redux/store';
import { getSingleAnswer } from '@selectors/companyQuestions';
import { CompanyQuestion } from 'src/types/shared/Companies';

const DeleteAnswerModal = ({ answerID, question, closeModal }: DeleteAnswerModalProps) => {
    const answer = useAppSelector(state => getSingleAnswer(state, answerID));

    const { handleDeleteAnswer, isPosting, error } = useDeleteAnswer(answer, question, closeModal);

    return (
        <ConfirmModal
            title="Delete answer"
            description={`Are you sure you want to delete this answer?`}
            closeModal={closeModal}
            handleSubmit={handleDeleteAnswer}
            isPosting={isPosting}
            error={error}
        />
    );
};

interface DeleteAnswerModalProps {
    answerID: number;
    question: CompanyQuestion;
    closeModal: () => void;
}

export default DeleteAnswerModal;
