import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';
// import { useHistory } from 'react-router-dom';

import {
    getCompanyQuestionPostSuccess,
    getCompanyQuestionsError,
    getCompanyQuestionsIsPosting,
} from '@selectors/companyQuestions';

import { CompanyQuestion } from 'src/types/shared/Companies';
import { createAnswer } from '@actions/companyQuestions/answers/createAnswer';

const initialFormData: FormState = {
    answerText: '',
};

const useCreateAnswer = (question: CompanyQuestion, closeModal: () => void) => {
    const dispatch = useDispatch();
    // const history = useHistory();

    const isPosting = useSelector(getCompanyQuestionsIsPosting);
    const postSuccess = useSelector(getCompanyQuestionPostSuccess);
    const error = useSelector(getCompanyQuestionsError);
    const prevPostSuccess = usePrevious(postSuccess);

    const [form, handleChange] = useForm({ ...initialFormData });

    const handleSubmit = useCallback(() => {
        dispatch(createAnswer(question?.id, form));
    }, [dispatch, form, question?.id]);

    // const closeModal = useCallback(() => {
    //     history.replace(
    //         `/companies/${question?.companyID}/sections/${question?.sectionID}/dimensionQuestions/${question?.id}`,
    //     );
    // }, [history, question?.id, question?.companyID]);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        form,
        handleChange,
        closeModal,
        handleSubmit,
        isPosting,
        error,
    };
};

interface FormState {
    answerText: string;
}

export default useCreateAnswer;
