import { useDispatch, useSelector } from 'react-redux';

import { deleteDimensionQuestion } from '@actions/dimensionQuestions/deleteDimensionQuestion';

import {
    selectDimensionQuestionsError,
    selectDimensionQuestionsIsPosting,
} from '@selectors/dimensionQuestions';
import { DimensionQuestion } from '../../../types/shared/Dimensions';

const useDeleteDimensionQuestion = ({ id }: DimensionQuestion) => {
    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(deleteDimensionQuestion(id));
    };

    const isPosting = useSelector(selectDimensionQuestionsIsPosting);
    const error = useSelector(selectDimensionQuestionsError);

    return { handleDelete, isPosting, error };
};

export default useDeleteDimensionQuestion;
