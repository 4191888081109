import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { Module } from '../../../types/shared/Modules';
import { APIError } from 'lib/src/types/APIError';

export const copyModuleRequest = createAction('copyModuleRequest');
export const copyModuleSuccess = createAction<Module>('copyModuleSuccess');
export const copyModuleFailure = createAction('copyModuleFailure');

export const copyModule = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(copyModuleRequest());

    try {
        const { data }: CreateModuleResponse = await api.post(`modules/${id}/copy`, {});

        dispatch(copyModuleSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, copyModuleFailure, e as APIError);
    }
};

interface CreateModuleResponse {
    data: Module;
}
