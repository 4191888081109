import { api, handleApiErrors } from 'lib/src/utils/api';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { APIError } from 'lib/src/types/APIError';
import { DimensionAnswer } from '../../../../types/shared/Dimensions';

export const updateDimensionAnswerRequest = createAction('updateDimensionAnswerRequest');
export const updateDimensionAnswerSuccess = createAction<DimensionAnswer>(
    'updateDimensionAnswerSuccess',
);
export const updateDimensionAnswerFailure = createAction('updateDimensionAnswerFailure');

export const updateDimensionAnswer =
    (answerID: number, postBody: { text: string; score: number }) =>
    async (dispatch: AppDispatch) => {
        dispatch(updateDimensionAnswerRequest());

        try {
            const { data }: UpdateDimensionResponse = await api.patch(
                `dimensions/answers/${answerID}`,
                postBody,
            );

            dispatch(updateDimensionAnswerSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateDimensionAnswerFailure, e as APIError);
        }
    };

interface UpdateDimensionResponse {
    data: DimensionAnswer;
}
