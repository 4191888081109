import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import useDeleteAccessCode from '../hooks/useDeleteAccessCode';

const DeleteAccessCodeModal = ({ id, closeModal }: DeleteAccessCodeModalProps) => {
    const { handleDeleteAccessCode, isPosting, error } = useDeleteAccessCode(id, closeModal);
    return (
        <ConfirmModal
            title="Delete Access Code"
            description={`Are you sure you want to delete this Access Code?`}
            closeModal={closeModal}
            handleSubmit={handleDeleteAccessCode}
            isPosting={isPosting}
            error={error}
        />
    );
};

interface DeleteAccessCodeModalProps {
    id: number;
    closeModal: () => void;
}
export default DeleteAccessCodeModal;
