import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from 'src/redux/store';
import { Module } from 'src/types/shared/Modules';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const fetchAllModulesRequest = createAction('fetchAllModules');
export const fetchAllModulesSuccess = createAction<Module[]>('fetchAllModulesSuccess');
export const fetchAllModulesFailure = createAction('fetchAllModulesFailure');

export const fetchAllModules =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchAllModulesRequest());
        try {
            const { data }: Response = await api.get('modules');
            dispatch(fetchAllModulesSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchAllModulesFailure, e as APIError);
        }
    };

interface Response {
    data: Module[];
}
