import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import Select from 'lib/src/components/form/Select';
import Modal from 'lib/src/components/modal/Modal';
import { Company } from 'src/types/shared/Companies';
import useDeleteAssociation from './hooks/useDeleteCompanyAssociation';

const DeleteAssociationModal = ({ closeModal, item }: DeleteAssociationProps) => {
    const { isPosting, handleSubmit, moduleOptions, selectedModuleID, handleChange } =
        useDeleteAssociation(item, closeModal);
    return (
        <Modal
            title={`Delete association`}
            closeModal={closeModal}
            size="medium"
            style={{ overflow: 'visible' }}
        >
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting}>
                <FormRow>
                    <Select
                        search
                        options={moduleOptions}
                        name="selectedCompanyID"
                        value={selectedModuleID}
                        onChange={handleChange}
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface DeleteAssociationProps {
    item: Company;
    closeModal: () => void;
}
export default DeleteAssociationModal;
