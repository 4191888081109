import useUpdateAccessCode from '../hooks/useUpdateAccessCode';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import NumberInput from 'lib/src/components/form/NumberInput';
import TextInput from 'lib/src/components/form/TextInput';
import { validateAccessCode } from 'lib/src/utils/validation';
import DatePicker from 'lib/src/components/form/DatePicker';

const UpdateAccessCodeModal = ({ id, closeModal }: UpdateAccessCodeModalProps) => {
    const {
        form: { accessCode, maxUses, startDate, endDate },
        handleChange,
        handleSubmit,
        isPosting,
        error,
    } = useUpdateAccessCode(id, closeModal);

    return (
        <Modal closeModal={closeModal} title="Edit access code">
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal} error={error}>
                <FormRow>
                    <TextInput
                        name="accessCode"
                        value={accessCode}
                        onChange={handleChange}
                        label="Access Code"
                        customValidate={validateAccessCode}
                        required
                    />

                    <NumberInput
                        name="maxUses"
                        value={maxUses === null ? 0 : maxUses}
                        onChange={handleChange}
                        label="Max Uses - 0 for unlimited"
                        minNumber={0}
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        name="startDate"
                        value={startDate && new Date(startDate)}
                        onChange={handleChange}
                        label="Start date"
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        name="endDate"
                        value={endDate && new Date(endDate)}
                        onChange={handleChange}
                        label="End date"
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface UpdateAccessCodeModalProps {
    id: number;
    closeModal: () => void;
}
export default UpdateAccessCodeModal;
