import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import usePrevious from 'lib/src/hooks/usePrevious';

import { Dimension as DimensionType } from '../../../../types/shared/Dimensions';
import { RootState } from '@reducers/index';

import { selectDimension, selectDimensionsIsFetching } from '@selectors/dimensions';
import { fetchAllDimensions } from '@actions/dimensions/fetchAllDimensions';

const useSingleDimension = () => {
    const { dimensionID } = useParams<{ moduleID: string; dimensionID: string }>();

    const dispatch = useDispatch();
    const history = useHistory();

    const isFetching = useSelector(selectDimensionsIsFetching);
    const dimension: DimensionType = useSelector((state: RootState) =>
        selectDimension(state, +dimensionID),
    );
    const prevDimension = usePrevious(dimension);

    useEffect(() => {
        dispatch(fetchAllDimensions());
    }, [dispatch]);

    useEffect(() => {
        if (!dimension && prevDimension) {
            history.replace(`/modules/${prevDimension.moduleID}`);
        }
    }, [dimension, prevDimension, history]);

    return {
        dimension,
        isFetching,
    };
};

export default useSingleDimension;
