import useForm from 'lib/src/hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import usePrevious from 'lib/src/hooks/usePrevious';

import dayjs from 'dayjs';
import { DimensionQuestion } from '../../../types/shared/Dimensions';
import { dateFormat } from '../../../constants/formats';

import { updateDimensionQuestion } from '@actions/dimensionQuestions/updateDimensionQuestion';

import {
    selectDimensionQuestionPostSuccess,
    selectDimensionQuestionsIsPosting,
} from '@selectors/dimensionQuestions';
import { DimensionQuestionTypes } from '../../../types/shared/Companies';

const useUpdateDimensionQuestion = (
    { id, text, answerType, startDate, endDate, textInputScore }: DimensionQuestion,
    closeModal: () => void,
) => {
    const dispatch = useDispatch();

    const initialFormData: FormState = {
        text,
        answerType,
        startDate,
        endDate,
        textInputScore,
    };

    const [form, handleChange] = useForm<FormState>(initialFormData);

    const isPosting = useSelector(selectDimensionQuestionsIsPosting);
    const postSuccess = useSelector(selectDimensionQuestionPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    const handleSubmit = () => {
        const formattedStartDate = form.startDate ? dayjs(form.startDate).format(dateFormat) : null;
        const formattedEndDate = form.endDate ? dayjs(form.endDate).format(dateFormat) : null;

        const postBody = {
            ...form,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
        };

        dispatch(updateDimensionQuestion(id, postBody));
    };

    return { form, handleChange, handleSubmit, isPosting };
};

interface FormState {
    text: string;
    answerType: DimensionQuestionTypes;
    startDate: Date;
    endDate: Date;
    textInputScore: number | null;
}

export default useUpdateDimensionQuestion;
