import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';

import { DimensionSubcategory } from '../../types/shared/Dimensions';
import {
    fetchDimensionSubcategoriesRequest,
    fetchDimensionSubcategoriesSuccess,
    fetchDimensionSubcategoriesFailure,
    fetchAllDimensionSubcategoriesFailure,
    fetchAllDimensionSubcategoriesRequest,
    fetchAllDimensionSubcategoriesSuccess,
    deleteDimensionSubcategoryFailure,
    deleteDimensionSubcategoryRequest,
    deleteDimensionSubcategorySuccess,
    fetchDimensionSubcategoryFailure,
    fetchDimensionSubcategoryRequest,
    fetchDimensionSubcategorySuccess,
    createDimensionSubcategoryFailure,
    createDimensionSubcategoryRequest,
    createDimensionSubcategorySuccess,
    updateDimensionSubcategoryRequest,
    updateDimensionSubcategorySuccess,
    updateDimensionSubcategoryFailure,
} from '@actions/dimensionSubcategories';
import {
    copySubcategoryToDimensionFailure,
    copySubcategoryToDimensionRequest,
    copySubcategoryToDimensionSuccess,
} from '@actions/dimensions/copySubcategoryToDimension';
import {
    patchSortDimensionSubcategoriesFailure,
    patchSortDimensionSubcategoriesRequest,
    patchSortDimensionSubcategoriesSuccess,
} from '@actions/dimensionSubcategories/patchSortDimensionSubcategories';

interface DimensionsSubcategoriesState {
    postSuccess: boolean;
    isPosting: boolean;
    isFetching: boolean;
    subcategories: Record<number, DimensionSubcategory>;
    error: string | null;
}

const initialState: DimensionsSubcategoriesState = {
    isFetching: false,
    subcategories: {},
    error: null,
    postSuccess: false,
    isPosting: false,
};

export default createReducer(initialState, {
    [fetchDimensionSubcategoriesRequest.type]: handleFetchRequest,
    [fetchDimensionSubcategoriesSuccess.type]: handleFetchSuccess,
    [fetchDimensionSubcategoriesFailure.type]: handleFailure,
    [createDimensionSubcategoryRequest.type]: handlePostRequest,
    [createDimensionSubcategorySuccess.type]: handlePostSuccess,
    [createDimensionSubcategoryFailure.type]: handleFailure,
    [deleteDimensionSubcategoryRequest.type]: handlePostRequest,
    [deleteDimensionSubcategorySuccess.type]: handleDeleteSuccess,
    [deleteDimensionSubcategoryFailure.type]: handleFailure,
    [fetchDimensionSubcategoryRequest.type]: handleFetchRequest,
    [fetchDimensionSubcategorySuccess.type]: handleFetchSingleSuccess,
    [fetchDimensionSubcategoryFailure.type]: handleFailure,
    [fetchAllDimensionSubcategoriesRequest.type]: handleFetchRequest,
    [fetchAllDimensionSubcategoriesSuccess.type]: handleFetchSuccess,
    [fetchAllDimensionSubcategoriesFailure.type]: handleFailure,
    [copySubcategoryToDimensionRequest.type]: handlePostRequest,
    [copySubcategoryToDimensionSuccess.type]: handlePostSuccess,
    [copySubcategoryToDimensionFailure.type]: handleFailure,
    [updateDimensionSubcategoryRequest.type]: handlePostRequest,
    [updateDimensionSubcategorySuccess.type]: handlePostSuccess,
    [updateDimensionSubcategoryFailure.type]: handleFailure,
    [patchSortDimensionSubcategoriesRequest.type]: handlePostRequest,
    [patchSortDimensionSubcategoriesSuccess.type]: handlePatchSortSuccess,
    [patchSortDimensionSubcategoriesFailure.type]: handleFailure,
});

function handleFetchRequest(state: DimensionsSubcategoriesState) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchSuccess(
    state: DimensionsSubcategoriesState,
    action: PayloadAction<DimensionSubcategory[]>,
) {
    state.isFetching = false;
    state.subcategories = { ...state.subcategories, ...convertArrToObj(action.payload) };
}

function handleFailure(state: DimensionsSubcategoriesState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
    state.isPosting = false;
}

function handlePostRequest(state: DimensionsSubcategoriesState) {
    state.isPosting = true;
    state.postSuccess = false;
}

function handlePostSuccess(
    state: DimensionsSubcategoriesState,
    action: PayloadAction<DimensionSubcategory>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    state.subcategories[action.payload.id] = action.payload;
}

function handleFetchSingleSuccess(
    state: DimensionsSubcategoriesState,
    action: PayloadAction<DimensionSubcategory>,
) {
    state.isFetching = false;
    state.subcategories[action.payload.id] = action.payload;
}

function handleDeleteSuccess(state: DimensionsSubcategoriesState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.subcategories[action.payload];
}

function handlePatchSortSuccess(
    state: DimensionsSubcategoriesState,
    action: PayloadAction<DimensionSubcategory[]>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    state.subcategories = { ...state.subcategories, ...convertArrToObj(action.payload) };
}
