import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { CompanyQuestion } from 'src/types/shared/Companies';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const updateCompanyQuestionRequest = createAction('updateCompanyQuestionRequest');
export const updateCompanyQuestionSuccess = createAction<CompanyQuestion>(
    'updateCompanyQuestionSuccess',
);
export const updateCompanyQuestionFailure = createAction('updateCompanyQuestionFailure');

export const updateCompanyQuestion =
    (id: number, postBody: PostBody) => async (dispatch: AppDispatch) => {
        dispatch(updateCompanyQuestionRequest());

        try {
            const { data }: ResponseModel = await api.patch(`onboarding/questions/${id}`, postBody);

            dispatch(updateCompanyQuestionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateCompanyQuestionFailure, e as APIError);
        }
    };

interface PostBody {
    questionText: string;
    isRequired: boolean;
}

interface ResponseModel {
    data: CompanyQuestion;
}
