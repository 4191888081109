import { RootState } from '@reducers/index';

export const getAllModuleDimensions = (state: RootState) => state.dimensionsReducer.dimensions;

export const getModuleDimension = (state: RootState, id: number) =>
    state.dimensionsReducer.dimensions[id];

export const getModuleQuestionsIsFetching = (state: RootState) =>
    state.dimensionsReducer.isFetching;

export const getModuleQuestionsError = (state: RootState) => state.dimensionsReducer.error;

export const getModuleQuestionsIsPosting = (state: RootState) => state.dimensionsReducer.isPosting;

export const getModuleQuestionPostSuccess = (state: RootState) =>
    state.dimensionsReducer.postSuccess;
