const ActionButton: React.FC<ActionButtonProps> = ({
    children,
    type = 'submit',
    color = 'blue',
    variant = 'flat',
    icon,
    isPosting = false,
    disabled = false,
    onClick = () => {},
    className,
    textClassName,
}) => (
    <button
        className={`button ${color} ${variant} ${className}`}
        type={type}
        disabled={isPosting || disabled}
        onClick={onClick}
    >
        {icon && !isPosting && (
            <i
                className={`icon far fa-fw fa-${icon}`}
                // todo add to class
                style={{ color: color === 'white' ? 'lightgray' : undefined }}
            ></i>
        )}
        <span className={`text ${textClassName}`}>{children}</span>
        {isPosting && (
            <i
                className="icon far fa-fw fa-spinner fa-spin"
                style={{ color: color === 'white' ? 'gray' : undefined }}
            ></i>
        )}
    </button>
);

interface ActionButtonProps {
    type?: 'submit' | 'button' | 'reset';
    color?: string;
    variant?: 'flat' | 'outlined';
    icon?: string;
    isPosting?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
    className?: string;
    textClassName?: string;
}

export default ActionButton;
