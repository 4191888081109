import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';

import {
    getModuleQuestionPostSuccess,
    getModuleQuestionsError,
    getModuleQuestionsIsPosting,
} from '@selectors/moduleQuestions';
import { DimensionForm, DimensionPost, ScoreDetails } from 'src/types/shared/Dimensions';
import { createDimension } from '@actions/dimensions/createDimension';
import { useAppSelector } from '../../../../redux/store';
import { getModule } from '@selectors/modules';

const useCreateModuleDimension = (moduleID: number, closeModal: () => void) => {
    const dispatch = useDispatch();

    const module = useAppSelector(state => getModule(state, moduleID));
    const isScoring = module?.isScoring;

    const initialFormData: DimensionForm = {
        description: '',
        highScoreBoundary: 0,
        highScoreDetails: { summary: '', action: '', breakdown: '' } as ScoreDetails,
        imageS3Key: [],
        isActive: false,
        lowScoreDetails: { summary: '', action: '', breakdown: '' } as ScoreDetails,
        mediumScoreBoundary: 0,
        mediumScoreDetails: { summary: '', action: '', breakdown: '' } as ScoreDetails,
        name: '',
    };

    const [form, handleChange] = useForm<DimensionForm>(initialFormData);
    const postSuccess = useSelector(getModuleQuestionPostSuccess);
    const error = useSelector(getModuleQuestionsError);
    const prevPostSuccess = usePrevious(postSuccess);
    const isPosting = useSelector(getModuleQuestionsIsPosting);

    const handleSubmit = useCallback(() => {
        let imageS3Key = null;

        if (Array.isArray(form.imageS3Key) && form.imageS3Key.length > 0) {
            imageS3Key = form.imageS3Key[0];
        }

        const formattedForm: DimensionPost = {
            ...form,
            imageS3Key,
            highScoreBoundary: isScoring ? parseFloat(form.highScoreBoundary.toString()) : null,
            mediumScoreBoundary: isScoring ? parseFloat(form.mediumScoreBoundary.toString()) : null,
        };
        dispatch(createDimension(moduleID, formattedForm));
    }, [dispatch, form, moduleID, isScoring]);

    const closeModalCallback = useCallback(() => {
        closeModal();
    }, [closeModal]);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModalCallback();
        }
    }, [postSuccess, prevPostSuccess, closeModal, closeModalCallback]);

    return {
        form,
        handleChange,
        closeModal,
        handleSubmit,
        isPosting,
        error,
        isScoring,
    };
};

export default useCreateModuleDimension;
