import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { Dimension, DimensionPost } from 'src/types/shared/Dimensions';

export const updateDimensionRequest = createAction('updateDimensionRequest');
export const updateDimensionSuccess = createAction<Dimension>('updateDimensionSuccess');
export const updateDimensionFailure = createAction('updateDimensionFailure');

export const updateDimension =
    (id: number, postBody: DimensionPost) => async (dispatch: AppDispatch) => {
        dispatch(updateDimensionRequest());

        try {
            const { data }: ResponseModel = await api.patch(`dimensions/${id}`, postBody);

            dispatch(updateDimensionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateDimensionFailure, e as APIError);
        }
    };

interface ResponseModel {
    data: Dimension;
}
